import * as Yup from 'yup';
import { SelectedField } from 'types/WorkerTypes';
import messages from './messages';

const updateWorkerDetailsSchemaValidationSchema = (workerEmail?: string) => Yup.object().shape({
  selectedField: Yup.array().of(Yup.object().shape(
    {
      value: Yup.string().required(messages?.required).max(64, messages?.maxLength),
      label: Yup.string().required(messages?.required).max(255, messages?.maxLength),
    },
  ).required()),
  email: Yup.string()
    .email(messages?.email)
    .test('is-different-email', messages?.emailMustBeDifferent, (value) => value !== workerEmail)
    .when('selectedField', {
      is: (selectedField: any) => selectedField && selectedField[0]?.value === SelectedField.Email,
      then: (schema) => schema.required(messages?.required),
      otherwise: (schema) => schema.notRequired(),
    }),
  otp: Yup.array()
    .of(Yup.string().matches(/^[0-9]$/, messages?.required))
    .length(4, messages?.required)
    .test('otp-required', messages?.required, (value) => (value ? value.every((v) => v !== '') : false))
    .when(['selectedField', 'email'], {
      is: (selectedField: any, email: string) => selectedField && selectedField[0]?.value === SelectedField.Email && email,
      then: (schema) => schema.required(messages?.required),
      otherwise: (schema) => schema.notRequired(),
    }),
  firstName: Yup.string()
    .matches(/^[a-zA-Z -.,']+$/, messages?.namePaycard)
    .max(30, messages?.maxLength)
    .when('selectedField', {
      is: (selectedField: any) => selectedField && selectedField[0]?.value === SelectedField.Name,
      then: Yup.string().required(messages?.required),
      otherwise: Yup.string().nullable(),
    }),
  lastName: Yup.string()
    .matches(/^[a-zA-Z -.,']+$/, messages?.namePaycard)
    .max(30, messages?.maxLength)
    .when('selectedField', {
      is: (selectedField: any) => selectedField && selectedField[0]?.value === SelectedField.Name,
      then: Yup.string().required(messages?.required),
      otherwise: Yup.string().nullable(),
    }),
});

export default updateWorkerDetailsSchemaValidationSchema;
