import {
  createContext,
  memo,
  useEffect,
} from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { useTranslation } from 'react-i18next';
import { LabelMedium } from 'baseui/typography';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import { employeePaymentDetailsInitialValues } from 'initialValues/PaycardsInitialValues';
import { EmployeePaymentDetailsValuesType } from 'types/PaycardManagementTypes';
import AppInput from 'components/Form/AppInput';
import {
  paycardOrganizationRegisterCardPaymentDetailsSelector,
  setIsPairCardFormValid,
} from 'store/slices/paycards';
import AppTextarea from 'components/Form/AppTextarea';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { employeePaymentDetailsValidationSchema as validationSchema } from 'validation/paycardManagementSchema';
import { LABEL_PLACEMENT, STYLE_TYPE } from 'baseui/checkbox';
import AppCheckbox from 'components/Form/AppCheckbox';
import { checkboxParagraphStyles } from '../../PaycardManagementHelper';

export const EmployeePaymentDetailsFormContext = createContext(
  {} as FormikState<EmployeePaymentDetailsValuesType> & FormikHelpers<EmployeePaymentDetailsValuesType> & FormikHandlers,
);

export type PaycardManagementRegisterCardPairCardPropsType = {
  formValues: EmployeePaymentDetailsValuesType | {},
  setFormValues: (values: EmployeePaymentDetailsValuesType | {}) => void;
}

const PaycardManagementRegisterCardPairCard = (
  {
    formValues,
    setFormValues,
  }: PaycardManagementRegisterCardPairCardPropsType,
) => {
  const { t } = useTranslation(['common', 'paycardManagement', 'dateFormats']);
  const dispatch = useAppDispatch();
  const organizationName = useAppSelector(loggedOrganizationSelector)?.name;
  const paymentDetails = useAppSelector(paycardOrganizationRegisterCardPaymentDetailsSelector);

  const initialValues = {
    ...employeePaymentDetailsInitialValues,
    ...formValues,
    ...paymentDetails,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: () => {},
    validationSchema,
  });

  const {
    values,
    isValid,
    validateForm,
  } = formik;

  useEffect(() => {
    dispatch(setIsPairCardFormValid(isValid));
  }, [isValid]);

  useEffect(() => {
    setFormValues(values);
  }, [values, setFormValues]);

  useEffect(() => {
    validateForm(initialValues);
  }, []);

  return (
    <form>
      <EmployeePaymentDetailsFormContext.Provider value={formik}>
        <LabelMedium margin="18px">{t('paycardManagement:paycardManagement.registerCard.pairCard.header')}</LabelMedium>
        <AppInput
          showStar
          name="proxyNumber"
          inputProps={{
            id: 'proxyNumber',
            autoComplete: 'off',

          }}
          formControlProps={{ htmlFor: 'proxyNumber' }}
          label={t('paycardManagement:paycardManagement.registerCard.pairCard.proxyNumber')}
          cellSpan={[12, 6, 2.5]}
          context={EmployeePaymentDetailsFormContext}
          dynamicErrors
        />
        <AppCheckbox
          name="useForDirectDeposit"
          label={
            t('paycardManagement:paycardManagement.registerCard.pairCard.paycardForPayrollPurposes')
          }
          labelPlacement={LABEL_PLACEMENT.right}
          cellSpan={12}
          context={EmployeePaymentDetailsFormContext}
          checkboxType={STYLE_TYPE.default}
          tooltipTitle=""
          tooltipContent={(
            <>
              {t('paycardManagement:paycardManagement.registerCard.pairCard.paycardForPayrollPurposesTooltip')}
              <br />
              <br />
              {t('paycardManagement:paycardManagement.registerCard.pairCard.paycardForPayrollPurposesTooltip1')}
            </>
          )}
          paragraphProps={checkboxParagraphStyles}
          labelProps={{
            whiteSpace: 'normal',
          }}
        />
        <AppInput
          name="funds"
          formControlProps={{ htmlFor: 'funds' }}
          label={t('paycardManagement:paycardManagement.registerCard.pairCard.loadFunds')}
          cellSpan={[12, 6, 2.5]}
          context={EmployeePaymentDetailsFormContext}
          inputProps={{
            id: 'funds',
            autoComplete: 'off',
            startEnhancer: '$',
            min: 0,
            max: 1000000.00,
            type: 'number',
            step: 'any' as any,
          }}
        />
        <AppTextarea
          name="paymentDescription"
          label={t('paycardManagement:paycardManagement.registerCard.pairCard.paymentDescription.label')}
          cellSpan={[12, 12, 6]}
          context={EmployeePaymentDetailsFormContext}
          textareaProps={{
            id: 'paymentDescription',
            autoComplete: 'off',
          }}
          placeholder={t('paycardManagement:paycardManagement.registerCard.pairCard.paymentDescription.placeholder', { orgName: `${organizationName || ''}` })}
        />
        <AppTextarea
          name="internalNotes"
          label={t('paycardManagement:paycardManagement.registerCard.pairCard.internalNotes.label')}
          cellSpan={[12, 12, 6]}
          context={EmployeePaymentDetailsFormContext}
          textareaProps={{
            id: 'internalNotes',
            autoComplete: 'off',
          }}
          placeholder={t('paycardManagement:paycardManagement.registerCard.pairCard.internalNotes.placeholder')}
        />
      </EmployeePaymentDetailsFormContext.Provider>
    </form>
  );
};

export default memo(PaycardManagementRegisterCardPairCard);
