import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { ParagraphSmall } from 'baseui/typography';
import { EmployeeTipsTotal } from 'types/TPOTypes';
import { useTranslation } from 'react-i18next';
import { colors, emptyPlaceholder } from 'theme';
import priceFormatter from 'utils/priceFormatter';
import { unScalePrice } from 'utils/priceScale';
import { useAppSelector } from 'store/hooks';
import {
  employeesTipTotalsSelector,
  tippedEmployeesByBusinessDateTableColumnsSelector,
} from 'store/slices/tpo';
import { ExcludedEditableColumns } from 'dataMappers/tippedEmployeesDataMapper';
import {
  bodyCellStyles,
  outerContainerStyles,
} from './AppDataTableHelpers';

const AppDataTableTotalTipsRow = () => {
  const [css] = useStyletron();
  const { t } = useTranslation(['tipsManagement']);
  const row = useAppSelector(employeesTipTotalsSelector);
  const columns = useAppSelector(tippedEmployeesByBusinessDateTableColumnsSelector);

  return (
    <div className={css({
      ...outerContainerStyles,
      backgroundColor: colors.bannerInfo,
    })}
    >
      <Grid
        gridColumns={12}
        align={ALIGNMENT.center}
        gridMargins={16}
        gridGutters={0}
        overrides={{
          Grid: {
            style: {
              padding: '0px !important',
              width: '100%',
            },
          },
        }}
      >
        <Cell
          align={ALIGNMENT.center}
          span={1.25}
          overrides={{
            Cell: {
              style: {
                ...bodyCellStyles,
                borderRight: `1px solid ${colors.tableBorder}`,
                borderBottom: `1px solid ${colors.tableBorder}`,
              },
            },
          }}
        >
          <ParagraphSmall>
            {t('tipsManagement:tippedEmployeesPerBusinessDateTable.total')}
          </ParagraphSmall>
        </Cell>

        {columns.length > 0 && columns.map((column) => {
          const spanWidth = 9.5 / columns.length;
          const value = column?.key in row ? row[column.key as keyof EmployeeTipsTotal] : undefined;

          return (
            <Cell
              align={ALIGNMENT.center}
              span={spanWidth}
              overrides={{
                Cell: {
                  style: {
                    ...bodyCellStyles,
                    borderBottom: `1px solid ${colors.tableBorder}`,
                  },
                },
              }}
            >
              <ParagraphSmall>
                {(() => {
                  if (value) {
                    if (typeof value === 'object' && 'value' in value && 'scale' in value) {
                      return priceFormatter().format(unScalePrice(value.value, value.scale));
                    }
                    return priceFormatter().format(unScalePrice(0, 2));
                  } if (ExcludedEditableColumns.PAYROLL_ID.includes(column.key)) {
                    return '';
                  }
                  return priceFormatter().format(unScalePrice(0, 2));
                })()}
              </ParagraphSmall>
            </Cell>
          );
        })}

        <Cell
          align={ALIGNMENT.center}
          span={1.25}
          overrides={{
            Cell: {
              style: {
                ...bodyCellStyles,
                borderLeft: `1px solid ${colors.tableBorder}`,
                borderBottom: `1px solid ${colors.tableBorder}`,
              },
            },
          }}
        >
          <ParagraphSmall>
            {row.totalTips
              ? priceFormatter().format(unScalePrice(row.totalTips.value, row.totalTips.scale))
              : emptyPlaceholder}
          </ParagraphSmall>
        </Cell>
      </Grid>
    </div>
  );
};

export default AppDataTableTotalTipsRow;
