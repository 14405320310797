import { ColumnWithEditability, TippedEmployeePerBusinessDate } from 'types/TPOTypes';

export enum ExcludedEditableColumns {
  PAYROLL_ID = 'payrollId',
}

export const tippedEmployeesTableColumnsMapper = (
  tippedEmployees: TippedEmployeePerBusinessDate[],
  editableFields: (keyof TippedEmployeePerBusinessDate)[],
): ColumnWithEditability[] => {
  const EXCLUDED_EDITABLE_COLUMNS: (keyof TippedEmployeePerBusinessDate)[] = ['payrollId'];

  const possibleColumns: (keyof TippedEmployeePerBusinessDate)[] = [
    'payrollId',
    'thirdPartyTips',
    'creditCardTips',
    'serviceChargeTips',
    'tipPoolTips',
    'declaredTips',
    'cashTips',
  ];

  // Determine which columns are actually present in the dataset
  const availableColumns = possibleColumns.filter((column) => tippedEmployees.some((employee) => employee[column] !== undefined));

  return availableColumns.map((column) => ({
    key: column,
    isEditable: editableFields.includes(column) && !EXCLUDED_EDITABLE_COLUMNS.includes(column),
    isEditingEnabled: false,
  }));
};
