import { StyleObject } from 'styletron-react';
import { colors } from 'theme';

export const tableStyles = {
  marginTop: '24px',
  overflowY: 'auto',
  maxHeight: '532px',
  marginBottom: '68px',
} as StyleObject;

export const tableHeaderStyles = {
  minWidth: '960px',
  overflow: 'hidden',
  'box-sizing': 'border-box',
  backgroundColor: colors.header,
  zIndex: 1,
  position: 'sticky',
  top: '0px',
} as StyleObject;

export const tableTotalTipsContainerStyles = {
  width: '100%',
  height: '100%',
  position: 'sticky',
  minWidth: '960px',
  top: '52px',
  zIndex: 1,
} as StyleObject;

export const tableListContainerStyles = {
  width: '100%',
  height: '100%',
  position: 'relative',
  minWidth: '960px',
  zIndex: 0,
} as StyleObject;

export const outerContainerStyles = {
  minHeight: '80px',
  overflow: 'hidden',
  position: 'relative',
} as StyleObject;

export const headerCellStyles = {
  minHeight: '52px',
  maxHeight: '72px',
  display: 'flex !important',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0 16px',
  margin: 0,
  borderBottom: `1px solid ${colors.tableBorder}`,
};

export const bodyCellStyles = {
  minHeight: '80px',
  maxHeight: '80px',
  display: 'flex !important',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0 16px',
  margin: 0,
};
