import { Layer } from 'baseui/layer';
import AppModal from 'components/AppModal/AppModal';
import SectionsTabulation from 'components/SectionsTabulation';
import {
  ModalNames,
  modalsSelector,
  setModal,
} from 'store/slices/modals';
import { OrganizationFormLocationModalPropsType } from 'types/LocationTypes';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import {
  useAppSelector,
  useAppDispatch,
} from 'store/hooks';
import { useTranslation } from 'react-i18next';
import {
  memo,
  useEffect,
} from 'react';
import {
  fetchLocation,
  fetchLocationPaycardConfig,
  fetchLocations,
  locationCreatedSelector,
  locationEditedSuccessfully,
  locationPaycardSettingsPendingSelector,
  locationPendingSelector,
  resetSelectedLocation,
  selectedLocationSelector,
} from 'store/slices/locations';
import { organizationSelector } from 'store/slices/organizations';
import { Grid } from 'baseui/layout-grid';
import { sectionSelector } from 'store/slices/sections';
import Loader from 'components/Loader';
import { fetchPayGroups } from 'store/slices/payGroups';
import useExposeSpecificFeatureFlag from 'hooks/useExposeSpecificFeatureFlag';
import OrganizationFormLocationModalEWA from '../OrganizationFormLocationModalEWA/OrganizationFormLocationModalEWA';
import OrganizationFormLocationModalProfile from '../OrganizationFormLocationModalProfile/OrganizationFormLocationModalProfile';
import OrganizationFormLocationModalTPO from '../OrganizationFormLocationModalTPO/OrganizationFormLocationModalTPO';
import OrganizationFormLocationModalTipConfiguration from '../OrganizationFormLocationModalTipConfiguration/OrganizationFormLocationModalTipConfiguration';
import OrganizationFormLocationModalPaycardConfiguration
  from '../OrganizationFormLocationModalPaycardConfiguration/OrganizationFormLocationModalPaycardConfiguration';
import
OrganizationFormLocationModalTipConfigurationNew
  from '../OrganizationFormLocationModalTipConfigurationNew/OrganizationFormLocationModalTipConfigurationNew';

const OrganizationFormLocationModal = ({
  locationID,
}: OrganizationFormLocationModalPropsType) => {
  const dispatch = useAppDispatch();
  const modals = useAppSelector(modalsSelector);
  const organization = useAppSelector(organizationSelector);
  const { id: organizationID } = organization || {};
  const modalName = ModalNames.LOCATION_FORM_MODAL;
  const modalTitle = locationID ? 'locations:editLocation' : 'locations:addLocation';
  const isModalOpen = checkIsModalOpen(modals, modalName);
  const { t } = useTranslation(['organizations', 'errors', 'common', 'locations']);
  const pending = useAppSelector(locationPendingSelector);
  const paycardConfigPending = useAppSelector(locationPaycardSettingsPendingSelector);
  const selectedSection = useAppSelector(sectionSelector);
  const location = useAppSelector(selectedLocationSelector);
  const locationCreated = useAppSelector(locationCreatedSelector);
  const locationEdited = useAppSelector(locationEditedSuccessfully);
  const isNewTipSourceConfigurationAvailable = useExposeSpecificFeatureFlag('newTipAdjustment');

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    dispatch(resetSelectedLocation());
    (locationCreated || locationEdited) && dispatch(fetchLocations({ organizationID }));
  };

  const subMenuHeadings = [t('locations:location.section.profile'),
    t('locations:location.section.ewa'),
    t('locations:location.section.tpo'),
    t('locations:location.section.tipConfiguration'),
    t('locations:location.section.paycardConfiguration'),
  ];

  useEffect(() => {
    if (isModalOpen) {
      locationID && dispatch(fetchLocation({ organizationID, locationID: locationID || location?.id.toString() }));
      organizationID && dispatch(fetchPayGroups({ organizationID }));
      locationID && organizationID && dispatch(fetchLocationPaycardConfig({
        organizationId: organizationID,
        locationId: locationID,
      }));
    }
  }, [locationID, organizationID, isModalOpen]);

  return (
    <Layer index={400}>
      <form>
        <AppModal
          modalWidth={['90vw', '90vw', '60vw', '40vw']}
          minWidth="400px"
          maxWidth="100%"
          modal={modalName}
          title={t(modalTitle)}
          onClose={handleModalClose}
        >
          <Grid gridMargins={12}>
            <SectionsTabulation
              subMenuHeadings={subMenuHeadings}
              buttonDisabledRole={!location?.id}
              sectionName="location"
            />
          </Grid>
          <Loader active={pending || paycardConfigPending} />
          <br />
          {!pending && selectedSection === 0 && <OrganizationFormLocationModalProfile />}
          {selectedSection === 1 && <OrganizationFormLocationModalEWA />}
          {selectedSection === 2 && <OrganizationFormLocationModalTPO />}
          {selectedSection === 3 && (
            isNewTipSourceConfigurationAvailable
              ? <OrganizationFormLocationModalTipConfigurationNew />
              : <OrganizationFormLocationModalTipConfiguration />
          )}
          {!pending && selectedSection === 4 && <OrganizationFormLocationModalPaycardConfiguration />}
        </AppModal>
      </form>
    </Layer>
  );
};
export default memo(OrganizationFormLocationModal);
