import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  saveAdministratorRequest,
  getAdministratorsRequest,
  getAdministratorRequest,
  updateAdministratorRolesRequest,
  updateAdministratorRequest,
} from 'api/administratorsAPI';
import {
  CreateAdminUserPropTypes,
  AdministratorType,
  AllAdministratorsFilterType,
  EditAdminRolesRequestType,
  EditAdminUserPropTypes,
} from 'types/AdministratorsTypes';
import { RootState } from '..';
import { administratorsStatuses } from '../../screens/Administrators/AdministratorsHelpers';

export const initialState = {
  list: [] as AdministratorType[],
  admin: {} as AdministratorType,
  nextToken: '' as string,
  searchPattern: '' as string,
  pageSize: 50 as number,
  pendingList: false,
  pending: false,
  filterCriteria: [{ id: 'namePattern', name: '' }],
  rolesFilter: [] as any[],
  organizationsFilter: [] as any[],
  locationsFilter: [] as any[],
  statusFilter: [administratorsStatuses[0]] as unknown as any[],
};

export const fetchAdministratorById = createAsyncThunk(
  'fetch/adminById',
  async ({ id }: { id: string }, { getState, rejectWithValue }): Promise<any> => {
    const storeState = getState() as RootState;
    try {
      return await getAdministratorRequest(storeState.user.accessToken, id);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchAllAdministrators = createAsyncThunk(
  'fetch/admins',
  async (params: AllAdministratorsFilterType, { getState, rejectWithValue }): Promise<any> => {
    const storeState = getState() as RootState;
    try {
      return await getAdministratorsRequest(storeState.user.accessToken, params);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const searchAdministrators = createAsyncThunk(
  'search/admins',
  async (params: AllAdministratorsFilterType, { getState, rejectWithValue }): Promise<any> => {
    const storeState = getState() as RootState;
    try {
      return await getAdministratorsRequest(storeState.user.accessToken, params);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const saveAdministrator = createAsyncThunk(
  'save/admin',
  async (params: CreateAdminUserPropTypes, { getState, rejectWithValue }): Promise<any> => {
    const { data } = params;
    const storeState = getState() as RootState;

    try {
      return await saveAdministratorRequest(storeState.user.accessToken, data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateAdministrator = createAsyncThunk(
  'update/admin',
  async (params: EditAdminUserPropTypes, { getState, rejectWithValue }): Promise<any> => {
    const { data } = params;
    const storeState = getState() as RootState;

    try {
      return await updateAdministratorRequest(storeState.user.accessToken, data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateAdminRoles = createAsyncThunk(
  'update/admin/roles',
  async (params: EditAdminRolesRequestType, { getState, rejectWithValue }): Promise<any> => {
    const { data, id } = params;
    const storeState = getState() as RootState;

    try {
      return await updateAdministratorRolesRequest(storeState.user.accessToken, id, data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const administratorsSlice = createSlice({
  name: 'administrators',
  initialState,
  reducers: {
    resetAdministratorsList: (state) => {
      state.list = initialState.list;
      state.nextToken = initialState.nextToken;
      state.filterCriteria = initialState.filterCriteria;
      state.searchPattern = initialState.searchPattern;
      state.rolesFilter = initialState.rolesFilter;
      state.organizationsFilter = initialState.organizationsFilter;
      state.locationsFilter = initialState.locationsFilter;
      state.statusFilter = initialState.statusFilter;
    },
    resetAdministatorsSearchFilterOnly: (state) => {
      state.list = initialState.list;
      state.nextToken = initialState.nextToken;
      state.filterCriteria = initialState.filterCriteria;
      state.searchPattern = initialState.searchPattern;
    },
    resetAdministrator: (state) => {
      state.admin = initialState.admin;
    },
    setAdministrator: (state, action) => {
      state.admin = action.payload;
    },
    setSearchPattern: (state, action) => {
      state.searchPattern = action.payload;
    },
    setFilterCriteria: (state, action) => {
      state.filterCriteria = action.payload;
    },
    setRolesFilter: (state, action) => {
      state.rolesFilter = action.payload;
    },
    setOrganizationsFilter: (state, action) => {
      state.organizationsFilter = action.payload;
    },
    setLocationsFilter: (state, action) => {
      state.locationsFilter = action.payload;
    },
    setStatusFilter: (state, action) => {
      state.statusFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveAdministrator.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(saveAdministrator.fulfilled, (state, action) => {
      state.admin = action.payload;
      state.pending = false;
    });
    builder.addCase(updateAdminRoles.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(updateAdminRoles.fulfilled, (state, action) => {
      state.admin = action.payload;
      state.list = state.list.map((admin) => {
        if (admin.id === action.payload.id) {
          admin.roles = action.payload.roles;
          admin.organizations = action.payload.organizations;
          admin.locations = action.payload.locations;
        }
        return admin;
      });
      state.pending = false;
    });
    builder.addCase(updateAdminRoles.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(fetchAllAdministrators.pending, (state) => {
      state.pendingList = true;
    });
    builder.addCase(fetchAllAdministrators.fulfilled, (state, { payload }) => {
      state.list = state.list.concat(payload.values);
      state.nextToken = payload.nextToken;
      state.pageSize = payload.pageSize;
      state.pendingList = false;
    });
    builder.addCase(fetchAllAdministrators.rejected, (state) => {
      state.pendingList = false;
    });
    builder.addCase(searchAdministrators.pending, (state) => {
      state.pendingList = true;
    });
    builder.addCase(searchAdministrators.fulfilled, (state, { payload }) => {
      state.list = payload.values;
      state.nextToken = payload.nextToken;
      state.pendingList = false;
    });
    builder.addCase(searchAdministrators.rejected, (state) => {
      state.pendingList = false;
    });
    builder.addCase(fetchAdministratorById.pending, (state) => {
      state.admin = initialState.admin;
      state.pending = true;
    });
    builder.addCase(fetchAdministratorById.fulfilled, (state, { payload }) => {
      state.admin = payload;
      state.pending = false;
    });
    builder.addCase(fetchAdministratorById.rejected, (state) => {
      state.admin = initialState.admin;
      state.pending = false;
    });
  },
});

export const {
  resetAdministratorsList,
  resetAdministrator,
  setAdministrator,
  setSearchPattern,
  setFilterCriteria,
  setRolesFilter,
  setOrganizationsFilter,
  setLocationsFilter,
  setStatusFilter,
  resetAdministatorsSearchFilterOnly,
} = administratorsSlice.actions;

// Selectors
export const administratorsListSelector = (state: RootState): AdministratorType[] => state.administrators.list;
export const administratorsNextTokenSelector = (state: RootState): string => state.administrators.nextToken;
export const administratorsPendingListSelector = (state: RootState): boolean => state.administrators.pendingList;
export const administratorSelector = (state: RootState): AdministratorType => state.administrators.admin;
export const administratorsPendingSelector = (state: RootState): boolean => state.administrators.pending;
export const administratorsSearchFilterSelector = (state: RootState): string => state.administrators.searchPattern;
export const administratorsSearchFilterCriteriaSelector = (state: RootState) => state.administrators.filterCriteria;
export const administratorsRoleFilterSelector = (state: RootState) => state.administrators.rolesFilter;
export const administratorsOrganizationsFilterSelector = (state: RootState) => state.administrators.organizationsFilter;
export const administratorsLocationsFilterSelector = (state: RootState) => state.administrators.locationsFilter;
export const administratorsStatusFilterSelector = (state: RootState) => state.administrators.statusFilter;

export default administratorsSlice.reducer;
