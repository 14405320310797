import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  employeesTipTotalsSelector,
  setTippedEmployeesByBusinessDateTableColumns,
  tippedEmployeesByBusinessDateListSelector,
  tippedEmployeesByBusinessDateNumPagesSelector,
  tippedEmployeesByBusinessDatePageNumberSelector,
} from 'store/slices/tpo';
import AppDataTable from 'components/AppDataTable/AppDataTable';
import { tippedEmployeesTableColumnsMapper } from '../../../dataMappers/tippedEmployeesDataMapper';
import { TippedEmployeePerBusinessDate, TippedEmployeePerBusinessDateEditableColumns } from '../../../types/TPOTypes';

export type TippedEmployeesPerBusinessDateTablePropsType = {
  handlePageChange: ({ nextPage }: { nextPage: number }) => void,
}

const TippedEmployeesPerBusinessDateTable = ({
  handlePageChange,
}: TippedEmployeesPerBusinessDateTablePropsType) => {
  const dispatch = useAppDispatch();
  const pageNumber = useAppSelector(tippedEmployeesByBusinessDatePageNumberSelector);
  const numPages = useAppSelector(tippedEmployeesByBusinessDateNumPagesSelector);
  const tippedEmployees = useAppSelector(tippedEmployeesByBusinessDateListSelector);
  const totalTips = useAppSelector(employeesTipTotalsSelector);

  const editableColumns = useMemo<(keyof TippedEmployeePerBusinessDate)[]>(() => {
    if (!totalTips?.manualTipTypesOverwriteEnabled) {
      return [];
    }

    return totalTips.manualTipTypesOverwriteEnabled
      .map((type: TippedEmployeePerBusinessDateEditableColumns) => TippedEmployeePerBusinessDateEditableColumns[
        type as unknown as keyof typeof TippedEmployeePerBusinessDateEditableColumns
      ])
      .filter(Boolean);
  }, [totalTips]);

  useEffect(() => {
    const availableColumnsWithEditability = tippedEmployees?.length > 0 && totalTips && Object.keys(totalTips).length > 0
      ? tippedEmployeesTableColumnsMapper(tippedEmployees, editableColumns)
      : [];

    availableColumnsWithEditability?.length > 0
    && dispatch(setTippedEmployeesByBusinessDateTableColumns(availableColumnsWithEditability));
  }, [tippedEmployees, totalTips]);

  return (
    <AppDataTable
      data={tippedEmployees}
      pageNumber={pageNumber}
      numPages={numPages}
      handlePageChange={handlePageChange}
    />
  );
};
export default TippedEmployeesPerBusinessDateTable;
