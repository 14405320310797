import { Cell, Grid } from 'baseui/layout-grid';
import {
  LabelLarge, LabelMedium, LabelSmall, ParagraphMedium, ParagraphSmall,
  ParagraphXSmall,
} from 'baseui/typography';
import AppInput from 'components/Form/AppInput';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import {
  paycardManagementOrderNewCardsInitialValues,
} from 'initialValues/PaycardsInitialValues';
import {
  createContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  fetchShippingAddresses,
  fetchShippingMethodFee,
  fetchShippingMethods,
  paycardManagementSelectedCardStockSelector,
  paycardMasterAccountBalanceSelector,
  paycardsSelectedAddressSelector,
  paycardsShippingAddressesPendingSelector,
  paycardsShippingMethodFeeSelector,
  paycardsShippingMethodsPendingSelector,
  paycardsShippingMethodsSelector,
  setIsOrderNewCardsFormValid,
} from 'store/slices/paycards';
import { colors, emptyPlaceholder } from 'theme';
import { OrderNewCardsValuesType } from 'types/PaycardManagementTypes';
import {
  orderNewCardsValidationSchema as validationSchema,
} from 'validation/paycardManagementSchema';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { Button, KIND, SIZE } from 'baseui/button';
import { Block } from 'baseui/block';
import Loader from 'components/Loader';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import { Banner, HIERARCHY, KIND as BannerKind } from 'baseui/banner';
import AppSelect from 'components/Form/AppSelect';
import priceFormatter from 'utils/priceFormatter';
import { unScalePrice } from 'utils/priceScale';
import AppTooltip from 'components/Form/AppTooltip';
import OrderNewCardsSelectAddressModal from './OrderNewCardsSelectAddressModal/OrderNewCardsSelectAddressModal';
import OrderNewCardsAddAddressModal from './OrderNewCardsAddAddressModal/OrderNewCardsAddAddressModal';
import { bannerStyles, shippingFeeContainerStyles, shippingMethodContainerStyles } from '../../../PaycardManagementHelper';

export const OrderNewCardsOrderFormContext = createContext(
  {} as FormikState<OrderNewCardsValuesType> & FormikHelpers<OrderNewCardsValuesType> & FormikHandlers,
);

type Props = {
  formValues: OrderNewCardsValuesType | {},
  setFormValues: (values: OrderNewCardsValuesType | {}) => void,
}

const OrderNewCardsOrderSection = (
  {
    formValues,
    setFormValues,
  }: Props,
) => {
  const { t } = useTranslation(['common', 'paycardManagement']);
  const dispatch = useAppDispatch();
  const cardStock = useAppSelector(paycardManagementSelectedCardStockSelector);
  const organization = useAppSelector(loggedOrganizationSelector);
  const pending = useAppSelector(paycardsShippingAddressesPendingSelector);
  const modals = useAppSelector(modalsSelector);
  const paycardsSelectedAddress = useAppSelector(paycardsSelectedAddressSelector);
  const balance = useAppSelector(paycardMasterAccountBalanceSelector);
  const shippingMethods = useAppSelector(paycardsShippingMethodsSelector);
  const pendingShippingMethods = useAppSelector(paycardsShippingMethodsPendingSelector);
  const shippingMethodFee = useAppSelector(paycardsShippingMethodFeeSelector);
  const shouldDisableShippingMethodField = shippingMethods && shippingMethods.length <= 1;
  const [cardsCountDebounce, setCardsCountDebounce] = useState('');
  const [isShippingFeeCalculated, setIsShippingFeeCalculated] = useState<boolean>(false);
  const [error, setError] = useState('');
  const timerRef = useRef<number | null>(null);

  const initialValues = {
    ...paycardManagementOrderNewCardsInitialValues,
    ...formValues,
  };

  const shippingMethodsOptions = shippingMethods?.map((method: string) => ({ label: method, value: method }));

  const shippingMethodFeeValue = shippingMethodFee && (formValues as OrderNewCardsValuesType).cardsCount
    ? priceFormatter().format(unScalePrice(shippingMethodFee?.value || 0, shippingMethodFee?.scale || 2))
    : priceFormatter().format(0);

  const openSelectAddressModal = () => {
    if (paycardsSelectedAddress) {
      dispatch(setModal({
        name: ModalNames.SELECT_ADDRESS_MODAL,
        isOpen: true,
      }));
    } else {
      dispatch(setModal({
        name: ModalNames.ADD_NEW_ADDRESS_MODAL,
        isOpen: true,
      }));
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: () => { },
    validationSchema,
  });

  const {
    values,
    isValid,
    validateForm,
    errors,
    touched,
    setFieldValue,
  } = formik;

  const openAddNewAddressModal = () => {
    dispatch(setModal({
      name: ModalNames.SELECT_ADDRESS_MODAL,
      isOpen: false,
    }));
    dispatch(setModal({
      name: ModalNames.ADD_NEW_ADDRESS_MODAL,
      isOpen: true,
    }));
  };

  useEffect(() => {
    dispatch(setIsOrderNewCardsFormValid(isValid && paycardsSelectedAddress !== null && isShippingFeeCalculated));
  }, [isValid, paycardsSelectedAddress, isShippingFeeCalculated]);

  useEffect(() => {
    setFormValues({
      ...values,
      shippingFee: shippingMethodFeeValue,
    });
  }, [values, setFormValues, shippingMethodFee]);

  useEffect(() => {
    validateForm(initialValues);
  }, []);

  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);

    timerRef.current = window.setTimeout(() => {
      timerRef.current = null;
      setCardsCountDebounce(values.cardsCount);
    }, 1000);

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [values.cardsCount]);

  useEffect(() => {
    if (organization?.id && cardStock?.locationId) {
      dispatch(fetchShippingAddresses({
        organizationId: organization?.id,
        locationId: cardStock?.locationId.toString(),
      }));
      dispatch(fetchShippingMethods({
        organizationId: organization?.id,
      }))
        .then((result) => {
          if (result?.payload?.values && result.payload.values.length > 0 && result.payload.values.length === 1) {
            const shippingMethod = result.payload.values[0];
            setFieldValue('shippingMethod', [{ label: shippingMethod, value: shippingMethod }]);
          }
        });
    }
  }, [organization, cardStock]);

  useEffect(() => {
    setIsShippingFeeCalculated(false);
    if (shippingMethods.length > 0 && cardsCountDebounce !== '' && values.shippingMethod.length > 0) {
      dispatch(fetchShippingMethodFee({
        cardsCount: Number(values.cardsCount),
        shippingMethod: values.shippingMethod[0].value,
      }))
        .then((result) => {
          const shippingFeeResult = result?.payload?.shippingFee;
          const shippingFeeUnscaled = unScalePrice(shippingFeeResult?.value || 0, shippingFeeResult?.scale || 2);
          if (shippingFeeUnscaled > balance.availableBalance) {
            setError(t('errors:errorNotEnoughtBalanceForShippingRequest'));
          } else if (error !== '') {
            setError('');
          }
          setIsShippingFeeCalculated(true);
        });
    }
  }, [cardsCountDebounce, values.shippingMethod]);

  return (
    <form>
      <OrderNewCardsOrderFormContext.Provider value={formik}>
        <Grid
          gridColumns={12}
          gridMargins={[12, 24]}
        >
          <LabelMedium margin="18px 18px 0 18px">
            {t('paycardManagement:paycardManagement.orderNewCards.cardAmountHeader')}
          </LabelMedium>
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={24}
          overrides={{
            Grid: {
              style: {
                flexDirection: 'column',
              },
            },
          }}
        >
          <AppInput
            name="cardsCount"
            inputProps={{
              id: 'cardsCount',
            }}
            formControlProps={{ htmlFor: 'cardsCount' }}
            cellSpan={[12, 3, 2]}
            context={OrderNewCardsOrderFormContext}
          />
          <Cell span={[12, 6, 2.5]}>
            <ParagraphSmall color="#757575" marginTop={0} hidden={!!errors.cardsCount && !!touched.cardsCount}>
              {`
                ${t('paycardManagement:paycardManagement.orderNewCards.currentStockLabel')}: ${cardStock?.availableCards}, 
                ${t('paycardManagement:paycardManagement.orderNewCards.thresholdLabel')}: ${cardStock?.threshold}
              `}
            </ParagraphSmall>
          </Cell>
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={[12, 24]}
          gridGaps={10}
        >
          <Block
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            <LabelLarge margin="18px">
              {t('paycardManagement:paycardManagement.orderNewCards.shippingInfoHeader')}
            </LabelLarge>
            <Button
              kind={KIND.tertiary}
              size={SIZE.compact}
              type="button"
              onClick={openSelectAddressModal}
              overrides={{
                BaseButton: {
                  props: {
                    id: 'PaycardManagementOrderNewCard-openSelectAdd',
                  },
                },
              }}
            >
              {t('common:change')}
            </Button>
          </Block>
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={24}
        >
          <Cell span={[12, 8, 4]}>
            {paycardsSelectedAddress && cardStock ? (
              <Block
                marginBottom="24px"
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                flexDirection="column"
              >
                <ParagraphMedium margin={0} whiteSpace="normal">
                  {paycardsSelectedAddress?.attention || emptyPlaceholder}
                </ParagraphMedium>
                <ParagraphMedium margin={0} whiteSpace="normal">
                  {paycardsSelectedAddress?.addressee || emptyPlaceholder}
                </ParagraphMedium>
                <ParagraphMedium margin={0} whiteSpace="normal">
                  {`P: ${paycardsSelectedAddress?.phone}` || emptyPlaceholder}
                </ParagraphMedium>
                <ParagraphMedium margin={0} whiteSpace="normal">
                  {`${paycardsSelectedAddress?.address?.addressLine1}${
                    paycardsSelectedAddress?.address?.addressLine2
                      ? `, ${paycardsSelectedAddress.address.addressLine2}`
                      : ''
                  }, ${paycardsSelectedAddress?.address?.city},
                  ${paycardsSelectedAddress?.address?.state},
                  ${paycardsSelectedAddress?.address?.postalCode}`
                  || emptyPlaceholder}
                </ParagraphMedium>
              </Block>
            )
              : (
                <Banner
                  kind={BannerKind.info}
                  hierarchy={HIERARCHY.low}
                  overrides={bannerStyles}
                >
                  <ParagraphSmall>
                    {t('paycardManagement:paycardManagement.orderNewCards.addShippingInformation')}
                  </ParagraphSmall>
                </Banner>
              )}
            <Loader active={pending} />
          </Cell>
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={24}
        >
          <Block
            overrides={shippingMethodContainerStyles}
          >
            <AppSelect
              name="shippingMethod"
              label={t('paycardManagement:paycardManagement.orderNewCards.shippingMethod')}
              options={shippingMethodsOptions}
              cellSpan={[12, 3, 2]}
              context={OrderNewCardsOrderFormContext}
              selectLoading={pendingShippingMethods}
              selectProps={{
                clearable: false,
              }}
              selectDisabled={shouldDisableShippingMethodField}
              placeholder={t('paycardManagement:paycardManagement.orderNewCards.shippingMethodPlaceholder')}
            />
            <Block
              overrides={shippingFeeContainerStyles}
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-end"
              gridGap="8px"
              height="36px"
            >
              <LabelSmall style={{ fontWeight: '600', whiteSpace: 'normal' }}>
                {t('paycardManagement:paycardManagement.orderNewCards.shippingFee')}
              </LabelSmall>
              <LabelSmall style={{ color: error !== '' ? colors.error : colors.black, fontWeight: 400 }}>
                {shippingMethodFeeValue}
                <AppTooltip title="" content={t('paycardManagement:paycardManagement.orderNewCards.shippingFeeTooltip')} />
              </LabelSmall>
            </Block>
          </Block>
          <Cell
            span={[12, 6, 2.5]}
          >
            <ParagraphXSmall color={colors.error} style={{ whiteSpace: 'normal', margin: 0, marginTop: '-8px' }}>
              {error}
            </ParagraphXSmall>
          </Cell>
        </Grid>
        {checkIsModalOpen(modals, ModalNames.SELECT_ADDRESS_MODAL) && (<OrderNewCardsSelectAddressModal openAddAddressModal={openAddNewAddressModal} />)}
        {checkIsModalOpen(modals, ModalNames.ADD_NEW_ADDRESS_MODAL) && (<OrderNewCardsAddAddressModal />)}
      </OrderNewCardsOrderFormContext.Provider>
    </form>
  );
};

export default OrderNewCardsOrderSection;
