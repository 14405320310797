import React, { Key } from 'react';
import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { LabelSmall, ParagraphSmall } from 'baseui/typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleExclamation, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Tag, VARIANT } from 'baseui/tag';
import { TETHER_PLACEMENT } from 'baseui/layer';
import { Block } from 'baseui/block';
import { PLACEMENT, StatefulPopover } from 'baseui/popover';
import { Button, KIND, SIZE } from 'baseui/button';
import { StatefulMenu } from 'baseui/menu';
import { Spinner, SIZE as SpinnerSize } from 'baseui/spinner';
import priceFormatter from 'utils/priceFormatter';
import { unScalePrice } from 'utils/priceScale';
import { colors, emptyPlaceholder } from 'theme';
import AppTooltip from 'components/Form/AppTooltip';
import Access, { AccessCheckType, AccessUnit } from 'components/Access/Access';
import hasAccess from 'utils/hasAccess';
import { useAppSelector } from 'store/hooks';
import { employeeDrawHistoryDeclineDrawPendingSelector, employeeDrawHistoryVoidDrawPendingSelector } from 'store/slices/employees';
import { pendingResendMoneyFromMFASelector } from 'store/slices/transactions';
import {
  Draw,
  EmployeeDrawsAvailableOperations,
  EmployeeIDType,
  SubElementPaymentFailedStatusType,
  SubElementStatusType,
} from 'types/EmployeeTypes';
import { Accordion, Panel } from 'baseui/accordion';
import { Card } from 'baseui/card';
import { extractAmountItem } from '../EmployeeDetailsTPODrawsTable';
import { accordionTPOListItemOverrides, listContainerStyles, outerContainerStyles } from '../../../EmployeesHelpers';

interface IItem {
  label: string;
  onClick?: () => void;
  isLoading?: boolean;
}

export type EmployeeDetailsTPODrawsTableItemPropsType = {
  payment: any,
  handleOfferIDClick: (selectedOffer: number | undefined) => void,
  employeeID: EmployeeIDType,
  handleAvailableOperationClick: (draw: Draw, type: EmployeeDrawsAvailableOperations) => void,
  clickedPaymentId: string | undefined,
}

const EmployeeDetailsTPODrawsTableItem = ({
  payment,
  employeeID,
  clickedPaymentId,
  handleOfferIDClick,
  handleAvailableOperationClick,
}: EmployeeDetailsTPODrawsTableItemPropsType) => {
  const [css, theme] = useStyletron();
  const { t } = useTranslation(['tipsManagement', 'dateFormats']);
  const dateFormat = t('dateFormats:standard-day-of-week-and-year');
  const isEwaManager = hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager]);
  const isDeclineDrawPending = useAppSelector(employeeDrawHistoryDeclineDrawPendingSelector);
  const isVoidDrawPending = useAppSelector(employeeDrawHistoryVoidDrawPendingSelector);
  const isRetryDrawPending = useAppSelector(pendingResendMoneyFromMFASelector);
  const hasDrawSubElements = payment?.subElements.length > 0;

  const NEUTRAL = {
    tagColor: theme.colors.backgroundTertiary,
    labelColor: theme.colors.contentSecondary,
  };

  const WARNING = {
    tagColor: theme.colors.backgroundWarningLight,
    labelColor: '#674D1B',
  };

  const EMPLOYEE_TPO_STATUS_TO_TAG_CONFIGS: {
    [index: string]: { tagColor: string, labelColor: string },
  } = {
    OK: NEUTRAL,
    EMPLOYEE_NOT_REGISTERED: WARNING,
    PAYMENT_PROCESSING: WARNING,
    PAYMENT_FAILED: WARNING,
    NON_TCO_EMPLOYMENT: WARNING,
    TCO_DEFAULT_ACCOUNT_NOT_SELECTED: WARNING,
  };

  const statusStyles = EMPLOYEE_TPO_STATUS_TO_TAG_CONFIGS[payment.offer.tcoStatus as keyof typeof EMPLOYEE_TPO_STATUS_TO_TAG_CONFIGS];
  const tpoSubElement = extractAmountItem(payment?.subElements);
  const tpoSubElementStatusNotOK = tpoSubElement?.status === SubElementStatusType.VOIDED
    || tpoSubElement?.status === SubElementStatusType.DECLINED
    || tpoSubElement?.status === SubElementStatusType.NOT_PAID;
  const loadingStateMap = {
    [EmployeeDrawsAvailableOperations.VOID]: isVoidDrawPending,
    [EmployeeDrawsAvailableOperations.RETRY]: isRetryDrawPending,
    [EmployeeDrawsAvailableOperations.DECLINE]: isDeclineDrawPending,
  };

  const getLoadingState = (type: EmployeeDrawsAvailableOperations) => loadingStateMap[type] ?? false;

  const listOfDropdownOptions: IItem[] = isEwaManager && payment?.availableOperations && payment.availableOperations.length > 0 && [
    ...(payment?.availableOperations.map((operation: EmployeeDrawsAvailableOperations) => ({
      label: t(`common:${operation.toLocaleLowerCase()}`),
      onClick: () => handleAvailableOperationClick(payment, operation),
      isLoading: getLoadingState(operation) && clickedPaymentId === `${payment?.offer?.id}`,
    })) || []),
  ];

  return (
    <>
      <div className={css(outerContainerStyles)}>
        <Grid
          gridColumns={12}
          align={ALIGNMENT.center}
          gridMargins={30}
        >
          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <ParagraphSmall color={theme.colors.contentSecondary}>
              {payment?.offer?.tcoStatus === 'OK' ? (
                <FontAwesomeIcon
                  className={css({ color: '#048848' })}
                  icon={faCheck}
                />
              ) : (
                <FontAwesomeIcon
                  className={css({ color: '#FFC043' })}
                  icon={faCircleExclamation}
                />
              )}
            </ParagraphSmall>
          </Cell>
          <Cell
            align={ALIGNMENT.center}
            span={2}
          >
            <ParagraphSmall color={tpoSubElementStatusNotOK ? '#AFAFAF' : theme.colors.contentSecondary} whiteSpace="normal">
              {moment(payment.drawRequestedDate).format(dateFormat)}
            </ParagraphSmall>
          </Cell>
          <Cell
            align={ALIGNMENT.center}
            span={1.5}
          >
            <ParagraphSmall color={tpoSubElementStatusNotOK ? '#AFAFAF' : theme.colors.contentSecondary}>
              {priceFormatter().format(unScalePrice(payment?.offer?.earningsToDate?.value))}
            </ParagraphSmall>
          </Cell>
          <Cell
            align={ALIGNMENT.center}
            span={1.5}
          >
            <ParagraphSmall color={tpoSubElementStatusNotOK ? '#AFAFAF' : theme.colors.contentSecondary}>
              {priceFormatter().format(unScalePrice(payment?.offer?.ewaAvailable?.value))}
            </ParagraphSmall>
          </Cell>
          <Cell
            align={ALIGNMENT.center}
            span={1.5}
          >
            <ParagraphSmall color={tpoSubElementStatusNotOK ? '#AFAFAF' : theme.colors.contentSecondary}>
              {priceFormatter().format(unScalePrice(tpoSubElement?.amount.value || 0))}
            </ParagraphSmall>
          </Cell>
          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <ParagraphSmall
              onClick={() => handleOfferIDClick(payment?.offer?.id)}
              color="#CC0033"
              overrides={{
                Block: {
                  style: {
                    cursor: 'pointer',
                    textDecorationLine: 'underline',
                  },
                },
              }}
            >
              {payment?.offer?.id || emptyPlaceholder}
            </ParagraphSmall>
          </Cell>
          <Cell
            align={ALIGNMENT.center}
            span={2.5}
          >
            <Tag
              closeable={false}
              variant={VARIANT.solid}
              overrides={{
                Root: {
                  style: {
                    backgroundColor: statusStyles.tagColor,
                    height: 'auto',
                  },
                },
                Text: {
                  style: {
                    maxWidth: 'unset',
                  },
                },
              }}
            >
              <Block
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <LabelSmall
                  color={statusStyles.labelColor}
                  width="fit-content"
                  whiteSpace="normal"
                >
                  {t(
                    `tipsManagement:employee.payments.statuses.${tpoSubElement?.status}`,
                    {
                      recipientDetails: `${tpoSubElement?.recipient?.accountNickname || ''} ${tpoSubElement?.recipient?.accountLastFour || ''}`,
                      TPOOfferStatus: `${t(`tipsManagement:statuses.${payment.offer.tcoStatus}`)}`,
                    },
                  )}
                </LabelSmall>

                {payment.offer.tcoStatus === 'PAYMENT_FAILED' && tpoSubElement?.statusDetails
                && (
                  <AppTooltip
                    title={t('tipsManagement:statuses.PAYMENT_FAILED')}
                    content={t(`tipsManagement:payments.failed.statuses.${tpoSubElement?.statusDetails as SubElementPaymentFailedStatusType}`)}
                    placement={TETHER_PLACEMENT.right}
                    iconColor="rgb(153, 111, 0)"
                  />
                )}
              </Block>
            </Tag>
          </Cell>

          <Cell
            align={ALIGNMENT.center}
            span={1}
          >
            <Access
              oneOf={[
                AccessUnit.EWAManager,
              ]}
            >
              <StatefulPopover
                content={({ close }) => (
                  <StatefulMenu
                    items={listOfDropdownOptions && listOfDropdownOptions.map((item) => ({
                      ...item,
                      label: item.isLoading ? (
                        <Block
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          minHeight="20px"
                        >
                          <Spinner
                            style={{
                              borderRightColor: colors.primary,
                              borderTopColor: colors.primary,
                              borderLeftColor: colors.primary,
                              borderBottomColor: 'transparent',
                              width: 12,
                              height: 12,
                            }}
                            $size={SpinnerSize.small}
                          />
                        </Block>
                      ) : (
                        item.label
                      ),
                    }))}
                    onItemSelect={(item) => {
                      item.item.onClick(payment, { close });
                    }}
                  />
                )}
                accessibilityType="tooltip"
                placement={PLACEMENT.right}
              >
                <Button
                  size={SIZE.compact}
                  kind={KIND.tertiary}
                  overrides={{
                    Root: {
                      props: {
                        id: `EmployeeDetailsTPOSection-employee-${employeeID}-menu`,
                      },
                      style: {
                        color: colors.primary,
                        backgroundColor: 'inherit',
                        ':hover': {
                          backgroundColor: 'inherit',
                        },
                      },
                    },
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEllipsisVertical}
                  />
                </Button>
              </StatefulPopover>
            </Access>
          </Cell>
        </Grid>
      </div>
      <Access
        not
        oneOf={[
          AccessUnit.EWAClientManager,
        ]}
      >
        <Accordion
          overrides={accordionTPOListItemOverrides}
        >
          <Panel
            key={payment?.id as Key}
          >
            {hasDrawSubElements && (
            <Grid
              gridColumns={12}
              align={ALIGNMENT.center}
              gridMargins={4}
            >
              <Cell
                align={ALIGNMENT.center}
                span={3}
              >
                <Block
                  marginLeft="48px"
                >
                  <strong>{t('employees:drawSubElement.type')}</strong>
                </Block>
              </Cell>

              <Cell
                align={ALIGNMENT.center}
                span={1}
              >
                <strong>{t('employees:drawSubElement.value')}</strong>
              </Cell>

              <Cell
                align={ALIGNMENT.center}
                span={1}
              >
                <strong>{t('employees:drawSubElement.id')}</strong>
              </Cell>

              <Cell
                align={ALIGNMENT.center}
                span={1}
              >
                <strong>{t('employees:drawSubElement.batchId')}</strong>
              </Cell>

              <Cell
                align={ALIGNMENT.center}
                span={3}
              >
                <strong>{t('employees:drawSubElement.status')}</strong>
              </Cell>
            </Grid>
            )}

            <div className={css(listContainerStyles)}>

              {[...payment.subElements]
                ?.sort((a, b) => a.id - b.id)
                ?.map((drawSubElement) => (
                  <div key={drawSubElement.id} className={css(outerContainerStyles)}>
                    <Grid
                      gridColumns={12}
                      align={ALIGNMENT.center}
                      gridMargins={0}
                    >
                      <Cell
                        align={ALIGNMENT.center}
                        span={3}
                      >
                        <Block
                          marginLeft="48px"
                        >
                          {`${t(`tipsManagement:employee.payments.subelement.types.${drawSubElement.type}`)}` || emptyPlaceholder}
                        </Block>
                      </Cell>

                      <Cell
                        align={ALIGNMENT.center}
                        span={1}
                      >
                        {priceFormatter().format(unScalePrice(drawSubElement.amount.value)) || emptyPlaceholder}
                      </Cell>

                      <Cell
                        align={ALIGNMENT.center}
                        span={1}
                      >
                        {drawSubElement.id || emptyPlaceholder}
                      </Cell>

                      <Cell
                        align={ALIGNMENT.center}
                        span={1}
                      >
                        {drawSubElement.batchId || emptyPlaceholder}
                      </Cell>

                      <Cell
                        align={ALIGNMENT.center}
                        span={3}
                      >
                        {`${t(`tipsManagement:employee.payments.subelement.statuses.${drawSubElement.status}`)}` || emptyPlaceholder}
                      </Cell>
                    </Grid>
                  </div>
                ))}

              {!hasDrawSubElements && (
              <Block
                marginTop="24px"
              >
                <Grid
                  gridColumns={12}
                  gridMargins={0}
                >
                  <Cell span={12}>
                    <Card>
                      {t('employees: noDrawSubElements')}
                    </Card>
                  </Cell>
                </Grid>
              </Block>
              )}
            </div>
          </Panel>
        </Accordion>
      </Access>
    </>
  );
};

export default EmployeeDetailsTPODrawsTableItem;
