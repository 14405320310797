import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import { memo } from 'react';
import { outerContainerStyles } from 'screens/Employees/EmployeesHelpers';
import { emptyPlaceholder } from 'theme';
import { TreasurySummaryListItemDetailsListItemType } from 'types/ReportsTypes';
import PriceFormatter from 'utils/priceFormatter';
import { unScalePrice } from 'utils/priceScale';

export type TreasurySummaryListItemDetailsListItemPropsType = {
  record: TreasurySummaryListItemDetailsListItemType
}

const TreasurySummaryListItemDetailsListItem = ({
  record,
}: TreasurySummaryListItemDetailsListItemPropsType) => {
  const {
    organization,
    summaryItem,
  } = record || {};

  const [css] = useStyletron();

  const unscaledTotalAmount = summaryItem ? unScalePrice(summaryItem?.amountTotal.value, summaryItem?.amountTotal.scale) : undefined;
  const unscaledAmountReceived = summaryItem ? unScalePrice(summaryItem?.amountReceived?.value, summaryItem?.amountReceived?.scale) : undefined;
  const unscaledAmountDue = summaryItem ? unScalePrice(summaryItem?.amountDue?.value, summaryItem?.amountDue?.scale) : undefined;

  return (
    <div className={css(outerContainerStyles)}>
      <Grid
        gridColumns={12}
        gridMargins={0}
      >

        <Cell
          align={ALIGNMENT.center}
          span={3}
        >
          <Block
            marginLeft="24px"
          >
            {organization.name}
          </Block>
        </Cell>

        <Cell
          span={1}
          align={ALIGNMENT.center}
        >
          {summaryItem?.totalRecordsInSummary || emptyPlaceholder}
        </Cell>

        <Cell span={2} align={ALIGNMENT.center}>
          {unscaledTotalAmount !== undefined
            ? PriceFormatter().format(unscaledTotalAmount)
            : emptyPlaceholder}
        </Cell>

        <Cell span={2} align={ALIGNMENT.center}>
          {unscaledAmountReceived !== undefined
            ? PriceFormatter().format(unscaledAmountReceived)
            : emptyPlaceholder}
        </Cell>

        <Cell span={2} align={ALIGNMENT.center}>
          {unscaledAmountDue !== undefined
            ? PriceFormatter().format(unscaledAmountDue)
            : emptyPlaceholder}
        </Cell>

      </Grid>
    </div>
  );
};

export default memo(TreasurySummaryListItemDetailsListItem);
