import { TimeCard, Tip } from 'types/OfferTypes';
import { EmploymentIDType, SubElementPaymentMethodEnum } from 'types/EmployeeTypes';
import { MoneyDtoType } from './CommonTypes';
import { OrganizationIDType } from './OrganizationTypes';
import { LocationIDType } from './LocationTypes';
import { PayrollPeriodIDType } from './PayGroupTypes';

export type PaymentIDType = number | undefined;

export type SearchPayrollPeriodDropDown = {
    name: string,
    id: string | number,
    status: string,
    startDate: string,
    endDate: string,
    tcoCutoffTime: string,
    ewaStatus: string,
    tcoStatus: string,
};

export interface TippedEmployeePerPayrollPeriod {
    id: number,
    payrollId: string,
    name: string,
    tipsPaid: MoneyDtoType,
    balance: MoneyDtoType,
    tipsEarnedToDate: MoneyDtoType,
    tipsPayable: MoneyDtoType,
    status: string,
    employmentStatus: string
    activePrograms: string[]
    failed: boolean
    finalInPeriod: boolean
    highEarner: boolean
    linked: boolean
    thresholdReached: boolean
    selected?: boolean,
    failedPaymentStatusDetails?: string
}

export interface TippedEmployeePerBusinessDate {
    id: number,
    payrollId: string,
    firstName: string,
    lastName: string,
    status: string,
    odpProgram: string,
    thirdPartyTips?: MoneyDtoType,
    creditCardTips?: MoneyDtoType,
    serviceChargeTips?: MoneyDtoType,
    tipPoolTips?: MoneyDtoType,
    declaredTips?: MoneyDtoType,
    cashTips?: MoneyDtoType,
    totalTips?: MoneyDtoType,
}

export enum TippedEmployeePerBusinessDateEditableColumns {
  EXTERNAL = 'thirdPartyTips',
  CARD = 'creditCardTips',
  SERVICE = 'serviceChargeTips',
  POOL = 'tipPoolTips',
  DECLARED = 'declaredTips',
  CASH = 'cashTips',
}

export interface ColumnWithEditability {
  key: keyof TippedEmployeePerBusinessDate;
  isEditable: boolean;
  isEditingEnabled: boolean;
}

export interface TipBalance {
    balance: MoneyDtoType,
    tipsEarnedToDate: MoneyDtoType,
    tipsPayable: MoneyDtoType,
    previousPaymentsAmount: MoneyDtoType,
    transferFee: MoneyDtoType,
    periodFee: MoneyDtoType,
    feesPaidByEmployer: boolean,
    defaultAccountId: string,
    allowedPaymentMethods: [
        {
            paymentMethod: string,
            fee: MoneyDtoType
        }
    ],
    data: {
        timeCards?: TimeCard[],
        tips: Tip[]
    },
}

export interface TPOPayment {
    id: PaymentIDType,
    tcoOfferId: number,
    paymentMethod: SubElementPaymentMethodEnum,
    recipientAccountId: string,
    status: string,
    requestedDate: string,
    feesPaidByEmployer: boolean,
    amount: MoneyDtoType,
    balance: MoneyDtoType,
    tipsEarnedToDate: MoneyDtoType,
    tipsPayable: MoneyDtoType,
    previousPaymentsAmount: MoneyDtoType,
    transferFee: MoneyDtoType,
    periodFee: MoneyDtoType,
    location: {
        id: number,
        name: string,
    },
    payrollPeriod: {
        id: number,
        startDate: string
        endDate: string
        tcoCutoffTime: string
        officialCutoffTime: string
    }
}

export type FetchTippedEmployeesByPayPeriodParamsType = {
    organizationID: OrganizationIDType,
    locationID: LocationIDType,
    payrollPeriodID: PayrollPeriodIDType,
    pageNumber: string,
    statuses: string,
    employmentStatuses: string,
    name: string
}

export type FetchTippedEmployeesByBusinessDateParamsType = {
    organizationID: OrganizationIDType,
    locationID: LocationIDType,
    businessDate: string,
    pageNumber: string,
    namePattern?: string,
    firstName?: string,
    lastName?: string,
    payrollId?: string,
    statuses?: string,
}

export type FetchTippedEmployeeBalanceOfferParamsType = {
    locationID: LocationIDType,
    employeeID: string | undefined,
    payrollPeriodID: PayrollPeriodIDType,
}

export enum TPOEmployeeStatuses {
    EMPLOYEE_NOT_REGISTERED = 'EMPLOYEE_NOT_REGISTERED',
    OK = 'OK',
    PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
    PAYMENT_FAILED = 'PAYMENT_FAILED',
    NON_TCO_EMPLOYMENT = 'NON_TCO_EMPLOYMENT',
    TCO_DEFAULT_ACCOUNT_NOT_SELECTED = 'TCO_DEFAULT_ACCOUNT_NOT_SELECTED',
}

export type InitiateTPOPaymentsParamsType = {
    organizationID: OrganizationIDType,
    locationID: LocationIDType,
    payrollPeriodID: PayrollPeriodIDType,
    employmentIds: string
}

export type FetchTippedEmployeeTPOPaymentsPaymentsParamsType = {
    employeeID: EmploymentIDType,
    locationID: LocationIDType,
    payrollPeriodID: PayrollPeriodIDType,
}

export type SetOrganizationTPOSettingsRequest = {
    employerPayTcoFees: boolean,
    effectiveDate: string,
    allowedMethods: {
        paymentMethod: SubElementPaymentMethodEnum,
        fee: MoneyDtoType,
        enabled: boolean
    }[]
}
export type SetBankingOrganizationTPOSettingsParamsType = {
    organizationID: OrganizationIDType,
    data: SetOrganizationTPOSettingsRequest
}

export type FetchTPOPayrollFileParamsType = {
    payrollPeriodID: PayrollPeriodIDType,
    locationID?: LocationIDType,
}

export type FetchTPODailyFileParamsType = {
    organizationID: OrganizationIDType,
    locationID: LocationIDType,
    businessDate: string,
    statuses?: string
    locationName?: string,
    onlyThirdParty?: boolean
}

export type ImportTipsFilePropsType = {
    organizationID: OrganizationIDType,
    locationID: LocationIDType,
    formData: FormData
}

export type ImportTipsFilesRequestType = {
    csvFile: File | undefined
    payrollDate: string,
}

export type SaveTPOPayoutConfigsParamsType = {
  organizationID: OrganizationIDType,
  locationID: LocationIDType,
  data: { payoutTimes: string }
}

export type FetchEmployeeTipsTotalParamsType = {
  organizationID: OrganizationIDType,
  locationID: LocationIDType,
  businessDate: string,
  namePattern?: string,
  payrollId?: string,
  statuses?: string,
}

export type EmployeeTipsTotal = {
  thirdPartyTips: MoneyDtoType,
  creditCardTips: MoneyDtoType,
  serviceChargeTips: MoneyDtoType,
  tipPoolTips: MoneyDtoType,
  declaredTips: MoneyDtoType,
  cashTips: MoneyDtoType,
  totalTips: MoneyDtoType,
  manualTipTypesOverwriteEnabled: TippedEmployeePerBusinessDateEditableColumns[],
  subtractedTipTypes: TippedEmployeePerBusinessDateEditableColumns[],
  custom3rdPartyTipsLabel: string
}
