import { colors } from 'theme';

export const contentContainerStyles = {
  padding: '0 0 8px 0',
  background: '#E5E5E5',
  marginTop: '24px',
  zIndex: 50,
  minHeight: '100vh',
};

export const containerStyles = {
  display: 'inherit',
  width: '100%',
  background: '#F6F6F6',
  minHeight: '100vh',
};

export const imageContainerStyles = {
  width: '100%',
  'text-align': 'center',
  marginBottom: '38px',
};

export const buttonsContainerStyles = {
  '@media screen and (min-width: 600px)': {
    display: 'inline-flex',
    justifyItems: 'end',
    justifyContent: 'flex-end',
  },
};

export const blockRightMarginOverrides = {
  marginRight: '-12px',
  '@media screen and (max-width: 1135px)': {
    marginRight: '0',
  },
};

export const bannerStyles = {
  Root: {
    style: {
      margin: '0',
      marginBottom: '24px',
      backgroundColor: colors.bannerInfo,
      width: 'auto',
    },
  },
};

export const checkboxParagraphStyles = {
  width: '100%',
  wordWrap: 'break-word',
  whiteSpace: 'normal',
};

export const shippingMethodContainerStyles = {
  Block: {
    style: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gridGap: '6px',
      width: '100%',

      '@media (max-width: 600px)': {
        flexDirection: 'column',
        display: 'block',
      },
    },
  },
};

export const shippingFeeContainerStyles = {
  Block: {
    style: {
      display: 'flex',
      justifyContent: ' flex-start',
      alignItems: 'flex-end',
      gridGap: '8px',
      height: '36px',
      '@media (max-width: 600px)': {
        margin: '8px 8px',
      },
    },
  },
};

export const addressContainerStyles = {
  Block: {
    style: {
      display: 'flex',
      justifyContent: ' flex-start',
      alignItems: 'space-between',
      gridGap: '16px',
      flexDirection: 'row',
      width: '100%',
      '@media (max-width: 1135px)': {
        flexDirection: 'column',
      },
    },
  },
};
