/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { Block } from 'baseui/block';
import { Skeleton } from 'baseui/skeleton';
import { blockOverrides, tableCellStyle, tableRowStyle } from './SkeletonHelpers';

type SkeletonTableProps = {
  rows: number;
  columns: number;
  tableRowStylesOverride?: React.CSSProperties;
  cellStylesOverride?: React.CSSProperties;
};

const SkeletonTable: React.FC<SkeletonTableProps> = ({
  rows,
  columns,
  cellStylesOverride,
  tableRowStylesOverride,
}) => {
  const validColumns = Math.min(columns, 12);
  const cellWidth = `${100 / validColumns}%`;

  return (
    <Block
      overrides={{
        Block: {
          style: blockOverrides,
        },
      }}
      data-testid="skeleton-table"
    >
      <Grid overrides={{
        Grid: {
          style: {
            ...tableRowStyle,
            ...tableRowStylesOverride,
          },
        },
      }}
      >
        {Array.from({ length: validColumns }).map((_, index) => (
          <Cell
            key={index}
            overrides={{
              Cell: {
                style: {
                  '@media screen and (max-width: 2000px)': {
                    width: `calc(${cellWidth} - 36px)`,
                  },
                  '@media screen and (max-width: 600px)': {
                    width: `calc(${cellWidth} - 16px)`,
                  },
                },
              },
            }}
          >
            <Block
              overrides={{
                Block: {
                  style: {
                    ...tableCellStyle,
                    ...cellStylesOverride,
                  },
                },
              }}
            >
              <Skeleton height="20px" width="100%" animation />
            </Block>
          </Cell>
        ))}
      </Grid>

      {Array.from({ length: rows }).map((_, rowIndex) => (
        <Grid
          key={rowIndex}
          overrides={{
            Grid: {
              style: {
                ...tableRowStyle,
                ...tableRowStylesOverride,
              },
            },
          }}
        >
          {Array.from({ length: validColumns }).map((__, colIndex) => (
            <Cell
              key={colIndex}
              overrides={{
                Cell: {
                  style: {
                    '@media screen and (max-width: 2000px)': {
                      width: `calc(${cellWidth} - 36px)`,
                    },
                    '@media screen and (max-width: 600px)': {
                      width: `calc(${cellWidth} - 16px)`,
                    },
                  },
                },
              }}
            >
              <Block
                overrides={{
                  Block: {
                    style: {
                      ...tableCellStyle,
                      ...cellStylesOverride,
                    },
                  },
                }}
              >
                <Skeleton height="16px" width="100%" animation />
              </Block>
            </Cell>
          ))}
        </Grid>
      ))}
    </Block>
  );
};

export default SkeletonTable;
