import { useStyletron } from 'baseui';
import {
  useEffect,
  createContext,
  useState,
  memo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  Grid,
  ALIGNMENT,
  Cell,
} from 'baseui/layout-grid';
import AccordionSection from 'components/AccordionSection/AccordionSection';
import { useHistory } from 'react-router-dom';
import {
  administratorSelector,
  resetAdministatorsSearchFilterOnly,
  updateAdministrator,
} from 'store/slices/administrators';
import {
  FormikHandlers,
  FormikState,
  useFormik,
} from 'formik';
import { adminProfileInitialValues } from 'initialValues/AdministratorInitialValues';
import profileValidationSchema from 'validation/editAdministratorSchema';
import AppInput from 'components/Form/AppInput';
import useIsFormChanged from 'hooks/useIsFormChanged';
import { EditAdministratorFormType } from 'types/AdministratorsTypes';
import {
  fetchAllOrganizations,
  organizationsPendingListSelector,
  organizationsSelector,
} from 'store/slices/organizations';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import { Block } from 'baseui/block';
import { Button, KIND } from 'baseui/button';
import Loader from 'components/Loader';
import {
  fetchLocations,
  locationsSelector,
} from 'store/slices/locations';
import AppFormControl from 'components/Form/AppFormControl';
import {
  Select,
  SIZE,
  Value,
} from 'baseui/select';
import {
  COUNTRIES,
  PhoneInput,
} from 'baseui/phone-input';
import { HeadingXSmall, LabelMedium } from 'baseui/typography';
import AppCheckbox from 'components/Form/AppCheckbox';
import { LABEL_PLACEMENT } from 'baseui/checkbox';
import hasAccess from 'utils/hasAccess';
import {
  AccessCheckType,
  AccessUnit,
} from 'components/Access/Access';
import { userAccessUnitsSelector } from 'store/slices/user';
import AppSelect from 'components/Form/AppSelect';
import { editAdministratorMapper } from 'dataMappers/administratorDataMapper';
import {
  administratorsStatuses,
  checkboxLabelOverrides,
  checkForAdminRules,
  roleValueToRoleProp,
} from '../AdministratorsHelpers';

export const ProfileFormContext = createContext(
  {} as FormikState<EditAdministratorFormType> & FormikHandlers,
);
export const contentContainerStyles = {
  padding: '0 0 8px 0',
  background: '#E5E5E5',
  marginTop: '24px',
  zIndex: 50,
  minHeight: '100vh',
};

const EditAdministratorForm = () => {
  const [css] = useStyletron();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation(['administrators', 'errors', 'common', 'organization']);
  const [organizationIDChanged, setOrganizationIDChanged] = useState<boolean>(false);
  const [phoneInputText, setPhoneInputText] = useState('');
  const [phoneInputCountry, setPhoneInputCountry] = useState(COUNTRIES.US);
  const organizations = useAppSelector(organizationsSelector);
  const locations = useAppSelector(locationsSelector);
  const [profile, setProfile] = useState<Value>();
  const [profileChanged, setProfileChanged] = useState<boolean>(false);
  const editorRoles = useAppSelector(userAccessUnitsSelector);
  const pending = useAppSelector(organizationsPendingListSelector);
  const admin = useAppSelector(administratorSelector);
  const initialRoles = Object.entries(roleValueToRoleProp).map(
    ([key, value]) => ({ [value]: admin?.roles?.indexOf(key) !== -1 }),
  );

  const adminProfileEditorEWAManager = [
    { value: 'ewaManager', label: t('administrators:adminRole.fuegoAdmin') },
    { value: 'ewaClientManager', label: t('administrators:adminRole.customerAdmin') },
    { value: 'ewaClientLocationManager', label: t('administrators:adminRole.customerLocationManager') },
  ];
  const adminProfileEditorEWAClientManager = [
    { value: 'ewaClientManager', label: t('administrators:adminRole.customerAdmin') },
    { value: 'ewaClientLocationManager', label: t('administrators:adminRole.customerLocationManager') },
  ];

  const initialValues = {
    ...adminProfileInitialValues,
    ...admin,
    ...Object.assign({}, ...initialRoles),
    organizationID: admin?.organizations ? admin?.organizations?.map((org) => ({ label: org.name || '', value: org.id || '' }))
      : null,
    locationID: admin?.locations ? admin?.locations?.map((loc) => ({ label: loc.name || '', value: loc.id || '' }))
      : null,
    active: admin?.active
      ? [administratorsStatuses[0]]
      : [administratorsStatuses[1]],
  };

  const organizationOptions = organizations.length > 0 ? organizations?.map((org) => ({ label: org?.name, value: org?.id }))
    ?.sort((a, b) => a.label.localeCompare(b.label)) : [];

  const locationsOptions = locations.length > 0 ? locations?.map((loc) => ({ label: loc?.name, value: loc?.id }))
    ?.sort((a, b) => a.label.localeCompare(b.label)) : [];

  const handleClickCancel = () => {
    history.push('/administrators');
  };

  const handleClickSave = (
    values: EditAdministratorFormType,
  ) => {
    const disabled = values?.active?.[0]?.value.toLowerCase() !== 'active';
    const data = editAdministratorMapper({
      ...values,
      disabled,
    });
    dispatch(updateAdministrator({ data }))
      .then(() => {
        dispatch(resetAdministatorsSearchFilterOnly());
        handleClickCancel();
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: profileValidationSchema,
    onSubmit: handleClickSave,
    initialTouched: {
      mobilePhone: true,
    },
  });

  const {
    values,
    handleSubmit,
    isSubmitting,
    isValid,
    setValues,
    setFieldValue,
    errors,
    touched,
    handleBlur,
  } = formik;

  const { hasRules } = checkForAdminRules(values);

  const { isFormChanged, setDefaultValues } = useIsFormChanged(values);

  const isAdministratorInactive = values?.active?.[0]?.value.toLowerCase() !== 'active';

  useEffect(() => {
    if (admin) {
      setDefaultValues(initialValues);
      admin?.mobilePhone && setPhoneInputText(admin?.mobilePhone);
    }
  }, [admin]);

  useEffect(() => {
    if (organizationIDChanged
      && (values.organizationID && values.organizationID.length > 0 && values.organizationID?.[0].value)) {
      setValues({
        ...values,
        locationID: [],
      });
    }

    if (values.ewaClientLocationManager && !!values.organizationID && values.organizationID[0].value !== '') {
      dispatch(fetchLocations({
        organizationID: values.organizationID && values.organizationID[0].value,
      }));
    }
  }, [values.organizationID,
    organizationIDChanged,
  ]);

  useEffect(() => {
    if (profile && profile.length > 0 && profileChanged) {
      setValues({
        ...values,
        ewaManager: profile?.[0].value === 'ewaManager',
        ewaClientManager: profile?.[0].value === 'ewaClientManager',
        ewaClientLocationManager: profile?.[0].value === 'ewaClientLocationManager',
        treasuryManager: false,
        treasuryReader: false,
        fboManager: false,
        fboReader: false,
        tipsReader: false,
        ewaReport: false,
        tipsClientManager: false,
        fddClientManager: false,
        paycardClientManager: false,
        paycardReader: false,
        organizationID: profile?.[0].value === 'ewaClientManager' ? [] : [{ label: '', value: '' }],
        locationID: [],
      });
    }
    setProfileChanged(false);
  }, [profileChanged,
    profile]);

  useEffect(() => {
    values.ewaManager && setProfile(
      [
        {
          value: 'ewaManager',
          label: t('administrators:adminRole.fuegoAdmin'),
        },
      ],
    );
    values.ewaClientManager && setProfile(
      [
        {
          value: 'ewaClientManager',
          label: t('administrators:adminRole.customerAdmin'),
        },
      ],
    );
    values.ewaClientLocationManager && setProfile(
      [
        {
          value: 'ewaClientLocationManager',
          label: t('administrators:adminRole.customerLocationManager'),
        },
      ],
    );
    if (organizations && organizations.length < 51) {
      dispatch(fetchAllOrganizations({ pageSize: '1000' }));
    }
  }, []);

  return (
    <ProfileFormContext.Provider value={formik}>
      <div className={css(contentContainerStyles)}>
        <Loader active={pending || isSubmitting} />
        <form onSubmit={handleSubmit}>
          <CommonHeader backTo="/administrators" title={t('administrators:editAdmin')} />
          <div className={css(contentContainerStyles)}>
            <AccordionSection
              expanded
              onExpand={() => { }}
              showStatus={false}
              title={t('administrators:profile')}
              disabled
            >
              <Grid
                align={ALIGNMENT.center}
                gridColumns={12}
              >
                <AppSelect
                  showStar
                  searchable={false}
                  cellSpan={[12, 6, 2]}
                  name="active"
                  label={t('administrators:status')}
                  placeholder={t('common:select')}
                  options={administratorsStatuses}
                  context={ProfileFormContext}
                  selectProps={{
                    clearable: false,
                  }}
                  maxDropdownHeight="300px"
                />
              </Grid>
              <Grid
                gridColumns={12}
                align={ALIGNMENT.center}
              >
                <Cell
                  span={12}
                  align={ALIGNMENT.start}
                >
                  <HeadingXSmall margin="16px 0">{t('administrators:personalDetails.header')}</HeadingXSmall>
                </Cell>
              </Grid>
              <Grid
                align={ALIGNMENT.center}
                gridColumns={12}
              >
                <AppInput
                  showStar
                  name="firstName"
                  placeholder="John"
                  inputProps={{
                    id: 'firstName',
                    autoComplete: 'off',
                    disabled: isAdministratorInactive,
                  }}
                  formControlProps={{
                    htmlFor: 'firstName',
                  }}
                  label={t('administrators:labelFirstName')}
                  cellSpan={[12, 6, 3]}
                  context={ProfileFormContext}
                />
                <AppInput
                  showStar
                  name="lastName"
                  placeholder="Doe"
                  inputProps={{
                    id: 'lastName',
                    autoComplete: 'off',
                    disabled: isAdministratorInactive,
                  }}
                  formControlProps={{
                    htmlFor: 'lastName',
                  }}
                  label={t('administrators:labelLastName')}
                  cellSpan={[12, 6, 3]}
                  context={ProfileFormContext}
                />
                <AppInput
                  showStar
                  name="email"
                  placeholder="john.doe@example.org"
                  inputProps={{
                    id: 'email',
                    autoComplete: 'off',
                    disabled: isAdministratorInactive,
                  }}
                  formControlProps={{
                    htmlFor: 'email',
                  }}
                  label={t('administrators:labelEmail')}
                  cellSpan={[12, 6, 3]}
                  context={ProfileFormContext}
                />
              </Grid>
              <Grid
                align={ALIGNMENT.center}
                gridColumns={12}
              >
                <Cell
                  span={[12, 6, 3]}
                  align={ALIGNMENT.start}
                >
                  <AppFormControl
                    label={t('administrators:labelPhoneNumber')}
                    error={!!(errors.mobilePhone && touched.mobilePhone) && errors.mobilePhone}
                  >
                    <PhoneInput
                      id="mobilePhone"
                      name="mobilePhone"
                      placeholder="(555) 555-1234"
                      size={SIZE.mini}
                      text={phoneInputText}
                      country={phoneInputCountry}
                      onTextChange={(event) => {
                        const phoneNumber = event.currentTarget.value.replace(/\D/g, '');

                        let formattedNumber = '';
                        if (phoneNumber.length < 4) {
                          formattedNumber = phoneNumber;
                        } else if (phoneNumber.length >= 4 && phoneNumber.length < 7) {
                          formattedNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
                        } else {
                          formattedNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
                        }
                        setPhoneInputText(formattedNumber);
                        setFieldValue('mobilePhone', formattedNumber.replace(/\D/g, ''));
                      }}
                      onCountryChange={(event: any) => {
                        setPhoneInputCountry(event.option);
                      }}
                      disabled={isAdministratorInactive}
                      error={!!(errors.mobilePhone && touched.mobilePhone && errors.mobilePhone)}
                      overrides={{
                        Input: {
                          props: {
                            autoComplete: 'off',
                          },
                        },
                        CountrySelect: {
                          props: {
                            disabled: true,
                            overrides: {
                              ControlContainer: {
                                style: {
                                  width: '64px',

                                },
                              },
                              Root: {
                                style: {
                                  marginRight: '16px',
                                },
                              },
                            },
                          },
                        },
                      }}
                    />
                  </AppFormControl>
                </Cell>
              </Grid>
              <br />

              <Grid
                gridColumns={12}
                align={ALIGNMENT.center}
              >
                <Cell
                  span={12}
                  align={ALIGNMENT.start}
                >
                  <HeadingXSmall margin="16px 0">{t('administrators:permissions.header')}</HeadingXSmall>
                </Cell>
              </Grid>

              <Grid
                align={ALIGNMENT.center}
                gridColumns={12}
              >
                <Cell
                  span={[12, 6, 3]}
                  align={ALIGNMENT.start}
                >
                  <AppFormControl
                    showStar
                    label={t('administrators:adminRole.label')}
                  >
                    <Select
                      size={SIZE.compact}
                      clearable={false}
                      id="adminProfile"
                      overrides={{
                        ControlContainer: { props: { 'data-testid': 'select-adminProfile', id: 'adminProfile-container' } },
                        Input: { props: { 'data-testid': 'select-adminProfile-input', id: 'adminProfile-input' } },
                      }}
                      disabled={isAdministratorInactive}
                      placeholder={t('common:select')}
                      type="select"
                      options={editorRoles.includes(AccessUnit.EWAClientManager) ? adminProfileEditorEWAClientManager : adminProfileEditorEWAManager}
                      labelKey="label"
                      valueKey="value"
                      onChange={({ value }) => {
                        setProfile(value);
                        setProfileChanged(true);
                      }}
                      value={profile as Value}
                      maxDropdownHeight="300px"
                    />
                  </AppFormControl>
                </Cell>
                {profile && profile.length > 0 && profile[0].value !== 'ewaManager' && (
                <Cell
                  span={[12, 6, 3]}
                  align={ALIGNMENT.start}
                >
                  <AppFormControl
                    showStar
                    label={t('administrators:chooseOrganization')}
                    error={!!(errors.organizationID && touched.organizationID && errors.organizationID)}
                  >
                    <Select
                      size={SIZE.compact}
                      id="organizationID"
                      type="select"
                      clearable={false}
                      multi={values.ewaClientManager}
                      searchable
                      options={organizationOptions}
                      labelKey="label"
                      valueKey="value"
                      maxDropdownHeight="280px"
                      onBlur={handleBlur}
                      placeholder={t('common:select')}
                      value={values.organizationID}
                      disabled={isAdministratorInactive}
                      onChange={(option) => {
                        setFieldValue('organizationID', values.ewaClientManager ? option.value : [option.option]);
                        setOrganizationIDChanged(true);
                      }}
                      error={!!(errors.organizationID && touched.organizationID && errors.organizationID)}
                      overrides={{
                        ValueContainer: {
                          props: {
                            'data-testid': 'Form-select-create-admin-loc-manager-organizationID',
                          },
                        },
                        ControlContainer: {
                          props: {
                            id: 'Form-select-create-admin-loc-manager-organizationID',
                          },
                        },
                        Input: {
                          props: {
                            id: 'Form-select-input-create-admin-loc-manager-organizationID',
                            name: 'Form-select-input-name-create-admin-loc-manager-organizationID',
                          },
                        },
                      }}
                    />
                  </AppFormControl>
                </Cell>

                )}
                {profile && profile.length > 0 && profile[0].value === 'ewaClientLocationManager' && (

                <Cell
                  span={[12, 6, 3]}
                  align={ALIGNMENT.start}
                >
                  <AppFormControl
                    showStar
                    label={t('administrators:chooseLocation')}
                  >
                    <Select
                      size={SIZE.compact}
                      id="locationID"
                      type="select"
                      searchable
                      clearable={false}
                      multi
                      options={locationsOptions}
                      labelKey="label"
                      valueKey="value"
                      maxDropdownHeight="280px"
                      onBlur={handleBlur}
                      placeholder={t('common:select')}
                      value={values.locationID}
                      onChange={(option) => { setFieldValue('locationID', option.value); }}
                      error={!!(errors.locationID && touched.locationID && errors.locationID)}
                      disabled={(values.organizationID && values.organizationID.length > 0
                        && (values.organizationID?.[0].value === '' || values.organizationID?.[0].value === undefined)) || isAdministratorInactive}
                      overrides={{
                        ValueContainer: {
                          props: {
                            'data-testid': 'Form-select-create-admin-locationID',
                          },
                        },
                        ControlContainer: {
                          props: {
                            id: 'Form-select-create-admin-locationID',
                          },
                        },
                        Input: {
                          props: { id: 'Form-select-create-admin-input-locationID', name: 'Form-select-create-admin-input-name-locationID' },
                        },
                      }}
                    />
                  </AppFormControl>
                </Cell>
                )}

              </Grid>
              <br />

              <Block
                overrides={{
                  Block: {
                    style: {
                      '@media screen and (max-width: 768px)': {
                        marginLeft: '0px',
                      },
                      marginLeft: '-20px',
                    },
                  },
                }}
              >
                <Grid
                  gridColumns={12}
                  align={ALIGNMENT.start}
                >
                  {profile && profile.length > 0 && profile[0].value === 'ewaManager'
                  && hasAccess(
                    AccessCheckType.oneOf,
                    [AccessUnit.TreasuryManager,
                      AccessUnit.FBOManager,
                      AccessUnit.TreasuryReader,
                      AccessUnit.FBOReader,
                      AccessUnit.EWAReport],
                  ) && (
                    <Cell
                      span={[12, 6, 3]}
                      align={ALIGNMENT.start}
                    >
                      <Cell
                        span={12}
                        align={ALIGNMENT.start}
                      >
                        <LabelMedium>{t('administrators:permissions.treasuryManagement.header')}</LabelMedium>
                      </Cell>
                      {hasAccess(AccessCheckType.oneOf, [AccessUnit.TreasuryManager]) && (
                      <AppCheckbox
                        name="treasuryReader"
                        label={
                          t('administrators:treasuryReader.label')
                        }
                        disabled={isAdministratorInactive}
                        labelProps={checkboxLabelOverrides}
                        labelPlacement={LABEL_PLACEMENT.left}
                        cellSpan={12}
                        context={ProfileFormContext}
                      />
                      )}
                      {hasAccess(AccessCheckType.oneOf, [AccessUnit.TreasuryManager, AccessUnit.FBOManager]) && (
                      <AppCheckbox
                        name="fboManager"
                        label={
                          t('administrators:fboManager.label')
                        }
                        disabled={isAdministratorInactive}
                        labelProps={checkboxLabelOverrides}
                        labelPlacement={LABEL_PLACEMENT.left}
                        cellSpan={12}
                        context={ProfileFormContext}
                        checkboxProps={{
                          onChange: (option) => {
                            const isChecked = option.currentTarget.checked;
                            setValues({
                              ...values,
                              fboManager: isChecked,
                              treasuryManager: isChecked ? values.treasuryManager : false,
                            });
                          },
                        }}
                      />
                      )}
                      { values.fboManager && hasAccess(AccessCheckType.oneOf, [AccessUnit.TreasuryManager]) && (
                      <AppCheckbox
                        name="treasuryManager"
                        label={t('administrators:treasuryManager.label')}
                        labelProps={checkboxLabelOverrides}
                        labelPlacement={LABEL_PLACEMENT.left}
                        cellSpan={12}
                        context={ProfileFormContext}
                        disabled={isAdministratorInactive}
                      />
                      )}
                      <AppCheckbox
                        name="ewaReport"
                        label={t('administrators:ewaReport.label')}
                        labelProps={checkboxLabelOverrides}
                        labelPlacement={LABEL_PLACEMENT.left}
                        cellSpan={12}
                        context={ProfileFormContext}
                        disabled={isAdministratorInactive}
                      />
                    </Cell>
                  )}

                  <Cell
                    span={[12, 6, 3]}
                  >
                    {profile && profile.length > 0 && (
                      <Cell
                        span={12}
                        align={ALIGNMENT.start}
                      >
                        <LabelMedium style={{ fontWeight: 500 }}>{t('administrators:permissions.tipsManagement.header')}</LabelMedium>
                      </Cell>
                    )}

                    {profile && profile.length > 0 && profile[0].value !== 'ewaClientLocationManager' && (
                      <AppCheckbox
                        name="tipsReader"
                        label={
                          t('administrators:tipsReader.label')
                        }
                        labelProps={checkboxLabelOverrides}
                        labelPlacement={LABEL_PLACEMENT.left}
                        cellSpan={12}
                        context={ProfileFormContext}
                        disabled={isAdministratorInactive}
                      />
                    )}
                    {profile && profile.length > 0 && profile[0].value !== 'ewaManager' && (
                      <AppCheckbox
                        name="tipsClientManager"
                        label={
                          t('administrators:tipsClientManager.label')
                        }
                        labelProps={checkboxLabelOverrides}
                        labelPlacement={LABEL_PLACEMENT.left}
                        cellSpan={12}
                        context={ProfileFormContext}
                        disabled={isAdministratorInactive}
                      />
                    )}
                  </Cell>

                  {profile && profile.length > 0 && profile[0].value === 'ewaClientManager' && (
                  <Cell
                    span={[12, 6, 3]}
                  >
                    <Cell
                      span={12}
                      align={ALIGNMENT.start}
                    >
                      <LabelMedium>{t('administrators:permissions.fuegoDirectDeposit.header')}</LabelMedium>
                    </Cell>
                    <AppCheckbox
                      name="fddClientManager"
                      label={
                        t('administrators:fddClientManager.label')
                      }
                      labelProps={checkboxLabelOverrides}
                      labelPlacement={LABEL_PLACEMENT.left}
                      cellSpan={12}
                      context={ProfileFormContext}
                      disabled={isAdministratorInactive}
                    />
                  </Cell>
                  )}

                  {profile
                  && profile.length > 0
                  && (profile[0].value === 'ewaClientManager' || profile[0].value === 'ewaManager')
                  && (editorRoles.includes(AccessUnit.PaycardClientManager) || editorRoles.includes(AccessUnit.PaycardReader))
                  && (
                  <Cell
                    span={[12, 6, 3]}
                  >
                    <Cell
                      span={12}
                      align={ALIGNMENT.start}
                    >
                      <LabelMedium>{t('administrators:permissions.paycardManagement.header')}</LabelMedium>
                    </Cell>

                    <AppCheckbox
                      name="paycardClientManager"
                      label={
                        t('administrators:paycardClientManager.label')
                      }
                      labelProps={checkboxLabelOverrides}
                      labelPlacement={LABEL_PLACEMENT.left}
                      cellSpan={12}
                      context={ProfileFormContext}
                      disabled={editorRoles.includes(AccessUnit.PaycardReader) || isAdministratorInactive}
                      checkboxProps={{
                        onChange: (event) => {
                          const isChecked = event.currentTarget.checked;
                          setValues({
                            ...values,
                            paycardClientManager: isChecked,
                            paycardReader: isChecked && values.paycardReader ? false : values.paycardReader,
                          });
                        },
                      }}
                    />
                    <AppCheckbox
                      name="paycardReader"
                      label={
                        t('administrators:paycardReader.label')
                      }
                      labelProps={checkboxLabelOverrides}
                      labelPlacement={LABEL_PLACEMENT.left}
                      cellSpan={12}
                      context={ProfileFormContext}
                      disabled={editorRoles.includes(AccessUnit.PaycardReader) || isAdministratorInactive}
                      checkboxProps={{
                        onChange: (event) => {
                          const isChecked = event.currentTarget.checked;
                          setValues({
                            ...values,
                            paycardReader: isChecked,
                            paycardClientManager: isChecked && values.paycardClientManager ? false : values.paycardClientManager,
                          });
                        },
                      }}
                    />
                  </Cell>
                  )}
                </Grid>
              </Block>
              <Block
                alignItems="center"
                justifyContent={['center', 'center', 'flex-end', 'flex-end']}
                display="flex"
                width={['100%', '100%', '95%']}
                height="72px"
              >
                <Block
                  display="inline-flex"
                  marginRight="16px"
                >
                  <Button
                    type="button"
                    kind={KIND.secondary}
                    onClick={handleClickCancel}
                    overrides={{
                      Root: {
                        props: {
                          id: 'admin-form-cancel-button',
                        },
                      },
                    }}
                  >
                    {t('common:cancel')}
                  </Button>
                </Block>

                <Button
                  type="submit"
                  kind={KIND.primary}
                  disabled={isSubmitting || !isFormChanged || !isValid || !hasRules}
                  isLoading={pending}
                  overrides={{
                    Root: {
                      props: {
                        id: 'admin-form-save-button',
                      },
                    },
                  }}
                >
                  {t('common:save')}
                </Button>
              </Block>
            </AccordionSection>

          </div>

        </form>
      </div>
    </ProfileFormContext.Provider>
  );
};

export default memo(EditAdministratorForm);
