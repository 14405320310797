import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { ParagraphSmall, ParagraphXSmall } from 'baseui/typography';
import { TippedEmployeePerBusinessDate } from 'types/TPOTypes';
import { colors, emptyPlaceholder, emptyPlaceholderDash } from 'theme';
import priceFormatter from 'utils/priceFormatter';
import { scalePrice, unScalePrice } from 'utils/priceScale';
import { InputCustomHTMLElement, MoneyDtoType } from 'types/CommonTypes';
import { tippedEmployeesByBusinessDateTableColumnsSelector } from 'store/slices/tpo';
import { useAppSelector } from 'store/hooks';
import { Input, SIZE } from 'baseui/input';
import {
  ChangeEvent, memo, useEffect, useState,
} from 'react';
import { Block } from 'baseui/block';
import { ExcludedEditableColumns } from 'dataMappers/tippedEmployeesDataMapper';
import { bodyCellStyles, outerContainerStyles } from './AppDataTableHelpers';
import messages from '../../validation/messages';

export type AppDataTableBodyRowPropsType = {
  row: TippedEmployeePerBusinessDate;
  updateRowData: (
    rowId: number,
    columnKey: keyof TippedEmployeePerBusinessDate,
    newValue: string | number | MoneyDtoType | undefined,
  ) => void;
};

const isMoneyDto = (value: string | number | MoneyDtoType): value is MoneyDtoType => (value as MoneyDtoType)?.value !== undefined
  && (value as MoneyDtoType)?.scale !== undefined
  && (value as MoneyDtoType)?.currencyCode !== undefined;

const AppDataTableBodyRow = ({
  row,
  updateRowData,
}: AppDataTableBodyRowPropsType) => {
  const [css] = useStyletron();
  const columns = useAppSelector(tippedEmployeesByBusinessDateTableColumnsSelector);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const amountRegex = /^([0-9]{1,6})(?:[,.]\d{1,2})?$/;

  const handleChangeAmount = (e: ChangeEvent<InputCustomHTMLElement>) => {
    const { name, value } = e.target;

    // Optimized error setting logic to reduce redundancy
    const setError = (message: string) => {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: message,
      }));
    };

    // Allow empty input but show an error
    if (value === '') {
      setError(messages.numberRequired);
      updateRowData(row.id, name as keyof TippedEmployeePerBusinessDate, '');
      return;
    }

    // Validate input format
    if (!amountRegex.test(value)) {
      setError(`${messages.mustBeAmount} and ${messages.maxAmount} 999999.99`);
      return;
    }

    const numericValue = Number(value.replace(',', '.')); // Handle both `,` and `.` as decimal separators

    const currentCellValue = row[name as keyof TippedEmployeePerBusinessDate] ?? {
      currencyCode: 'USD',
      scale: 2,
      value: 0,
    };

    const scale = isMoneyDto(currentCellValue) ? currentCellValue.scale : 2;
    const scaledValue = scalePrice(numericValue, scale);

    const moneyObject = isMoneyDto(currentCellValue)
      ? { ...currentCellValue, value: scaledValue }
      : { currencyCode: 'USD', scale, value: scaledValue };

    updateRowData(row.id, name as keyof TippedEmployeePerBusinessDate, moneyObject);

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name];
      return newErrors;
    });
  };

  useEffect(() => {
    setErrors({});
  }, [columns]);

  return (
    <div className={css(outerContainerStyles)} key={row.id}>
      <Grid
        gridColumns={12}
        align={ALIGNMENT.center}
        gridMargins={16}
        gridGutters={0}
        overrides={{
          Grid: {
            style: {
              padding: '0px !important',
              width: '100%',
            },
          },
        }}
      >
        <Cell
          align={ALIGNMENT.center}
          span={1.25}
          overrides={{
            Cell: {
              style: {
                ...bodyCellStyles,
                borderRight: `1px solid ${colors.tableBorder}`,
                borderBottom: `1px solid ${colors.tableBorder}`,
              },
            },
          }}
        >
          <ParagraphSmall
            style={{ whiteSpace: 'normal', margin: 0 }}
          >
            {row.firstName && row.lastName ? `${row.firstName} ${row.lastName}` : emptyPlaceholder}
          </ParagraphSmall>
        </Cell>

        {columns.length > 0 && columns.map((column) => {
          const spanWidth = 9.5 / columns.length;
          const isEditableEnabled = column.isEditingEnabled;
          const cellValue = row[column.key];
          const formattedValue = cellValue !== undefined && isMoneyDto(cellValue)
            ? unScalePrice(cellValue.value, cellValue.scale)
            : cellValue;

          return (
            <Cell
              align={ALIGNMENT.center}
              span={spanWidth}
              overrides={{
                Cell: {
                  style: {
                    ...bodyCellStyles,
                    borderBottom: `1px solid ${colors.tableBorder}`,
                  },
                },
              }}
            >
              {isEditableEnabled ? (
                <Block
                  display="block"
                  position="relative"
                  width="100%"
                >
                  <Input
                    size={SIZE.compact}
                    autoComplete="off"
                    type="number"
                    step={0.01}
                    min={0}
                    max={999999.99}
                    name={column.key}
                    value={formattedValue}
                    id={`${column.key}-amount-input`}
                    onChange={handleChangeAmount}
                    overrides={{
                      Input: {
                        props: {
                          id: `${column.key}-amount-input`,
                          autoComplete: 'off',
                          'data-testid': `${column.key}-amount-input`,
                        },
                      },
                    }}
                  />
                  {errors[column.key]
                   && (
                   <ParagraphXSmall style={{
                     color: colors.primary,
                     margin: 0,
                     position: 'absolute',
                     bottom: '-20px',
                   }}
                   >
                     {errors[column.key]}
                   </ParagraphXSmall>
                   )}
                </Block>
              ) : (
                <ParagraphSmall>
                  {(() => {
                    if (!formattedValue) {
                      if (ExcludedEditableColumns.PAYROLL_ID.includes(column.key)) {
                        return emptyPlaceholderDash;
                      }
                      return priceFormatter().format(unScalePrice(0, 0));
                    }
                    if (cellValue !== undefined && isMoneyDto(cellValue)) {
                      return priceFormatter().format(unScalePrice(cellValue.value, cellValue.scale));
                    }
                    return formattedValue;
                  })()}
                </ParagraphSmall>
              )}
            </Cell>
          );
        })}

        <Cell
          align={ALIGNMENT.center}
          span={1.25}
          overrides={{
            Cell: {
              style: {
                ...bodyCellStyles,
                borderLeft: `1px solid ${colors.tableBorder}`,
                borderBottom: `1px solid ${colors.tableBorder}`,
                backgroundColor: colors.bannerInfo,
              },
            },
          }}
        >
          <ParagraphSmall style={{ whiteSpace: 'normal', margin: 0 }}>
            {row.totalTips && isMoneyDto(row.totalTips)
              ? priceFormatter().format(unScalePrice(row.totalTips.value, row.totalTips.scale))
              : priceFormatter().format(unScalePrice(0, 0))}
          </ParagraphSmall>
        </Cell>
      </Grid>
    </div>
  );
};

export default memo(AppDataTableBodyRow);
