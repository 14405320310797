import { AdministratorType, AllAdministratorsFilterType, EditAdminRolesType } from 'types/AdministratorsTypes';
import request from './request';

export const getAdministratorRequest = (
  token: string,
  id: string,
) => request({
  token,
  url: `administrators/${id}`,
});

export const getAdministratorsRequest = (
  token: string,
  filters: AllAdministratorsFilterType,
) => {
  const queryParams = filters ? new URLSearchParams(Object.entries(filters).map(([key, value]) => [key, value?.toString()])).toString() : '';
  return request({
    token,
    url: `administrators?${queryParams}`,
  });
};

export const saveAdministratorRequest = (
  token: string,
  data: AdministratorType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: 'administrators',
});

export const updateAdministratorRolesRequest = (
  token: string,
  id: string,
  data: EditAdminRolesType,
) => request({
  token,
  method: 'PATCH',
  payload: data,
  url: `administrators/${id}`,
  extraHeaders: { 'Content-Type': 'application/merge-patch+json' },
});

export const updateAdministratorRequest = (
  token: string,
  data: AdministratorType,
) => request({
  token,
  method: 'PATCH',
  payload: data,
  url: `administrators/${data?.id}`,
  extraHeaders: { 'Content-Type': 'application/merge-patch+json' },
});
