import {
  employeeDetailsOnDemandPayRatesInitialValues,
  employeeLinkToEmployerInitialValues,
  employeeZendeskInitialValues,
  generateDirectDepositInitialValues,
} from 'initialValues/EmployeeInitialValues';
import { MoneyDtoType } from 'types/CommonTypes';
import { OfferIDType } from 'types/OfferTypes';
import { PayrollPeriod } from './PayGroupTypes';
import {
  EditEmployeeDetailsRequestType,
  OrganizationIDType,
  SaveOrganizationConfigsRequestType,
} from './OrganizationTypes';
import { WorkerEmployments } from './WorkerTypes';
import { TPOEmployeeStatuses } from './TPOTypes';

export type EmployeeValuesType = {
  id?: number | null;
  organization?: {
    id: OrganizationIDType;
    name: string;
  };
  enrollmentStatus?: { value: string | null }[];
  payrollEmployeeId?: EmployeeIDType;
  linkedAccounts?: string[];
  lowerEarningsThreshold: number;
  locations?: string[];
  offerPercentPerEmployee?: string | number | undefined;
  tpoOfferPercentPerEmployee?: string | number | undefined;
  employeeType?: any;
};

export type PayRatesDataValuesType = {
  id: number;
  organization: {
    id: OrganizationIDType;
    name: string;
    programsAvailable: string[];
  };
  contractType: SubElementContractTypeEnum;
  ewaType: SubElementEWATypeEnum;
  ewaTypeOverridden?: SubElementEWATypeEnum;
  contractedHours: number;
  contractedHoursOverridden?: number;
  workDays: string[];
};

export type PayRatesValuesType = {
  payRates: any;
  payRateOverridden?: EmploymentPayRate;
  payRatesOverridden?: EmploymentPayRate[];
};

export type PayRateValuesType = {
  payRatePeriod: number | string;
  payRateYearly: number | string;
  payRateHourly: number | string;
};

export type EmployeeDetailsProfileValuesType = {
  id?: number | null;
  organization: {
    id: OrganizationIDType;
    name: string;
  };
  enrollmentStatus?: { value: string | null }[];
  payrollEmployeeId?: EmployeeIDType;
  linkedAccounts?: string[];
  locations?: string[];
  enrollmentDate?: string;
};

export type EmployeeDetailsOnDemandPayValuesType = {
  id?: number | null;
  lowerEarningsThreshold: number;
  offerPercentPerEmployee?: string | number | undefined;
  tpoOfferPercentPerEmployee?: string | number | undefined;
  enrollmentDate?: string;
  programs?: string[];
  employeeType?: any;
};

export type CreateEmployeeLocationRequestType = {
  id: number;
  externalLocationId: string;
  timezone: string;
  businessDayStartTime: string;
  payGroupId: number | null;
  name: string;
  payGroupName: string;
};

export interface Employee {
  id: string;
  workerId?: string;
  organization?: {
    id: OrganizationIDType;
    name: string;
  };
  externalUserId?: string;
  externalEmployeeId?: string;
  status?: string;
  statusEffectiveDate?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  payrollId?: string;
  assignments?: EmployeeAssignment[];
}

export type EmployeeAssignment = {
  id: number;
  name: string;
  primaryLocation: boolean;
  location?: {
    id: number;
    name: string;
    offerPercent: number;
    payGroup: {
      id: number;
      name: string;
    };
  };
};

export type CreateEmployeePayGroupRequestType = {
  id: number;
  employeeID: number;
  payFrequencyRule: string;
  defaultForEmployee: boolean;
  name: string;
};

export type EmploymentsVerificationHeaderTypes = {
  name?: string;
  handleVerifyEmployment?: () => void;
};

export type EmployeeLinkToEmployerInitialValuesType =
  typeof employeeLinkToEmployerInitialValues;
export type GenerateDirectDepositInitialValuesType =
  typeof generateDirectDepositInitialValues;

export type CreateEmployeeRequestType = {
  name: string;
  type: string;
  id?: number;
  externalId?: string;
  tnaPollingCron?: string;
  employerLoginUrl?: string;
};

export type EmploymentType = {
  id: string;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  selected?: boolean;
  assignments: EmployeeAssignment[] | undefined;
};

export type EmploymentsListPropsType = {
  employments: (Employee & EmploymentType)[];
  toggle: Function;
  onAssignmentsChange: Function;
};

export type WorkerEmploymentsListPropsType = {
  employments: WorkerEmployments[];
  toggle?: Function;
  onSelect?: Function;
};

export type SearchOrganizationFilterType = {
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  filter?: string;
  workerId?: string;
  payGroupId?: string;
  locationId?: string;
  status?: string;
  pageNumber?: string;
  namePattern?: string;
  payrollNumber?: string;
  pageSize?: string;
};

export type SearchOrganizationEmploymentsParamsType = {
  organizationID: OrganizationIDType;
  filter: SearchOrganizationFilterType;
};

export enum OperationMode {
  WORKER_UPDATE = 'WORKER_UPDATE',
  ASSIGNMENT_UPDATE = 'ASSIGNMENT_UPDATE',
}

export enum EmploymentStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  TERMINATED = 'TERMINATED',
  MERGED = 'MERGED',
  BAD_DATA = 'BAD_DATA',
}

export type EditEmployeeDetailsParamsType = {
  organization: {
    id: OrganizationIDType;
    name: string;
  };
  employeeID: EmployeeIDType;
  employeeDetailsData: EditEmployeeDetailsRequestType;
  employeeConfigsData: SaveOrganizationConfigsRequestType;
};

export type EditEmployeeDetailsProfileParamsType = {
  organization: {
    id: OrganizationIDType;
    name: string;
  };
  employeeID: EmployeeIDType;
  employeeDetailsData: EditEmployeeDetailsRequestType;
};

export type SelectedEmploymentType = {
  id: number | string | undefined;
  assignments: EmployeeAssignment[] | undefined;
};

export type AssignEmployeesToWorkerValuesType = {
  workerId: string | undefined;
  employments: SelectedEmploymentType[];
  operation: string;
};

export type AssignEmployeesToWorkerRequestType =
  AssignEmployeesToWorkerValuesType;

export interface EmploymentPayroll {
  id: string;
  workerId?: string;
  organization?: {
    id: OrganizationIDType;
    name: string | undefined | null;
  };
  externalUserId?: string;
  externalEmployeeId?: string;
  status?: string;
  statusEffectiveDate?: Date;
  firstName: string;
  lastName?: string;
  phone?: string;
  email?: string;
  payrollId?: number | null;
  organizationName?: string;
  drawsTotalAmount?: any;
}

export type EmployeeIDType = string | null | undefined;
export type EmploymentIDType = string | null | undefined;
export type LedgerIDType = string | null | undefined;

export type EmployeeResponseType = {
  id: number;
  workerId: string;
  organization: {
    id: OrganizationIDType;
    name: string;
    programsAvailable: string[];
  };
  externalUserId: string;
  externalEmployeeId: string;
  status: EmploymentStatus;
  statusEffectiveDate: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  payrollId: string;
  organizationName: string;
  assignments: EmployeeAssignment[];
  lowerEarningsThreshold: MoneyDtoType;
  enrollmentDate: string;
  programs: string[];
  contractType?: string;
  ewaType?: string;
  ewaTypeOverridden?: string;
  contractedHours?: number;
  contractedHoursOverridden?: number;
  workDays?: string[];
  payRates?: any[];
  payRateOverridden?: any;
  payRatesOverridden?: any[];
};

export type EmployeeProfileResponseType = {
  id: number;
  workerId: string;
  organization: {
    id: OrganizationIDType;
    name: string;
    programsAvailable: string[];
  };
  externalUserId: string;
  externalEmployeeId: string;
  status: EmploymentStatus;
  statusEffectiveDate: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  payrollId: string;
  organizationName: string;
  assignments: EmployeeAssignment[];
  lowerEarningsThreshold: MoneyDtoType;
  enrollmentDate: string;
  programs: string[];
};

export enum SubElementContractTypeEnum {
  SALARIED = 'SALARIED',
  HOURLY_FTE = 'HOURLY_FTE',
  HOURLY_PTE = 'HOURLY_PTE',
  UNKNOWN = 'UNKNOWN',
}

export enum SubElementEWATypeEnum {
  HOURLY_PAID = 'HOURLY_PAID',
  SALARIED_NOT_CLOCKING = 'SALARIED_NOT_CLOCKING',
  REGULARLY_PAID_HOURLY = 'REGULARLY_PAID_HOURLY',
}

export interface EmploymentPayRate {
  rateType: SubElementPayRateTypeEnum;
  effectiveFrom: string;
  amount: MoneyDtoType;
  payGroup: EmploymentPayRatePayGroupType;
  calculated: boolean;
}

export type PayRatesResponseType = {
  id: number;
  workerId: string;
  status: EmploymentStatus;
  parentEmploymentId: number;
  organization: {
    id: OrganizationIDType;
    name: string;
    programsAvailable: string[];
  };
  statusEffectiveDate: string;
  terminatedEffectiveDate: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  payrollId: string;
  enrollmentDate: string;
  assignments: EmployeeAssignment[];
  externalIntegrationId: string;
  payGroup: {
    id: number;
    name: string;
    externalIntegrationCode: string;
  };
  directDepositEnabled: boolean;
  adHocCreated: boolean;
  lowerEarningsThreshold: MoneyDtoType;
  programs: string[];
  contractType: SubElementContractTypeEnum;
  ewaType: SubElementEWATypeEnum;
  ewaTypeOverridden?: SubElementEWATypeEnum;
  contractedHours: number;
  contractedHoursOverridden?: number;
  workDays: string[];
  payRates: any;
  payRateOverridden: any;
  payRatesOverridden: any;
  fullName: string;
};

export type OrganizationFormLocationModalPropsType = {
  drawID: DrawIDType;
  zendeskTicket: ZendeskTicketResponseType;
};

export type AssignEmployeesParamsType = {
  organizationID: OrganizationIDType;
  data: AssignEmployeesToWorkerValuesType;
};

export type ZendeskTicketValuesType = {
  ticketNumber: number;
  ticketStatuses?: { value: string | null }[];
};

export type ZendeskTicketRequestType = {
  id: number;
  status: string;
};

export type ZendeskTicketParamsType = {
  drawID: DrawIDType;
  data: ZendeskTicketResponseType;
};

export type RecipientDtoType = {
  accountId: string;
  accountNickname: string;
  accountLastFour: string;
};
export type DrawRequestFilterType = {
  pageNumber: string;
  payrollPeriodId?: string;
  statuses?: string;
  type?: string;
  includeOfferDetails?: string;
};

export type EmploymentPayRatePayGroupType = {
  id: number;
  name: string;
  externalIntegrationCode: number;
  payFrequencyRule: string;
};

export enum SubElementStatusType {
  CREATED = 'CREATED',
  ACCEPTED = 'ACCEPTED',
  COMPLETED = 'COMPLETED',
  DISPUTED = 'DISPUTED',
  VOIDED = 'VOIDED',
  DECLINED = 'DECLINED',
  REFUNDED = 'REFUNDED',
  SETTLED = 'SETTLED',
  PARTIALLY_SETTLED = 'PARTIALLY_SETTLED',
  PROCESSED = 'PROCESSED',
  NOT_PAID = 'NOT_PAID',
}

export enum SubElementPaymentFailedStatusType {
  FAILED_TRANSACTION = 'FAILED_TRANSACTION',
  REPLAY_CHECK_FAILED = 'REPLAY_CHECK_FAILED',
  INACTIVE_DESTINATION_ACCOUNT = 'INACTIVE_DESTINATION_ACCOUNT',
  WORKER_IS_NOT_ACCOUNT_OWNER = 'WORKER_IS_NOT_ACCOUNT_OWNER',
  SIGNATURE_CHECK_FAILED = 'SIGNATURE_CHECK_FAILED',
  CARD_DECLINED = 'CARD_DECLINED',
  INVALID_NAME_ON_CARD = 'INVALID_NAME_ON_CARD',
  INVALID_POSTAL_CODE = 'INVALID_POSTAL_CODE',
  DESTINATION_ACCOUNT_NOT_FOUND = 'DESTINATION_ACCOUNT_NOT_FOUND',
}

export enum SubElementTypeEnum {
  DRAW_REQUEST = 'DRAW_REQUEST',
  REPAYMENT_FROM_PAYROLL = 'REPAYMENT_FROM_PAYROLL',
  ARREARS_CARRIED_FORWARD = 'ARREARS_CARRIED_FORWARD',
  ARREARS_BROUGHT_FORWARD = 'ARREARS_BROUGHT_FORWARD',
  ADMIN_REFUND = 'ADMIN_REFUND',
  BAD_DEBT = 'BAD_DEBT',
  TCO_REQUEST = 'TCO_REQUEST',
  TCO_RESEND = 'TCO_RESEND',
  DRAW_RESEND = 'DRAW_RESEND',
  // DRAW FEE
  DRAW_FEE_WORKER = 'DRAW_FEE_WORKER',
  DRAW_FEE_EMPLOYER = 'DRAW_FEE_EMPLOYER',
  // TRANSFER_FEE
  SAME_DAY_ACH_TRANSFER_FEE = 'SAME_DAY_ACH_TRANSFER_FEE',
  PUSH_TO_CARD_TRANSFER_FEE = 'PUSH_TO_CARD_TRANSFER_FEE',
  TCO_SAME_DAY_ACH_TRANSFER_FEE = 'TCO_SAME_DAY_ACH_TRANSFER_FEE',
}

export enum SubElementPaymentMethodEnum {
  FUEGO_CARD = 'FUEGO_CARD',
  ACH_TRANSFER = 'ACH_TRANSFER',
  ACH_TRANSFER_SAME_DAY = 'ACH_TRANSFER_SAME_DAY',
  PUSH_TO_EXTERNAL_CARD = 'PUSH_TO_EXTERNAL_CARD',
  FUEGO_PAYCARD = 'FUEGO_PAYCARD',
}

export enum SubElementPayRateTypeEnum {
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY',
  SEMI_MONTHLY = 'SEMI_MONTHLY',
  BI_WEEKLY = 'BI_WEEKLY',
  WEEKLY = 'WEEKLY',
  HOURLY = 'HOURLY',
  PERIOD = 'PERIOD',
}

export enum EmployeeDrawsAvailableOperations {
  VOID = 'VOID',
  RETRY = 'RETRY',
  DECLINE = 'DECLINE',
}

export type SubElementType = {
  id: number;
  drawId: number;
  type: SubElementTypeEnum;
  recipient: RecipientDtoType;
  amount: MoneyDtoType;
  transactionId: string;
  batchId: number;
  repaymentId: number;
  originSubElementId: number;
  status: SubElementStatusType;
  statusDetails?: string;
};

export type EmployeeOfferType = {
  id: number;
  earningsToDate: MoneyDtoType;
  ewaAvailable: MoneyDtoType;
  tcoStatus: TPOEmployeeStatuses;
};

export type ZendeskTicketResponseType = {
  id: number;
  status: string;
};
export type ZendeskTicketModalPropsType = {
  drawID?: number;
  zendeskTicket?: ZendeskTicketResponseType;
};

export type DrawIDType = number | undefined;

export type EmployeeDetailsDrawDetailsModalPropsType = {
  drawID: DrawIDType;
};
export interface Draw {
  id: DrawIDType;
  offerId?: OfferIDType;
  workerId?: string;
  employmentId?: EmploymentIDType;
  payrollPeriod: PayrollPeriod;
  drawRequestedDate: Date;
  paymentMethod: SubElementPaymentMethodEnum;
  subElements: SubElementType[];
  zendeskTicket?: ZendeskTicketResponseType;
  type?: string;
  offer?: EmployeeOfferType;
  payrollPeriodId?: string;
  availableOperations?: EmployeeDrawsAvailableOperations[];
  recipientAccountId?: string;
}

export interface DefaultAccount {
  accountHolderId: string;
  accountId: string;
  accountLastFour: string;
  accountNickname: string;
  accountType: string;
  actor: string;
  timestamp: string;
}

export interface CreateACFParamTypes {
  data: { amount: MoneyDtoType };
  employmentId: EmploymentIDType;
  drawId: DrawIDType;
  idempotencyKey?: string;
}

export type EmployeeZendeskValuesType = typeof employeeZendeskInitialValues;

export type DirectDepositEnrollmentType = {
  accountNumber: string;
  routingNumber: string;
  payrollNumber: string;
  enrollDate: string;
  status: string;
};

export interface EditEmploymentPayRate {
  rateType: SubElementPayRateTypeEnum;
  amount: MoneyDtoType;
}

export type EditEmploymentPayRatesRequestType = {
  payRate: EditEmploymentPayRate;
  contractedHours: number;
};

export type EditEmploymentPayRatesParamsType = {
  organizationID: OrganizationIDType;
  employmentID: EmploymentIDType;
  data: EditEmploymentPayRatesRequestType;
};

export type EmployeeDetailsOnDemandPayRatesValuesType =
  typeof employeeDetailsOnDemandPayRatesInitialValues;

export type PayRatesPartialResponseType = {
  payRates: EmploymentPayRate[];
  contractedHours: number;
};

export type EmployeePayRatesFormValuesType = {
  PERIOD: number;
  YEARLY: number;
  HOURLY: number;
  contractedHours: number;
};

export type EditEmployeePayRatesValuesType = {
  name: SubElementPayRateTypeEnum;
  values: EmployeePayRatesFormValuesType;
};

export type EmployeeTypePayRatesType = [
  {
    label?: string;
    value: SubElementEWATypeEnum;
  }
];

export type DeclineDrawRequestParamsType = {
  employmentID: EmploymentIDType;
  ledgerID: LedgerIDType;
};

export type VoidDrawRequestParamsType = {
  employmentID: EmploymentIDType;
  ledgerID: LedgerIDType;
};

export type UnsetDefaultAccountParamsType = {
  employmentID: EmploymentIDType;
};

export type FetchEmployeeDefaultAccountParamsType = {
  employmentID: EmploymentIDType;
};
