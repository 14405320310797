import { useStyletron } from 'baseui';
import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid';
import { useTranslation } from 'react-i18next';
import { LabelMedium } from 'baseui/typography';
import { Block } from 'baseui/block';
import { Button, KIND, SIZE } from 'baseui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faX, faPen } from '@fortawesome/free-solid-svg-icons';
import { ColumnWithEditability, TippedEmployeePerBusinessDate } from 'types/TPOTypes';
import { colors } from 'theme';
import {
  employeesTipTotalsSelector,
  setTippedEmployeesByBusinessDateTableColumns,
  tippedEmployeesByBusinessDateTableColumnsSelector,
} from 'store/slices/tpo';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useState } from 'react';
import {
  headerCellStyles,
  tableHeaderStyles,
} from './AppDataTableHelpers';
import Access, { AccessUnit } from '../Access/Access';

export interface AppDataTableHeaderPropsType {
  updateTippedEmployeesRowValues: (shouldUpdate: boolean) => void;
}
const AppDataTableHeader = ({ updateTippedEmployeesRowValues }: AppDataTableHeaderPropsType) => {
  const [css] = useStyletron();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['tipsManagement']);
  const columns = useAppSelector(tippedEmployeesByBusinessDateTableColumnsSelector);
  const totalTips = useAppSelector(employeesTipTotalsSelector);
  const [isEditingEnabledForAColumn, setIsEditingEnabledForAColumn] = useState<boolean>(false);

  const setEditState = (
    columnName: keyof TippedEmployeePerBusinessDate,
    nextEditingState: boolean,
  ) => {
    const updatedColumns = columns.map((column) => ({
      ...column,
      isEditingEnabled: column.key === columnName ? nextEditingState : false,
    }));
    setIsEditingEnabledForAColumn(nextEditingState);
    dispatch(setTippedEmployeesByBusinessDateTableColumns(updatedColumns));
  };

  const saveEditedColumn = (columnName: keyof TippedEmployeePerBusinessDate) => {
    setEditState(columnName, false);
    updateTippedEmployeesRowValues(true);
  };

  const cancelEditedColumn = (columnName: keyof TippedEmployeePerBusinessDate) => {
    setEditState(columnName, false);
    updateTippedEmployeesRowValues(false);
  };

  return (
    <div className={css(tableHeaderStyles)}>
      <Grid
        gridColumns={12}
        align={ALIGNMENT.start}
        gridMargins={16}
        gridGutters={0}
        overrides={{
          Grid: {
            style: {
              padding: '0px !important',
              width: '100%',
            },
          },
        }}
      >
        <Cell
          align={ALIGNMENT.center}
          span={1.25}
          overrides={{
            Cell: {
              style: {
                ...headerCellStyles,
                borderRight: `1px solid ${colors.tableBorder}`,
              },
            },
          }}
        >
          <LabelMedium style={{ fontWeight: 500 }}>
            {t('tipsManagement:tippedEmployeesPerBusinessDateTable.header.employeeName')}
          </LabelMedium>
        </Cell>

        {columns.length > 0 && columns.map((column: (ColumnWithEditability)) => {
          const spanWidth = 9.5 / columns.length;

          return (
            <Cell
              align={ALIGNMENT.center}
              span={spanWidth}
              key={column.key}
              overrides={{
                Cell: {
                  style: {
                    ...headerCellStyles,
                  },
                },
              }}
            >
              <Block
                display="flex"
                justifyContent="space-between"
                alignItems={ALIGNMENT.center}
                width="100%"
              >
                <LabelMedium style={{ fontWeight: 500 }}>
                  { column.key === 'thirdPartyTips'
                    ? totalTips.custom3rdPartyTipsLabel || t('tipsManagement:tippedEmployeesPerBusinessDateTable.header.externalData')
                    : t(`tipsManagement:tippedEmployeesPerBusinessDateTable.header.${column.key}`)}
                </LabelMedium>
                <Access oneOf={[AccessUnit.TipsEditor, AccessUnit.EWAClientManager]}>
                  {column.isEditable && (
                    column.isEditingEnabled
                      ? (
                        <Block
                          display="flex"
                          justifyContent={ALIGNMENT.start}
                          alignItems={ALIGNMENT.center}
                          gridGap="8px"
                        >
                          <Button
                            size={SIZE.compact}
                            kind={KIND.secondary}
                            type="button"
                            onClick={() => cancelEditedColumn(column.key)}
                            overrides={{
                              Root: {
                                style: {
                                  width: '28px',
                                  height: '28px',
                                  padding: 0,
                                },
                                props: {
                                  id: 'tip-management-day-view-cancel-edit-button',
                                },
                              },
                            }}
                          >
                            <FontAwesomeIcon icon={faX} style={{ width: '14px', height: '14px' }} />
                          </Button>
                          <Button
                            size={SIZE.compact}
                            kind={KIND.primary}
                            type="button"
                            onClick={() => saveEditedColumn(column.key)}
                            overrides={{
                              Root: {
                                style: {
                                  width: '28px',
                                  height: '28px',
                                  padding: 0,
                                },
                                props: {
                                  id: 'tip-management-day-view-accept-edit-button',
                                },
                              },
                            }}
                          >
                            <FontAwesomeIcon icon={faCheck} style={{ width: '14px', height: '14px' }} />
                          </Button>
                        </Block>
                      )
                      : (
                        <Button
                          size={SIZE.compact}
                          kind={KIND.tertiary}
                          type="button"
                          disabled={isEditingEnabledForAColumn}
                          onClick={() => setEditState(column.key, true)}
                          overrides={{
                            Root: {
                              style: {
                                width: '28px',
                                height: '28px',
                                padding: 0,
                              },
                              props: {
                                id: 'tip-management-day-view-start-edit-button',
                              },
                            },
                          }}
                        >
                          <FontAwesomeIcon icon={faPen} style={{ width: '14px', height: '14px' }} />
                        </Button>
                      )
                  )}
                </Access>
              </Block>
            </Cell>
          );
        })}
        <Cell
          align={ALIGNMENT.center}
          span={1.25}
          overrides={{
            Cell: {
              style: {
                ...headerCellStyles,
                borderLeft: `1px solid ${colors.tableBorder}`,
              },
            },
          }}
        >
          <LabelMedium style={{ fontWeight: 500 }}>
            {t('tipsManagement:tippedEmployeesPerBusinessDateTable.header.totalTips')}
          </LabelMedium>
        </Cell>
      </Grid>
    </div>
  );
};

export default AppDataTableHeader;
