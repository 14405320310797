import { Block } from 'baseui/block';
import { Button, KIND } from 'baseui/button';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import { Card } from 'baseui/card';
import { Search } from 'baseui/icon';
import { Input, SIZE } from 'baseui/input';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import Loader from 'components/Loader';
import {
  ChangeEvent,
  memo,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  administratorsListSelector,
  administratorsNextTokenSelector,
  administratorsPendingListSelector,
  administratorsSearchFilterCriteriaSelector,
  administratorsSearchFilterSelector,
  fetchAllAdministrators,
  resetAdministratorsList,
  resetAdministrator,
  searchAdministrators,
  setSearchPattern,
  setFilterCriteria,
  administratorsOrganizationsFilterSelector,
  setOrganizationsFilter,
  administratorsLocationsFilterSelector,
  setLocationsFilter,
  administratorsStatusFilterSelector,
  setStatusFilter,
  administratorSelector,
} from 'store/slices/administrators';
import {
  useStyletron,
} from 'styletron-react';
import {
  AdministratorType,
  AllAdministratorsFilterType,
} from 'types/AdministratorsTypes';
import {
  ModalNames,
  modalsSelector,
} from 'store/slices/modals';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import {
  prevPageSelector,
  setPrevPage,
} from 'store/slices/application';
import { InputCustomHTMLElement } from 'types/CommonTypes';
import { OnChangeParams, Select, Value } from 'baseui/select';
import CellFormControl from 'components/CellFormControl';
import { Organization, organizationsSelector } from 'store/slices/organizations';
import { fetchLocations, locationsSelector } from 'store/slices/locations';
import {
  administratorsListBlockOverrides,
  administratorsStatuses,
  containerStylesAdministrator,
} from './AdministratorsHelpers';
import EditAdministratorModal from './EditAdministratorModal';
import AdministratorListItem from './AdministratorListItem';

const Administrators = () => {
  const list = useAppSelector(administratorsListSelector);
  const pendingList = useAppSelector(administratorsPendingListSelector);
  const nextToken = useAppSelector(administratorsNextTokenSelector);
  const search = useAppSelector(administratorsSearchFilterSelector);
  const criteria = useAppSelector(administratorsSearchFilterCriteriaSelector);
  const organizationsFilter = useAppSelector(administratorsOrganizationsFilterSelector);
  const locationsFilter = useAppSelector(administratorsLocationsFilterSelector);
  const prevPage = useAppSelector(prevPageSelector);
  const organizations = useAppSelector(organizationsSelector);
  const locations = useAppSelector(locationsSelector);
  const status = useAppSelector(administratorsStatusFilterSelector);
  const { t } = useTranslation(['common', 'administrators']);
  const [css] = useStyletron();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const modals = useAppSelector(modalsSelector);
  const selectedAdmin = useAppSelector(administratorSelector);

  const createAdmin = () => {
    history.push('/administrators/create');
  };

  const searchByOptions = [
    {
      name: t('common:name'),
      id: 'namePattern',
    },
    {
      name: t('common:email'),
      id: 'email',
    },
  ];

  const organizationOptions = organizations?.map((org: Organization) => ({ label: org.name, value: `${org.id}` }))
    ?.sort((a, b) => a.label.localeCompare(b.label));

  const locationsOptions = locations?.map((loc) => ({ label: loc.name, value: loc.id }))
    ?.sort((a, b) => a.label.localeCompare(b.label));

  const handleCriteriaChange = ({
    value,
  }: OnChangeParams) => {
    dispatch(setFilterCriteria(value));
  };

  const handleChangeSearch = (e: ChangeEvent<InputCustomHTMLElement>) => {
    dispatch(setSearchPattern(e.target.value));
  };

  const handleAdministratorStatusFilter = ({
    value,
  }: OnChangeParams) => {
    dispatch(setStatusFilter(value));
  };

  const handleOrganizationsChangeFilter = ({
    value,
  }: OnChangeParams) => {
    dispatch(setOrganizationsFilter(value));
    dispatch(setLocationsFilter([]));
  };

  const handleLocationsChangeFilter = ({
    value,
  }: OnChangeParams) => {
    dispatch(setLocationsFilter(value));
  };

  const handleSearch = (isLoadMore?: boolean) => {
    const searchCriteria = criteria[0].id;
    const searchString = search.trim();

    const filter: AllAdministratorsFilterType = {
      active: status[0].value === 'ACTIVE',
      ...(searchCriteria === 'namePattern' && search !== '' && { namePattern: searchString }),
      ...(searchCriteria === 'email' && search !== '' && { email: searchString }),
      ...(organizationsFilter.length > 0 && { organizationIdsFilter: organizationsFilter.map((org) => org.value as string) }),
      ...(locationsFilter.length > 0 && { locationIdsFilter: locationsFilter.map((loc) => loc.value as string) }),
      ...(isLoadMore && { nextToken }),
    };

    dispatch(searchAdministrators(filter));
  };

  useEffect(() => {
    if (!prevPage.startsWith('/administrators') && selectedAdmin?.id) {
      handleSearch();
      dispatch(resetAdministrator());
    } else if (!prevPage.startsWith('/administrators') && !selectedAdmin?.id) {
      dispatch(resetAdministratorsList());
      dispatch(fetchAllAdministrators({
        active: true,
      }));
    } else {
      handleSearch();
    }
    dispatch(setPrevPage(''));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (organizationsFilter && organizationsFilter.length > 0) {
      dispatch(fetchLocations({
        organizationID: organizationsFilter[0].value || '',
      }));
    }
  }, [organizationsFilter]);

  return (
    <div className={css(containerStylesAdministrator)}>

      <CommonHeader title={t('administrators:administrators')}>
        <Block
          alignItems="center"
          alignContent="end"
          justifyItems="end"
          display="inline-flex"
          justifyContent="flex-end"
          width="50%"
          minWidth="160px"
          height="72px"
        >
          <Button
            kind={KIND.primary}
            size={SIZE.compact}
            overrides={{
              Root: {
                props: {
                  id: 'Administrators-create-admin',
                },
              },
            }}
            onClick={createAdmin}
          >
            {t('administrators:createNewAdmin')}
          </Button>
        </Block>
      </CommonHeader>

      <Block
        marginTop="24px"
        marginBottom="21px"
      >
        <Grid
          gridGaps={[0, 10]}
          align={ALIGNMENT.center}
        >
          <Cell span={12}>
            <b>
              {t('common:searchBy')}
            </b>
          </Cell>

          <Cell
            span={[12, 2, 2]}
            align={ALIGNMENT.center}
          >
            <Block
              marginTop="20px"
              display="flex"
              width="auto"
            >
              <Select
                size={SIZE.compact}
                placeholder={t('common:select')}
                type="select"
                clearable={false}
                options={searchByOptions}
                labelKey="name"
                valueKey="id"
                onChange={handleCriteriaChange}
                value={criteria as Value}
                overrides={{
                  ControlContainer: {
                    props: {
                      'data-testid': 'Administrators-search-type-select',
                      id: 'Administrators-search-type-select',
                    },
                  },
                }}
              />
            </Block>
          </Cell>

          { criteria[0].id === 'namePattern' && (
            <Cell
              span={[12, 3, 3]}
              align={ALIGNMENT.center}
            >
              <Block
                marginTop="20px"
                display="flex"
                width="auto"
              >
                <Input
                  size={SIZE.compact}
                  clearable
                  clearOnEscape
                  startEnhancer={<Search />}
                  type="text"
                  name="searchByNamePattern"
                  onChange={handleChangeSearch}
                  value={search || ''}
                  placeholder={t('administrators:searchByName')}
                  overrides={{
                    Input: {
                      props: {
                        id: 'Administrators-search',
                        autoComplete: 'off',
                      },
                    },
                  }}
                />
              </Block>
            </Cell>
          )}

          { criteria[0].id === 'email' && (
            <Cell
              span={[12, 3, 3]}
              align={ALIGNMENT.center}
            >
              <Block
                marginTop="20px"
                display="flex"
                width="auto"
              >
                <Input
                  size={SIZE.compact}
                  clearable
                  clearOnEscape
                  startEnhancer={<Search />}
                  type="text"
                  name="searchByEmail"
                  onChange={handleChangeSearch}
                  value={search || ''}
                  placeholder={t('administrators:searchByEmail')}
                  overrides={{
                    Input: {
                      props: {
                        id: 'Administrators-email-search',
                        autoComplete: 'off',
                      },
                    },
                  }}
                />
              </Block>
            </Cell>
          )}
        </Grid>

        <Grid
          align={ALIGNMENT.center}
          overrides={{
            Grid: {
              style: {
                marginTop: '24px',
              },
            },
          }}
        >
          <Cell span={12}>
            <b>
              {t('common:filterBy')}
            </b>
          </Cell>
          <CellFormControl
            cellSpan={[12, 2, 2]}
            label={`${t('employees:employmentStatus')}`}
            cellAlign={ALIGNMENT.center}
            formControlProps={{
              overrides: {
                ControlContainer: {
                  style: {
                    marginBottom: '0',
                  },
                },
              },
            }}
          >
            <Select
              size={SIZE.compact}
              placeholder={t('common:all')}
              searchable={false}
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'administrators-status-select',
                    id: 'administrators-status-select',
                  },
                },
              }}
              type="select"
              options={administratorsStatuses.map(({ value, label }) => ({
                label,
                value,
              }))}
              clearable={false}
              labelKey="label"
              valueKey="value"
              onChange={handleAdministratorStatusFilter}
              value={status}
              maxDropdownHeight="300px"
            />
          </CellFormControl>

          <CellFormControl
            cellSpan={[12, 3, 3]}
            label={`${t('administrators:organization')}`}
            cellAlign={ALIGNMENT.center}
            formControlProps={{
              overrides: {
                ControlContainer: {
                  style: {
                    marginBottom: '0',
                  },
                },
              },
            }}
          >
            <Select
              size={SIZE.compact}
              clearable
              placeholder={t('common:all')}
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'administrators-organizations-select',
                    id: 'administrators-organizations-select',
                  },
                },
              }}
              type="select"
              options={organizationOptions}
              labelKey="label"
              valueKey="value"
              onChange={handleOrganizationsChangeFilter}
              value={organizationsFilter}
              maxDropdownHeight="300px"
            />
          </CellFormControl>

          <CellFormControl
            cellSpan={[12, 3, 5]}
            label={`${t('administrators:location')}`}
            cellAlign={ALIGNMENT.center}
            formControlProps={{
              overrides: {
                ControlContainer: {
                  style: {
                    marginBottom: '0',
                  },
                },
              },
            }}
          >
            <Select
              size={SIZE.compact}
              clearable
              placeholder={t('common:all')}
              overrides={{
                ControlContainer: {
                  props: {
                    'data-testid': 'administrators-location-select',
                    id: 'administrators-location-select',
                  },
                },
              }}
              type="select"
              options={locationsOptions}
              labelKey="label"
              valueKey="value"
              onChange={handleLocationsChangeFilter}
              value={locationsFilter}
              maxDropdownHeight="300px"
              disabled={organizationsFilter.length === 0}
              multi
            />
          </CellFormControl>

          <CellFormControl
            cellSpan={[12, 2, 2]}
            label=""
            cellAlign={ALIGNMENT.center}
            formControlProps={{
              overrides: {
                ControlContainer: {
                  style: {
                    marginBottom: '0',
                  },
                },
              },
            }}
          >
            <Block margin="22px 0 0 0" display="flex">
              <Button
                kind={KIND.primary}
                size={SIZE.compact}
                overrides={{
                  Root: {
                    props: {
                      id: 'Administrators-search-admin',
                    },
                  },
                }}
                disabled={pendingList}
                onClick={() => handleSearch(false)}
              >
                {t('administrators:searchButton')}
              </Button>
            </Block>
          </CellFormControl>
        </Grid>
      </Block>
      <Block position="relative" overrides={administratorsListBlockOverrides}>
        <Loader
          containerStyles={{
            position: 'absolute',
            top: '0px',
            height: '600px',
          }}
          active={pendingList}
        />
        <Grid
          align={ALIGNMENT.center}
        >
          <Cell
            span={12}
          >
            {list?.length ? list.map((admin: AdministratorType) => (
              <AdministratorListItem key={admin.id} admin={admin} />
            )) : null}
            {nextToken
              ? (
                <Block margin="10px 0 20px" display="flex">
                  <Button
                    overrides={{
                      Root: {
                        style: {
                          marginTop: 'auto',
                          marginBottom: 'auto',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        },
                        props: {
                          id: 'administrators-more-workers-button',
                        },
                      },
                      LoadingSpinner: {
                        style: {
                          borderRightColor: 'white',
                          borderTopColor: 'white',
                          borderLeftColor: 'white',
                        },
                      },
                    }}
                    isLoading={pendingList}
                    disabled={!nextToken}
                    onClick={() => handleSearch(true)}
                  >
                    {t('administrators:loadMore')}
                  </Button>
                </Block>
              ) : null}
            {list?.length === 0 && (
              <Block
                marginTop="24px"
                marginBottom="24px"
              >
                <Card>
                  <p>{t('administrators:notFound')}</p>
                </Card>
              </Block>
            )}
          </Cell>
        </Grid>
      </Block>
      {checkIsModalOpen(modals, ModalNames.EDIT_ADMIN_MODAL) && <EditAdministratorModal />}
    </div>
  );
};

export default memo(Administrators);
