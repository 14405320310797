import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchEmployeesTPOOfferByIdRequest,
  fetchEmployeesTPOOffersPerLocationRequest,
} from 'api/offersAPI';
import {
  fetchEmployeesTPOByPayPeriodRequest,
  fetchEmployeesTPOByBusinessDateRequest,
  fetchEmployeeTPOPaymentsRequest,
  generateTPOPayrollFileRequest,
  generateTPODailyFileRequest,
  initiateTPOPaymentsRequest,
  saveTpoPayoutConfigsRequest,
  fetchEmployeeTipsTotalsRequest,
} from 'api/tpoAPI';
import { importTipsFileRequest } from 'tnaApi/tpoFilesAPI';
import { TPOOffer } from 'types/OfferTypes';
import {
  FetchTippedEmployeeBalanceOfferParamsType,
  FetchTippedEmployeesByPayPeriodParamsType,
  FetchTippedEmployeeTPOPaymentsPaymentsParamsType,
  InitiateTPOPaymentsParamsType,
  TPOPayment,
  TipBalance,
  TippedEmployeePerPayrollPeriod,
  SearchPayrollPeriodDropDown,
  FetchTPOPayrollFileParamsType,
  FetchTPODailyFileParamsType,
  ImportTipsFilePropsType,
  FetchTippedEmployeesByBusinessDateParamsType,
  TippedEmployeePerBusinessDate,
  SaveTPOPayoutConfigsParamsType,
  FetchEmployeeTipsTotalParamsType,
  EmployeeTipsTotal,
  ColumnWithEditability,
} from 'types/TPOTypes';
import { RootState } from '..';

export interface TPOPayrollData {
  id?: string
  name?: string
  csv?: string
}

export const initialState = {
  tippedEmployeesSelectedLocation: [] as any,
  tippedEmployee: {} as TippedEmployeePerPayrollPeriod,
  tippedEmployeesByPayPeriod: {
    list: [] as TippedEmployeePerPayrollPeriod[],
    pendingList: false,
    pageNumber: 1 as number,
    pageSize: 50 as number,
    totalSize: 0 as number,
    numPages: 1 as number,
  },
  tippedEmployeesByBusinessDate: {
    list: [] as TippedEmployeePerBusinessDate[],
    pendingList: false,
    pageNumber: 1 as number,
    pageSize: 50 as number,
    totalSize: 0 as number,
    numPages: 1 as number,
    columns: [] as ColumnWithEditability[],
  },
  selectedTippedEmployees: [] as TippedEmployeePerPayrollPeriod[],
  tippedEmployeeBalance: {
    offer: undefined as TPOOffer | undefined,
    generatedOffer: undefined as TipBalance | undefined,
    pendingList: false,
    areFetched: false,
  },
  tippedEmployeeTPOPayments: {
    list: [] as TPOPayment[],
    pendingList: false,
  },
  tippedEmployeeOffers: {
    offer: undefined as TPOOffer | undefined,
    generatedOffer: undefined as TPOOffer | undefined,
    pendingList: false,
    areFetched: false,
  },
  tpoSearchByBusinessDate: {
    businessDate: new Date(new Date().setDate(new Date().getDate() - 1)),
    name: '',
  },
  tpoSearchByPayrollPeriod: {
    payrollPeriod: [] as SearchPayrollPeriodDropDown[],
    name: '',
    employeeStatuses: [
      {
        name: 'ACTIVE',
        id: 'ACTIVE',
      },
    ] as any,
    paymentStatuses: [] as any,
  },
  tpoSearchByPayrollPeriodChanged: {
    nameChanged: false,
    employeeStatusChanged: false,
    paymentStatusesChanged: false,
  },
  tpoPayrollData: {
    pending: false,
    file: null as TPOPayrollData | null,
  },
  tpoDailyData: {
    pending: false,
    file: null as TPOPayrollData | null,
  },
  IsSingleTipPayoutOptionChosen: false,
  isDailyFileUploading: false,
  dailyFileUploadedSuccessfully: false,
  employeesTipTotals: {} as EmployeeTipsTotal,
  employeeTipsTotalsPending: false,
};

export const fetchTippedEmployeesByPayPeriod = createAsyncThunk(
  'tips/payPeriod/employees',
  async (params: FetchTippedEmployeesByPayPeriodParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID,
      locationID,
      payrollPeriodID,
      pageNumber,
      statuses,
      employmentStatuses,
      name,
    } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchEmployeesTPOByPayPeriodRequest(
        storeState.user.accessToken,
        organizationID,
        locationID,
        payrollPeriodID,
        pageNumber,
        statuses,
        employmentStatuses,
        name,
      );
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchTippedEmployeesByBusinessDate = createAsyncThunk(
  'tips/businessDate/employees',
  async (params: FetchTippedEmployeesByBusinessDateParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID,
      locationID,
      pageNumber,
      businessDate,
      firstName,
      lastName,
      payrollId,
      statuses,
      namePattern,
    } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchEmployeesTPOByBusinessDateRequest(
        storeState.user.accessToken,
        organizationID,
        locationID,
        businessDate,
        pageNumber,
        firstName,
        lastName,
        payrollId,
        statuses,
        namePattern,
      );
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchTippedEmployeeBalanceOffer = createAsyncThunk(
  'tips/employee/offers',
  async (params: FetchTippedEmployeeBalanceOfferParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { locationID, employeeID, payrollPeriodID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchEmployeesTPOOffersPerLocationRequest(storeState.user.accessToken, locationID, employeeID, payrollPeriodID);
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchTippedEmployeeBalanceOfferById = createAsyncThunk(
  'tips/employee/offer/id',
  async (params: { offerID: string | undefined }, { getState, rejectWithValue }): Promise<any> => {
    const { offerID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchEmployeesTPOOfferByIdRequest(storeState.user.accessToken, offerID);
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const initiateTPOPayments = createAsyncThunk(
  'tips/initiateTPOPayments',
  async (params: InitiateTPOPaymentsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID, locationID, payrollPeriodID, employmentIds,
    } = params;
    const storeState = getState() as RootState;

    try {
      const result = await initiateTPOPaymentsRequest(storeState.user.accessToken, organizationID, locationID, payrollPeriodID, employmentIds);
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchTippedEmployeeTPOPayments = createAsyncThunk(
  'tips/employee/TPOPayments',
  async (params: FetchTippedEmployeeTPOPaymentsPaymentsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      employeeID, locationID, payrollPeriodID,
    } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchEmployeeTPOPaymentsRequest(storeState.user.accessToken, employeeID, locationID, payrollPeriodID);
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchTPOPayrollFile = createAsyncThunk(
  'tips/generateTPOPayrollFile',
  async (params: FetchTPOPayrollFileParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { payrollPeriodID, locationID } = params;
    const storeState = getState() as RootState;

    try {
      return {
        id: locationID ? `Location_${locationID}_PP_${payrollPeriodID}-payroll-file` : `PP_${payrollPeriodID}-payroll-file`,
        csv: await generateTPOPayrollFileRequest(storeState.user.accessToken, payrollPeriodID, locationID),
      };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchTPODailyFile = createAsyncThunk(
  'tips/generateTPODailyFile',
  async (params: FetchTPODailyFileParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID, locationID, businessDate, statuses, locationName, onlyThirdParty,
    } = params;
    const storeState = getState() as RootState;

    try {
      return {
        id: `tips_${locationName}_${businessDate}_${Date.now()}`,
        csv: await generateTPODailyFileRequest(storeState.user.accessToken, organizationID, locationID, businessDate, statuses, onlyThirdParty),
      };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const importTipsFile = createAsyncThunk(
  'tips/import',
  async (params: ImportTipsFilePropsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID,
      locationID,
      formData,
    } = params;
    const storeState = getState() as RootState;

    try {
      const result = await importTipsFileRequest(storeState.user.accessToken, organizationID, locationID, formData);
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const saveTpoPayoutConfigs = createAsyncThunk(
  'tips/tpoPayoutConfigs',
  async (params: SaveTPOPayoutConfigsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID, locationID, data,
    } = params;
    const storeState = getState() as RootState;

    try {
      const result = await saveTpoPayoutConfigsRequest(storeState.user.accessToken, organizationID, locationID, data);
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchEmployeeTipsTotals = createAsyncThunk(
  'tips/location/tips-totals',
  async (params: FetchEmployeeTipsTotalParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID,
      locationID,
      businessDate,
      namePattern,
      payrollId,
      statuses,
    } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchEmployeeTipsTotalsRequest(
        storeState.user.accessToken,
        organizationID,
        locationID,
        businessDate,
        namePattern,
        payrollId,
        statuses,
      );
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const tpoSlice = createSlice({
  name: 'tpo',
  initialState,
  reducers: {
    setTippedEmployeesSelectedLocation: (state, action) => {
      state.tippedEmployeesSelectedLocation = action.payload;
      state.tippedEmployeesByPayPeriod.pendingList = true;
    },
    setTPOSearchEmployeeStatusInPayPeriod: (state, action) => {
      state.tpoSearchByPayrollPeriod.employeeStatuses = action.payload;
    },
    setTPOSearchPaymentStatusInPayPeriod: (state, action) => {
      state.tpoSearchByPayrollPeriod.paymentStatuses = action.payload;
    },
    setTPOSearchPayPeriod: (state, action) => {
      state.tpoSearchByPayrollPeriod.payrollPeriod = action.payload;
    },
    setTPOSearchBusinessDate: (state, action) => {
      state.tpoSearchByBusinessDate.businessDate = action.payload;
    },
    setTPOSearchNameInBusinessDate: (state, action) => {
      state.tpoSearchByBusinessDate.name = action.payload;
    },
    setTPOSearchNameInPayPeriod: (state, action) => {
      state.tpoSearchByPayrollPeriod.name = action.payload;
    },
    setTippedEmployeeInfo: (state, action) => {
      state.tippedEmployee = action.payload;
    },
    setSelectedTippedEmployees: (state, action) => {
      state.selectedTippedEmployees = action.payload;
    },
    resetSelectedTippedEmployees: (state) => {
      state.selectedTippedEmployees = initialState.selectedTippedEmployees;
    },
    resetTippedEmployeesByPayPeriod: (state) => {
      state.tippedEmployeesByPayPeriod.list = initialState.tippedEmployeesByPayPeriod.list;
      state.tippedEmployeesByPayPeriod.pendingList = initialState.tippedEmployeesByPayPeriod.pendingList;
      state.tippedEmployeesByPayPeriod.pageNumber = initialState.tippedEmployeesByPayPeriod.pageNumber;
      state.tippedEmployeesByPayPeriod.pageSize = initialState.tippedEmployeesByPayPeriod.pageSize;
      state.tippedEmployeesByPayPeriod.totalSize = initialState.tippedEmployeesByPayPeriod.totalSize;
      state.tippedEmployeesByPayPeriod.numPages = initialState.tippedEmployeesByPayPeriod.numPages;
    },
    resetTippedEmployeesByBusinessDate: (state) => {
      state.tippedEmployeesByBusinessDate = initialState.tippedEmployeesByBusinessDate;
    },
    resetTPOSearchByBusinessDate: (state) => {
      state.tpoSearchByBusinessDate = initialState.tpoSearchByBusinessDate;
    },
    resetTPOSearchByPayrollPeriod: (state) => {
      state.tpoSearchByPayrollPeriod = initialState.tpoSearchByPayrollPeriod;
    },
    resetAndSetTPOSearchByBusinessDate: (state) => {
      state.tpoSearchByBusinessDate = {
        ...initialState.tpoSearchByBusinessDate,
        businessDate: new Date(state.tpoSearchByBusinessDate.businessDate.toISOString()),
      };
      state.tippedEmployeesByBusinessDate.pendingList = true;
    },
    resetAndSetTPOSearchByPayrollPeriod: (state, action) => {
      state.tpoSearchByPayrollPeriod = {
        ...initialState.tpoSearchByPayrollPeriod,
        payrollPeriod: action.payload,
      };
      state.tpoSearchByPayrollPeriodChanged = initialState.tpoSearchByPayrollPeriodChanged;
      state.tippedEmployeesByPayPeriod.pendingList = true;
    },
    resetTPOSearchByPayrollPeriodChanged: (state) => {
      state.tpoSearchByPayrollPeriodChanged = initialState.tpoSearchByPayrollPeriodChanged;
    },
    resetTippedEmployeesSelectedLocation: (state) => {
      state.tippedEmployeesSelectedLocation = initialState.tippedEmployeesSelectedLocation;
    },
    resetTPOPayrollData: (state) => {
      state.tpoPayrollData.file = initialState.tpoPayrollData.file;
    },
    resetTippedEmployeesByPayPeriodPendingList: (state) => {
      state.tippedEmployeesByPayPeriod.pendingList = initialState.tippedEmployeesByPayPeriod.pendingList;
    },
    resetTippedEmployeesByBusinessDatePendingList: (state) => {
      state.tippedEmployeesByBusinessDate.pendingList = initialState.tippedEmployeesByPayPeriod.pendingList;
    },
    setIsSingleTipPayoutOptionChosen: (state, action) => {
      state.IsSingleTipPayoutOptionChosen = action.payload;
    },
    setTPOSearchNameInPayPeriodChanged: (state, action) => {
      state.tpoSearchByPayrollPeriodChanged.nameChanged = action.payload;
    },
    setTPOSearchPaymentStatusInPayPeriodChanged: (state, action) => {
      state.tpoSearchByPayrollPeriodChanged.paymentStatusesChanged = action.payload;
    },
    setTPOSearchEmployeeStatusInPayPeriodChanged: (state, action) => {
      state.tpoSearchByPayrollPeriodChanged.employeeStatusChanged = action.payload;
    },
    resetTPODailyDataFile: (state) => {
      state.tpoDailyData.file = initialState.tpoDailyData.file;
    },
    setTippedEmployeesByBusinessDateTableColumns: (state, action) => {
      state.tippedEmployeesByBusinessDate.columns = action.payload;
    },
    setTippedEmployeesByBusinessDateList: (state, action) => {
      state.tippedEmployeesByBusinessDate.list = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTippedEmployeesByPayPeriod.pending, (state) => {
      state.tippedEmployeesByPayPeriod.list = initialState.tippedEmployeesByPayPeriod.list;
      state.tippedEmployeesByPayPeriod.pendingList = true;
    });

    builder.addCase(fetchTippedEmployeesByPayPeriod.fulfilled, (state, action) => {
      state.tippedEmployeesByPayPeriod.list = action.payload.values;
      state.tippedEmployeesByPayPeriod.pendingList = false;
      state.tippedEmployeesByPayPeriod.pageNumber = action.payload.pageNumber;
      state.tippedEmployeesByPayPeriod.pageSize = action.payload.pageSize;
      state.tippedEmployeesByPayPeriod.totalSize = action.payload.totalSize;
      state.tippedEmployeesByPayPeriod.numPages = (Math.ceil(action.payload.totalSize / initialState.tippedEmployeesByPayPeriod.pageSize));
    });

    builder.addCase(fetchTippedEmployeesByPayPeriod.rejected, (state) => {
      state.tippedEmployeesByPayPeriod.pendingList = false;
    });

    builder.addCase(fetchTippedEmployeesByBusinessDate.pending, (state) => {
      state.tippedEmployeesByBusinessDate.list = initialState.tippedEmployeesByBusinessDate.list;
      state.tippedEmployeesByBusinessDate.pendingList = true;
      state.dailyFileUploadedSuccessfully = false;
    });

    builder.addCase(fetchTippedEmployeesByBusinessDate.fulfilled, (state, action) => {
      state.tippedEmployeesByBusinessDate.list = action.payload.values;
      state.tippedEmployeesByBusinessDate.pendingList = false;
      state.tippedEmployeesByBusinessDate.pageNumber = action.payload.pageNumber;
      state.tippedEmployeesByBusinessDate.pageSize = action.payload.pageSize;
      state.tippedEmployeesByBusinessDate.totalSize = action.payload.totalSize;
      state.tippedEmployeesByBusinessDate.numPages = (Math.ceil(action.payload.totalSize / initialState.tippedEmployeesByBusinessDate.pageSize));
    });

    builder.addCase(fetchTippedEmployeesByBusinessDate.rejected, (state) => {
      state.tippedEmployeesByBusinessDate.pendingList = false;
    });

    builder.addCase(fetchTippedEmployeeBalanceOffer.pending, (state) => {
      state.tippedEmployeeBalance.generatedOffer = initialState.tippedEmployeeBalance.generatedOffer;
      state.tippedEmployeeBalance.areFetched = initialState.tippedEmployeeBalance.areFetched;
      state.tippedEmployeeBalance.pendingList = true;
    });

    builder.addCase(fetchTippedEmployeeBalanceOffer.fulfilled, (state, action) => {
      state.tippedEmployeeBalance.generatedOffer = action.payload;
      state.tippedEmployeeBalance.areFetched = true;
      state.tippedEmployeeBalance.pendingList = false;
    });

    builder.addCase(fetchTippedEmployeeBalanceOffer.rejected, (state) => {
      state.tippedEmployeeBalance.areFetched = true;
      state.tippedEmployeeBalance.pendingList = false;
    });

    builder.addCase(fetchTippedEmployeeBalanceOfferById.pending, (state) => {
      state.tippedEmployeeBalance.offer = initialState.tippedEmployeeBalance.offer;
      state.tippedEmployeeBalance.areFetched = initialState.tippedEmployeeBalance.areFetched;
      state.tippedEmployeeBalance.pendingList = true;
    });

    builder.addCase(fetchTippedEmployeeBalanceOfferById.fulfilled, (state, action) => {
      state.tippedEmployeeBalance.offer = action.payload;
      state.tippedEmployeeBalance.areFetched = true;
      state.tippedEmployeeBalance.pendingList = false;
    });

    builder.addCase(fetchTippedEmployeeBalanceOfferById.rejected, (state) => {
      state.tippedEmployeeBalance.areFetched = initialState.tippedEmployeeBalance.areFetched;
      state.tippedEmployeeBalance.pendingList = false;
    });

    builder.addCase(fetchTippedEmployeeTPOPayments.pending, (state) => {
      state.tippedEmployeeTPOPayments.list = initialState.tippedEmployeeTPOPayments.list;
      state.tippedEmployeeTPOPayments.pendingList = true;
    });

    builder.addCase(fetchTippedEmployeeTPOPayments.fulfilled, (state, action) => {
      state.tippedEmployeeTPOPayments.list = action.payload;
      state.tippedEmployeeTPOPayments.pendingList = false;
    });

    builder.addCase(fetchTippedEmployeeTPOPayments.rejected, (state) => {
      state.tippedEmployeeTPOPayments.pendingList = false;
    });

    builder.addCase(fetchTPOPayrollFile.pending, (state) => {
      state.tpoPayrollData.pending = true;
    });

    builder.addCase(fetchTPOPayrollFile.fulfilled, (state, action) => {
      state.tpoPayrollData.file = {
        id: action.payload.id,
        csv: action.payload.csv,
      };
      state.tpoPayrollData.pending = false;
    });

    builder.addCase(fetchTPOPayrollFile.rejected, (state) => {
      state.tpoPayrollData.file = initialState.tpoPayrollData.file;
      state.tpoPayrollData.pending = false;
    });

    builder.addCase(fetchTPODailyFile.pending, (state) => {
      state.tpoDailyData.pending = true;
    });

    builder.addCase(fetchTPODailyFile.fulfilled, (state, action) => {
      state.tpoDailyData.file = {
        id: action.payload.id,
        csv: action.payload.csv,
      };
      state.tpoDailyData.pending = false;
    });

    builder.addCase(fetchTPODailyFile.rejected, (state) => {
      state.tpoDailyData.file = initialState.tpoDailyData.file;
      state.tpoDailyData.pending = false;
    });

    builder.addCase(importTipsFile.pending, (state) => {
      state.isDailyFileUploading = true;
      state.dailyFileUploadedSuccessfully = false;
    });

    builder.addCase(importTipsFile.fulfilled, (state) => {
      state.isDailyFileUploading = false;
      state.dailyFileUploadedSuccessfully = true;
    });

    builder.addCase(importTipsFile.rejected, (state) => {
      state.isDailyFileUploading = false;
      state.dailyFileUploadedSuccessfully = false;
    });

    builder.addCase(fetchEmployeeTipsTotals.pending, (state) => {
      state.employeeTipsTotalsPending = true;
      state.employeesTipTotals = initialState.employeesTipTotals;
    });

    builder.addCase(fetchEmployeeTipsTotals.fulfilled, (state, action) => {
      state.employeeTipsTotalsPending = false;
      state.employeesTipTotals = action.payload;
    });

    builder.addCase(fetchEmployeeTipsTotals.rejected, (state) => {
      state.employeeTipsTotalsPending = false;
      state.employeesTipTotals = initialState.employeesTipTotals;
    });
  },
});

export const {
  setTippedEmployeesSelectedLocation,
  setTPOSearchEmployeeStatusInPayPeriod,
  setTPOSearchPaymentStatusInPayPeriod,
  setTPOSearchPayPeriod,
  setTPOSearchBusinessDate,
  setTPOSearchNameInBusinessDate,
  setTPOSearchNameInPayPeriod,
  resetTippedEmployeesByBusinessDate,
  resetTippedEmployeesByPayPeriod,
  resetTippedEmployeesSelectedLocation,
  resetTPOSearchByBusinessDate,
  resetTPOSearchByPayrollPeriod,
  resetAndSetTPOSearchByPayrollPeriod,
  setTippedEmployeeInfo,
  resetTPOPayrollData,
  resetTippedEmployeesByBusinessDatePendingList,
  resetTippedEmployeesByPayPeriodPendingList,
  setSelectedTippedEmployees,
  resetSelectedTippedEmployees,
  setIsSingleTipPayoutOptionChosen,
  setTPOSearchNameInPayPeriodChanged,
  setTPOSearchPaymentStatusInPayPeriodChanged,
  setTPOSearchEmployeeStatusInPayPeriodChanged,
  resetTPOSearchByPayrollPeriodChanged,
  resetTPODailyDataFile,
  resetAndSetTPOSearchByBusinessDate,
  setTippedEmployeesByBusinessDateTableColumns,
  setTippedEmployeesByBusinessDateList,
} = tpoSlice.actions;

export const tippedEmployeesSelectedLocationSelector = (state: RootState) => state.tpo.tippedEmployeesSelectedLocation;
export const tippedEmployeesByPayPeriodListSelector = (state: RootState): TippedEmployeePerPayrollPeriod[] => state.tpo.tippedEmployeesByPayPeriod.list;
export const tippedEmployeesByPayPeriodPendingListSelector = (state: RootState): boolean => state.tpo.tippedEmployeesByPayPeriod.pendingList;
export const tippedEmployeesByPayPeriodPageNumberSelector = (state: RootState) => state.tpo.tippedEmployeesByPayPeriod.pageNumber;
export const tippedEmployeesByPayPeriodTotalSizeSelector = (state: RootState) => state.tpo.tippedEmployeesByPayPeriod.totalSize;
export const tippedEmployeesByPayPeriodNumPagesSelector = (state: RootState) => state.tpo.tippedEmployeesByPayPeriod.numPages;
export const tippedEmployeesByBusinessDateListSelector = (state: RootState): TippedEmployeePerBusinessDate[] => state.tpo.tippedEmployeesByBusinessDate.list;
export const tippedEmployeesByBusinessDatePendingListSelector = (state: RootState): boolean => state.tpo.tippedEmployeesByBusinessDate.pendingList;
export const tippedEmployeesByBusinessDatePageNumberSelector = (state: RootState) => state.tpo.tippedEmployeesByBusinessDate.pageNumber;
export const tippedEmployeesByBusinessDateSizeSelector = (state: RootState) => state.tpo.tippedEmployeesByBusinessDate.totalSize;
export const tippedEmployeesByBusinessDateTotalSizeSelector = (state: RootState) => state.tpo.tippedEmployeesByBusinessDate.totalSize;
export const tippedEmployeesByBusinessDateNumPagesSelector = (state: RootState) => state.tpo.tippedEmployeesByBusinessDate.numPages;
export const tippedEmployeesByBusinessDateTableColumnsSelector = (state: RootState): ColumnWithEditability[] => state.tpo.tippedEmployeesByBusinessDate.columns;
export const tpoSearchByPayrollPeriodEmployeeStatusSelector = (state: RootState) => state.tpo.tpoSearchByPayrollPeriod.employeeStatuses;
export const tpoSearchPayrollPeriodSelector = (state: RootState) => state.tpo.tpoSearchByPayrollPeriod.payrollPeriod;
export const tpoSearchBusinessDateSelector = (state: RootState) => state.tpo.tpoSearchByBusinessDate.businessDate;
export const tpoSearchByBusinessDateNameSelector = (state: RootState) => state.tpo.tpoSearchByBusinessDate.name;
export const tpoSearchByPayrollPeriodNameSelector = (state: RootState) => state.tpo.tpoSearchByPayrollPeriod.name;
export const tpoSearchByPayrollPeriodPaymentStatusSelector = (state: RootState) => state.tpo.tpoSearchByPayrollPeriod.paymentStatuses;
export const tippedEmployeeBalanceOfferSelector = (state: RootState): TPOOffer | undefined => state.tpo.tippedEmployeeBalance.offer;
export const tippedEmployeeBalanceOfferPendingListSelector = (state: RootState): boolean => state.tpo.tippedEmployeeBalance.pendingList;
export const tippedEmployeeBalanceOfferGeneratedSelector = (state: RootState): TipBalance | undefined => state.tpo.tippedEmployeeBalance.generatedOffer;
export const tippedEmployeeBalanceOfferAreFetchedSelector = (state: RootState): boolean => state.tpo.tippedEmployeeBalance.areFetched;
export const tippedEmployeeTPOPaymentsListSelector = (state: RootState): TPOPayment[] | undefined => state.tpo.tippedEmployeeTPOPayments.list;
export const tippedEmployeeTPOPaymentsPendingListSelector = (state: RootState): boolean => state.tpo.tippedEmployeeTPOPayments.pendingList;
export const tippedEmployeeInfoSelector = (state: RootState) => state.tpo.tippedEmployee;
export const tpoPayrollDataSelector = (state: RootState): TPOPayrollData | null | undefined => state.tpo.tpoPayrollData.file;
export const tpoPayrollDataPendingSelector = (state: RootState): boolean => state.tpo.tpoPayrollData.pending;
export const selectedTippedEmployeesSelector = (state: RootState): TippedEmployeePerPayrollPeriod[] => state.tpo.selectedTippedEmployees;
export const isSingleTipPayoutOptionChosenSelector = (state: RootState): boolean => state.tpo.IsSingleTipPayoutOptionChosen;
export const tpoSearchByPayrollPeriodNameChangedSelector = (state: RootState): boolean => state.tpo.tpoSearchByPayrollPeriodChanged.nameChanged;
// eslint-disable-next-line max-len
export const tpoSearchByPayrollPeriodEmployeeStatusChangedSelector = (state: RootState): boolean => state.tpo.tpoSearchByPayrollPeriodChanged.employeeStatusChanged;
// eslint-disable-next-line max-len
export const tpoSearchByPayrollPeriodPaymentStatusChangedSelector = (state: RootState): boolean => state.tpo.tpoSearchByPayrollPeriodChanged.paymentStatusesChanged;
export const tpoDailyDataSelector = (state: RootState): TPOPayrollData | null | undefined => state.tpo.tpoDailyData.file;
export const tpoDailyDataPendingSelector = (state: RootState): boolean => state.tpo.tpoDailyData.pending;
export const isDailyFileUploadingSelector = (state: RootState): boolean => state.tpo.isDailyFileUploading;
export const dailyFileUploadedSuccessfullySelector = (state: RootState): boolean => state.tpo.dailyFileUploadedSuccessfully;
export const employeesTipTotalsSelector = (state: RootState) => state.tpo.employeesTipTotals;
export const employeesTipTotaslPendingSelector = (state: RootState): boolean => state.tpo.employeeTipsTotalsPending;

export default tpoSlice.reducer;
