import { ReactNode } from 'react';
import { useAppSelector } from 'store/hooks';
import { userAccessUnitsSelector } from 'store/slices/user';

export enum AccessCheckType {
  all = 'all',
  oneOf = 'oneOf',
}

export enum AccessUnit {
  TreasuryReader = 'TreasuryReader',
  TreasuryManager = 'TreasuryManager',
  FBOReader = 'FBOReader',
  FBOManager = 'FBOManager',
  EWAClientManager = 'EWAClientManager', // Customer Admin
  EWAManager = 'EWAManager', // Fuego Admin
  TipsReader = 'TipsReader',
  TipsEditor = 'TipsEditor',
  TipsClientManager = 'TipsClientManager',
  EWAClientLocationManager = 'EWAClientLocationManager', // Customer Location Manager
  FDDClientManager = 'FDDClientManager',
  EWAReport = 'EWAReport',
  PaycardClientManager = 'PaycardClientManager',
  PaycardReader = 'PaycardReader'
}

export type AccessPropsType = {
  children?: ReactNode
  oneOf?: AccessUnit[]
  all?: AccessUnit[]
  not?: boolean
};

const Access = ({
  children,
  all,
  oneOf,
  not,
}: AccessPropsType) => {
  const userAccessUnits = useAppSelector<AccessUnit[]>(userAccessUnitsSelector);

  const checkAll = !!all?.every((item) => userAccessUnits?.includes(item));
  const checkOneOf = !!oneOf?.some((item) => userAccessUnits?.includes(item));

  const hasAll = !!all && checkAll;
  const hasOneOf = !!oneOf && checkOneOf;

  let hasAccess = all && oneOf
    ? (hasAll && hasOneOf)
    : (hasAll || hasOneOf);

  if (not) {
    hasAccess = !hasAccess;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return hasAccess ? <>{children}</> : null;
};

export default Access;
