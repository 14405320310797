import { emptyPlaceholder } from 'theme';
import { useTranslation } from 'react-i18next';
import { OrderNewCardsValuesType } from 'types/PaycardManagementTypes';
import { Cell, Grid } from 'baseui/layout-grid';
import { Block } from 'baseui/block';
import { LabelMedium, ParagraphMedium } from 'baseui/typography';
import { useAppSelector } from 'store/hooks';
import { paycardOrderNewCardsPendingSelector, paycardsSelectedAddressSelector } from 'store/slices/paycards';
import Loader from 'components/Loader';

type Props = {
  values: OrderNewCardsValuesType,
}

const ConfirmationSection = ({ values }: Props) => {
  const { t } = useTranslation(['common', 'paycardManagement']);
  const orderPending = useAppSelector(paycardOrderNewCardsPendingSelector);
  const paycardsSelectedAddress = useAppSelector(paycardsSelectedAddressSelector);

  return (
    paycardsSelectedAddress
    && (
    <Grid
      gridColumns={12}
      gridMargins={24}
      overrides={{
        Grid: {
          style: {
            padding: '18px',
          },
        },
      }}
    >
      <Cell span={[12, 6, 4]}>
        <Loader active={orderPending} />
        <Block>
          <LabelMedium>{t('paycardManagement:paycardManagement.orderNewCards.cardsOrderedLabel')}</LabelMedium>
          <ParagraphMedium marginTop="4px">{values.cardsCount || emptyPlaceholder}</ParagraphMedium>
        </Block>
        <Block marginTop="24px" gridGap="4px">
          <LabelMedium>{t('paycardManagement:paycardManagement.orderNewCards.addresseeLabel')}</LabelMedium>
          <ParagraphMedium marginTop="4px">{paycardsSelectedAddress.addressee || emptyPlaceholder}</ParagraphMedium>
        </Block>
        <Block marginTop="24px" gridGap="4px">
          <LabelMedium>{t('paycardManagement:paycardManagement.orderNewCards.attentionLabel')}</LabelMedium>
          <ParagraphMedium marginTop="4px">{paycardsSelectedAddress.attention || emptyPlaceholder}</ParagraphMedium>
        </Block>
        <Block marginTop="24px" gridGap="4px">
          <LabelMedium>{t('paycardManagement:paycardManagement.orderNewCards.phoneLabel')}</LabelMedium>
          <ParagraphMedium marginTop="4px">{paycardsSelectedAddress.phone || emptyPlaceholder}</ParagraphMedium>
        </Block>
        <Block
          marginTop="24px"
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          flexDirection="column"
          gridGap="4px"
        >
          <LabelMedium>{t('paycardManagement:paycardManagement.orderNewCards.addressHeader')}</LabelMedium>
          <ParagraphMedium margin={0}>
            {`${paycardsSelectedAddress?.address?.addressLine1}${
              paycardsSelectedAddress?.address?.addressLine2
                ? `, ${paycardsSelectedAddress.address.addressLine2}`
                : ''
            }` || emptyPlaceholder}
          </ParagraphMedium>
          <ParagraphMedium margin={0}>
            {`${paycardsSelectedAddress?.address?.city},
              ${paycardsSelectedAddress?.address?.state},
              ${paycardsSelectedAddress?.address?.postalCode}`
              || emptyPlaceholder}
          </ParagraphMedium>
        </Block>
        <Block marginTop="24px" gridGap="4px">
          <LabelMedium>{t('paycardManagement:paycardManagement.orderNewCards.shippingMethod')}</LabelMedium>
          <ParagraphMedium margin="4px 0 0 0">
            {values.shippingMethod[0].label || emptyPlaceholder}
          </ParagraphMedium>
          <ParagraphMedium margin={0}>
            {`${t('paycardManagement:paycardManagement.orderNewCards.fee')}: ${values.shippingFee}` || emptyPlaceholder}
          </ParagraphMedium>
        </Block>
      </Cell>
    </Grid>
    )
  );
};

export default ConfirmationSection;
