import { BatchIDType } from 'types/BatchTypes';
import {
  OrganizationIDType,
  EditEmployeeDetailsRequestType,
  SaveOrganizationConfigsRequestType,
} from 'types/OrganizationTypes';
import {
  SearchOrganizationFilterType,
  EmployeeIDType,
  AssignEmployeesToWorkerRequestType,
  DrawRequestFilterType,
  DrawIDType,
  EditEmploymentPayRatesRequestType,
  LedgerIDType,
} from 'types/EmployeeTypes';
import {
  PayrollPeriodIDType,
} from 'types/PayGroupTypes';
import { WorkerEmploymentLinkParamsType, WorkerIDType } from 'types/WorkerTypes';
import { MoneyDtoType } from 'types/CommonTypes';
import request from './request';
import { organizationsBaseUrl } from './organizationsAPI';

export type PaginationFiltersType = {
  pageSize: string,
  pageNumber: string,
};

export const employeeDetailsBaseUrl = (
  organizationID: OrganizationIDType,
  employeeID: EmployeeIDType,
) => `organizations/${organizationID}/employments/${employeeID}`;

export const fetchEmployeeRequest = (
  token: string,
  organizationID: OrganizationIDType,
  employeeID: EmployeeIDType,
) => request({
  token,
  url: employeeDetailsBaseUrl(organizationID, employeeID),
});

export const fetchEmployeeDrawsRequest = (
  token: string,
  employeeID: EmployeeIDType,
  filter: DrawRequestFilterType,
) => request({
  token,
  url: `employments/${employeeID}/ledgers?${new URLSearchParams(filter)?.toString()}`,
});
/**
 * @returns {values: []} but with potential to return pages as well
 */
export const fetchEmployeeConfigsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  employeeID: EmployeeIDType,
) => request({
  token,
  url: `${organizationsBaseUrl}/${organizationID}/employments/${employeeID}/configs`,
});

export const fetchEmploymentPayrollPeriodsRequest = (
  token: string,
  payrollPeriodID: PayrollPeriodIDType,
  pageNumber: string,
) => {
  const queryParams = new URLSearchParams({
    pageNumber,
  })?.toString();

  return request({
    token,
    url: `/payroll-periods/${payrollPeriodID}/employments?${queryParams}`,
  });
};

export const fetchBatchEmploymentsRequest = (
  token: string,
  batchId: BatchIDType,
  pageNumber: string,
) => {
  const queryParams = new URLSearchParams({
    pageNumber,
  })?.toString();
  return request({
    token,
    url: `batches/${batchId}/employments?${queryParams}`,
  });
};

export const fetchWorkerRequest = (
  token: string,
  workerID: WorkerIDType,
) => request({
  token,
  url: `workers/${workerID}`,
});

export const searchEmployeesRequest = (
  token: string,
  organizationID: OrganizationIDType,
  filter: SearchOrganizationFilterType,
) => {
  const queryParams = new URLSearchParams(filter)?.toString();

  return request({
    token,
    url: `organizations/${organizationID}/employments?${queryParams}`,
  });
};

export const editEmployeeDetailsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  employeeID: EmployeeIDType,
  data: EditEmployeeDetailsRequestType,
) => request({
  token,
  method: 'PUT',
  payload: data,
  url: employeeDetailsBaseUrl(organizationID, employeeID),
});

export const editEmploymentWorkersRequest = (
  token: string,
  organizationID: OrganizationIDType,
  data: AssignEmployeesToWorkerRequestType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `organizations/${organizationID}/employments/bulk`,
});

export const editEmployeeConfigsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  employeeID: string,
  data: SaveOrganizationConfigsRequestType,
) => request({
  token,
  method: 'PUT',
  payload: data,
  url: `${organizationsBaseUrl}/${organizationID}/employments/${employeeID}/configs`,
});

export const fetchWorkerEmploymentLinksRequest = (
  token: string,
  params: WorkerEmploymentLinkParamsType,
) => {
  const queryParams = new URLSearchParams(params)?.toString();

  return request({
    token,
    url: `worker-employment-links?${queryParams}`,
  });
};

export const createACFRequest = (
  token: string,
  drawID: DrawIDType,
  employmentId: EmployeeIDType,
  idempotencyKey: string,
  data: { amount: MoneyDtoType },
) => request({
  token,
  method: 'POST',
  extraHeaders: {
    'Idempotency-Key': idempotencyKey,
  },
  payload: data,
  url: `employments/${employmentId}/draws/${drawID}/arrears-draws`,
});

export const createBadDebtRequest = (
  token: string,
  drawID: DrawIDType,
  employmentId: EmployeeIDType,
  data: { amount: MoneyDtoType },
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `employments/${employmentId}/draws/${drawID}/write-off-bad-debt`,
});

export const fetchEmploymentPayRatesRequest = (
  token: string,
  organizationID: OrganizationIDType,
  employmentID: EmployeeIDType,
) => request({
  token,
  url: `organizations/${organizationID}/employments-with-pay-rates/${employmentID}`,
});

export const editEmploymentPayRatesRequest = (
  token: string,
  organizationID: OrganizationIDType,
  employmentID: EmployeeIDType,
  data: EditEmploymentPayRatesRequestType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `organizations/${organizationID}/employments/${employmentID}/overridden-pay-rates`,
});

export const declineDrawRequest = (
  token: string,
  employmentID: EmployeeIDType,
  ledgerId: LedgerIDType,
) => request({
  token,
  method: 'POST',
  url: `/employments/${employmentID}/ledgers/${ledgerId}/decline`,
});

export const voidDrawRequest = (
  token: string,
  employmentID: EmployeeIDType,
  ledgerId: LedgerIDType,
) => request({
  token,
  method: 'PATCH',
  url: `/employments/${employmentID}/ledgers/${ledgerId}/void`,
});

export const unsetDefaultAccountRequest = (
  token: string,
  employmentID: EmployeeIDType,
) => request({
  token,
  method: 'DELETE',
  url: `/employments/${employmentID}/tco-default-account`,
});

export const fetchDefaultAccountRequest = (
  token: string,
  employmentID: EmployeeIDType,
) => request({
  token,
  url: `/employments/${employmentID}/tco-default-account`,
});
