import { memo } from 'react';
import {
  borderRadius,
  colors,
} from 'theme';
import { useStyletron } from 'baseui';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  LabelMedium,
  ParagraphMedium,
  ParagraphXSmall,
} from 'baseui/typography';
import priceFormatter from 'utils/priceFormatter';
import {
  Button,
  KIND,
  SIZE,
} from 'baseui/button';
import { useTranslation } from 'react-i18next';
import { Accordion, Panel } from 'baseui/accordion';
import { Block } from 'baseui/block';
import { TreasurySummaryListItemDetailsListItemType } from 'types/ReportsTypes';
import TreasurySummaryListItemDetailsListItem from './TreasurySummaryListItemDetailsListItem';

export const tableStyles = {
  '@media screen and (max-width: 1200px)': {
    overflow: 'auto',
  },
};

export const tableHeaderStyles = {
  '@media screen and (max-width: 1200px)': {
    minWidth: '960px',
  },
};

const TreasurySummaryListItem = ({
  sectionName,
  totalValue,
  totalRecordsNumber,
  onClick,
  disabledExportButton,
  info,
  pending,
}: {
  sectionName: string,
  totalValue: number,
  onClick?: any,
  disabledExportButton?: boolean,
  info?: any,
  totalRecordsNumber: number,
  pending?: boolean
}) => {
  const [css] = useStyletron();
  const { t } = useTranslation(['common']);
  const theme = useStyletron()[1];

  return (
    <Accordion
      renderAll
      disabled={!totalRecordsNumber || totalRecordsNumber === 0}
      overrides={{
        Root: {
          props: {
            id: `AccordionSection-${sectionName}-header`,
          },
        },
      }}
    >
      <Panel
        overrides={{
          Header: {
            style: {
              borderBottomLeftRadius: borderRadius,
              borderBottomRightRadius: borderRadius,
              borderTopLeftRadius: borderRadius,
              borderTopRightRadius: borderRadius,
              display: 'block',
              position: 'relative',
              textAlign: 'right',
            },
          },
          ToggleIcon: {
            style: {
              display: 'flex',
              marginLeft: 'auto',
              marginTop: '-20px',
            },
          },
        }}
        key={sectionName}
        title={(
          <>
            <Grid
              gridMargins={0}
              gridColumns={12}
            >
              <Cell
                span={onClick ? [6] : [12, 6]}
                overrides={{
                  Cell: {
                    style: { textAlign: 'left' as any },
                  },
                }}
              >
                <LabelMedium
                  paddingTop={onClick ? '6px' : '8px'}
                  paddingBottom={onClick ? '6px' : '8px'}
                >
                  {sectionName}
                </LabelMedium>
              </Cell>
              <Cell span={6}>
                {onClick && (
                  <Button
                    kind={KIND.tertiary}
                    size={SIZE.compact}
                    onClick={onClick}
                    disabled={disabledExportButton}
                    isLoading={pending}
                    overrides={{
                      Root: {
                        props: {
                          id: `TreasurySummary-${sectionName}-export-btn`,
                        },
                      },
                      LoadingSpinner: {
                        style: {
                          borderRightColor: colors.primary,
                          borderTopColor: colors.primary,
                          borderLeftColor: colors.primary,
                        },
                      },
                    }}
                  >
                    {t('common:export')}
                  </Button>
                )}
              </Cell>
            </Grid>
            <hr />
            <Grid
              gridMargins={0}
              gridColumns={12}
            >
              <Cell
                span={12}
                overrides={{
                  Cell: {
                    style: { textAlign: 'left' as any },
                  },
                }}
              >
                <ParagraphMedium margin="0px">
                  {totalValue || totalValue === 0
                    ? priceFormatter().format(totalValue)
                    : t('errors:noDataAvailable')}
                </ParagraphMedium>
              </Cell>
              {typeof totalRecordsNumber === 'number' && totalRecordsNumber > 0 && (
                <Cell
                  span={12}
                  overrides={{
                    Cell: {
                      style: { textAlign: 'left' as any },
                    },
                  }}
                >
                  <ParagraphXSmall color={theme.colors.contentSecondary} margin="0px">
                    {t(
                      'treasury:treasurySummary.details.title.availableRecords',
                      {
                        totalRecordsNumber,
                      },
                    )}
                  </ParagraphXSmall>
                </Cell>
              )}
            </Grid>
          </>
        )}
      >
        <div className={css(tableStyles)}>
          <div className={css(tableHeaderStyles)}>
            <Grid
              gridColumns={12}
              align={ALIGNMENT.center}
              gridMargins={4}
            >
              <Cell
                align={ALIGNMENT.center}
                span={3}
              >
                <Block
                  marginLeft="24px"
                >
                  <strong>{t('treasury:treasurySummary.details.organizationName')}</strong>
                </Block>
              </Cell>

              <Cell
                align={ALIGNMENT.center}
                span={1}
              >
                <strong>{t('treasury:treasurySummary.details.totalRecords')}</strong>
              </Cell>

              <Cell
                align={ALIGNMENT.center}
                span={2}
              >
                <strong>{t('treasury:treasurySummary.details.amountTotal')}</strong>
              </Cell>

              <Cell
                align={ALIGNMENT.center}
                span={2}
              >
                <strong>{t('treasury:treasurySummary.details.paidAmount')}</strong>
              </Cell>

              <Cell
                align={ALIGNMENT.center}
                span={2}
              >
                <strong>{t('treasury:treasurySummary.details.outstandingAmount')}</strong>
              </Cell>
            </Grid>

            {info && info?.map((item: TreasurySummaryListItemDetailsListItemType) => item?.summaryItem && (
              <TreasurySummaryListItemDetailsListItem
                key={item?.organization.id}
                record={item}
              />
            ))}

            {!info && (
            <Grid
              gridColumns={12}
              gridMargins={16}
            >
              <Cell span={12}>
                <ParagraphMedium>{t('treasury:noTreasurySummaryRecordsFound')}</ParagraphMedium>
              </Cell>
            </Grid>
            )}
          </div>
        </div>
      </Panel>
    </Accordion>
  );
};

export default memo(TreasurySummaryListItem);
