import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  ChangeEvent,
  memo,
  useEffect,
  useState,
} from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import {
  tippedEmployeesSelectedLocationSelector,
  fetchTippedEmployeesByBusinessDate,
  tippedEmployeesByBusinessDatePendingListSelector,
  tpoSearchByBusinessDateNameSelector,
  setTPOSearchNameInBusinessDate,
  tippedEmployeesByBusinessDateTotalSizeSelector,
  tpoSearchBusinessDateSelector,
  dailyFileUploadedSuccessfullySelector,
  resetTPOSearchByBusinessDate,
  resetTPOSearchByPayrollPeriod,
  tippedEmployeesByPayPeriodPendingListSelector,
  tippedEmployeesByPayPeriodListSelector,
  tippedEmployeesByBusinessDateListSelector,
  tpoDailyDataPendingSelector,
  fetchEmployeeTipsTotals,
  employeesTipTotaslPendingSelector,
} from 'store/slices/tpo';
import { useStyletron } from 'baseui';
import { colors } from 'theme';
import CellFormControl from 'components/CellFormControl';
import {
  Input,
  SIZE,
} from 'baseui/input';
import { Search } from 'baseui/icon';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { InputCustomHTMLElement, OptionalDateOrDateArrayType } from 'types/CommonTypes';
import { MODE, StatefulButtonGroup } from 'baseui/button-group';
import { Button, KIND } from 'baseui/button';
import { DatePicker } from 'baseui/datepicker';
import { Block } from 'baseui/block';
import { faDownload, faRotate, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import hasAccess from 'utils/hasAccess';
import { AccessCheckType, AccessUnit } from 'components/Access/Access';
import { organizationConfigSelector } from 'store/slices/organizations';
import { ConfigType } from 'types/OrganizationTypes';
import { locationConfigSelector } from 'store/slices/locations';
import { ModalNames, setModal } from 'store/slices/modals';
import useExposeSpecificFeatureFlag from 'hooks/useExposeSpecificFeatureFlag';
import SkeletonTable from 'components/Skeleton/SkeletonTable';
import TippedEmployeesPerBusinessDateTable from './TippedEmployeesPerBusinessDateTable';
import TippedEmployeesPerBusinessDateTableNew from './TippedEmployeesPerBusinessDateTable/TippedEmployeesPerBusinessDateTable';
import {
  containerViewByStyles,
  contentLocationContainerStyles,
  listContainerStyles,
  viewByLeftBlockStyles,
  viewByRightBlockBusinessDayStyles,
} from './TipManagementHelper';

let timer: any;

export type TipManagementBusinessDatedSectionPropsType = {
  isPayPeriodChecked: boolean,
  isExportDailyPartialBtnClicked: boolean,
  setIsPayPeriodChecked: (value: boolean) => void,
  handleBusinessDateChange: (params: { date: OptionalDateOrDateArrayType }) => void,
  datePickerOverridesExtended: (id: string) => any,
  handleGenerateTPODailyFileBtnClick: (isExportDailyPartialBtnClicked: boolean) => void,
  handleOnRefresh: () => void,
}

const TipManagementBusinessDateSection = ({
  isPayPeriodChecked,
  isExportDailyPartialBtnClicked,
  setIsPayPeriodChecked,
  handleBusinessDateChange,
  datePickerOverridesExtended,
  handleOnRefresh,
  handleGenerateTPODailyFileBtnClick,
}: TipManagementBusinessDatedSectionPropsType) => {
  const [css] = useStyletron();
  const { t } = useTranslation(['tipsManagement', 'dateFormats', 'common']);
  const dispatch = useAppDispatch();
  const location = useAppSelector(tippedEmployeesSelectedLocationSelector);
  const loggedOrganizationID = useAppSelector(loggedOrganizationSelector)?.id;
  const selectedLocation = useAppSelector(tippedEmployeesSelectedLocationSelector);
  const pendingList = useAppSelector(tippedEmployeesByBusinessDatePendingListSelector);
  const search = useAppSelector(tpoSearchByBusinessDateNameSelector);
  const totalSize = useAppSelector(tippedEmployeesByBusinessDateTotalSizeSelector);
  const businessDate = useAppSelector(tpoSearchBusinessDateSelector);
  const [searchDebounce, setSearchDebounce] = useState('');
  const dailyFileUploadedSuccessfully = useAppSelector(dailyFileUploadedSuccessfullySelector);
  const pendingListPayPeriod = useAppSelector(tippedEmployeesByPayPeriodPendingListSelector);
  const organizationConfig = useAppSelector(organizationConfigSelector);
  const locationConfig = useAppSelector(locationConfigSelector);
  const tippedEmployees = useAppSelector(tippedEmployeesByPayPeriodListSelector);
  const tippedEmployeesPerDay = useAppSelector(tippedEmployeesByBusinessDateListSelector);
  const tpoFilePerDayPending = useAppSelector(tpoDailyDataPendingSelector);
  const pendingTotalTips = useAppSelector(employeesTipTotaslPendingSelector);
  const dateFormatAPI = t('dateFormats:standard-reverse');
  const isNewTipSourceConfigurationAvailable = useExposeSpecificFeatureFlag('newTipAdjustment');

  // eslint-disable-next-line max-len
  const organizationTPOInclude3rdPartyTipsOrgAndLocation = organizationConfig?.find((item) => item.configKeyName === ConfigType.TPO_INCLUDE_3RD_PARTY_TIPS)?.configValue === 'true'
    && locationConfig?.find((i) => i.configKeyName === ConfigType.TPO_INCLUDE_3RD_PARTY_TIPS)?.configValue === 'true';
  // eslint-disable-next-line max-len
  const organizationEWAInclude3rdPartyTipsOrgAndLocation = organizationConfig?.find((item) => item.configKeyName === ConfigType.INCLUDE_3RD_PARTY_TIPS)?.configValue === 'true'
    && locationConfig?.find((i) => i.configKeyName === ConfigType.INCLUDE_3RD_PARTY_TIPS)?.configValue === 'true';

  const handleChangeSearch = (e: ChangeEvent<InputCustomHTMLElement>) => {
    dispatch(setTPOSearchNameInBusinessDate(e.target.value));
  };

  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    const page = Math.min(Math.max(nextPage, 1), totalSize).toString();
    if (selectedLocation && selectedLocation.length > 0) {
      dispatch(fetchTippedEmployeesByBusinessDate({
        organizationID: loggedOrganizationID,
        locationID: selectedLocation?.[0]?.id?.toString(),
        businessDate: moment(businessDate).format(dateFormatAPI),
        namePattern: search,
        pageNumber: page,
      }));
      dispatch(fetchEmployeeTipsTotals({
        organizationID: loggedOrganizationID,
        locationID: selectedLocation?.[0]?.id?.toString(),
        businessDate: moment(businessDate).format(dateFormatAPI),
        namePattern: search,
      }));
    }
  };

  const openImportTipsFileModal = () => {
    dispatch(setModal({
      name: ModalNames.UPLOAD_TIPS_FILE_MODAL,
      isOpen: true,
    }));
  };

  useEffect(() => {
    if (search === searchDebounce) {
      dispatch(fetchTippedEmployeesByBusinessDate({
        organizationID: loggedOrganizationID,
        locationID: selectedLocation?.[0]?.id?.toString(),
        businessDate: moment(businessDate).format(dateFormatAPI),
        namePattern: search,
        pageNumber: '1',
      }));
      dispatch(fetchEmployeeTipsTotals({
        organizationID: loggedOrganizationID,
        locationID: selectedLocation?.[0]?.id?.toString(),
        businessDate: moment(businessDate).format(dateFormatAPI),
        namePattern: search,
      }));
    }
  }, [
    searchDebounce,
    selectedLocation,
    businessDate,
  ]);

  useEffect(() => {
    if (dailyFileUploadedSuccessfully) {
      dispatch(fetchTippedEmployeesByBusinessDate({
        organizationID: loggedOrganizationID,
        locationID: selectedLocation?.[0]?.id?.toString(),
        businessDate: moment(businessDate).format(dateFormatAPI),
        namePattern: search,
        pageNumber: '1',
      }));
      dispatch(fetchEmployeeTipsTotals({
        organizationID: loggedOrganizationID,
        locationID: selectedLocation?.[0]?.id?.toString(),
        businessDate: moment(businessDate).format(dateFormatAPI),
        namePattern: search,
      }));
    }
  }, [
    dailyFileUploadedSuccessfully,
  ]);

  useEffect(() => {
    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      timer = undefined;
      setSearchDebounce(search);
    }, 1000);
  }, [search]);

  return (
    <Grid gridColumns={12}>
      <div className={css(contentLocationContainerStyles)}>
        <Grid gridColumns={12} gridMargins={20}>
          <Block
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            overrides={{ Block: { style: containerViewByStyles } }}
          >
            <Block
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              overrides={{ Block: { style: viewByLeftBlockStyles } }}
            >
              <CellFormControl
                cellSpan={[12, 6, 2]}
                cellAlign={ALIGNMENT.start}
                label={t('common:viewBy')}
                cellProps={{
                  overrides: {
                    Cell: {
                      style: {
                        maxWidth: '143px',
                        minWidth: '143px',
                        marginRight: '24px !important',
                      },
                    },
                  },
                }}
              >
                <StatefulButtonGroup
                  size={SIZE.compact}
                  disabled={!location || !location?.length || (isPayPeriodChecked && pendingListPayPeriod) || (!isPayPeriodChecked && pendingList)}
                  mode={MODE.radio}
                >
                  <Button
                    onClick={() => {
                      setIsPayPeriodChecked(true);
                      dispatch(resetTPOSearchByBusinessDate());
                    }}
                    isSelected={isPayPeriodChecked}
                    overrides={{
                      BaseButton: {
                        props: {
                          id: 'TipManagement-location-payPeriod-view-btn',
                          name: 'TipManagement-location-payPeriod-view-btn',
                        },
                      },
                    }}
                  >
                    {t('common:payPeriod')}
                  </Button>
                  <Button
                    onClick={() => {
                      setIsPayPeriodChecked(false);
                      dispatch(resetTPOSearchByPayrollPeriod());
                    }}
                    isSelected={!isPayPeriodChecked}
                    overrides={{
                      BaseButton: {
                        props: {
                          id: 'TipManagement-location-day-view-btn',
                          name: 'TipManagement-location-day-view-btn',
                        },
                      },
                    }}
                  >
                    {t('common:day.label')}
                  </Button>
                </StatefulButtonGroup>
              </CellFormControl>
              {!isPayPeriodChecked && (
              <CellFormControl
                cellSpan={[12]}
                label={t('tipsManagement:businessDate.label')}
              >
                <DatePicker
                  size={SIZE.compact}
                  formatDisplayValue={(date: any) => new Intl.DateTimeFormat('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }).format(date)}
                  disabled={!location || !location?.length || pendingList}
                  formatString="yyyy/MM/dd"
                  value={businessDate}
                  onChange={handleBusinessDateChange}
                  overrides={datePickerOverridesExtended('tpo-day-view-businessDate-dateRange')}
                />
              </CellFormControl>
              )}
            </Block>
            <Block
              alignItems="center"
              alignContent="end"
              justifyItems="end"
              display="inline-flex"
              justifyContent="flex-end"
              gridGap="8px"
              marginTop="20px"
              overrides={{ Block: { style: viewByRightBlockBusinessDayStyles } }}
            >
              <Button
                disabled={!location || !location?.length || (isPayPeriodChecked && pendingListPayPeriod) || (!isPayPeriodChecked && pendingList)}
                onClick={handleOnRefresh}
                size={SIZE.compact}
                kind={KIND.secondary}
              >
                <FontAwesomeIcon
                  className={css({ cursor: 'pointer', justifyContent: 'center' })}
                  icon={faRotate}
                />
              </Button>
              {(hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager, AccessUnit.TipsClientManager, AccessUnit.EWAClientManager])
                && (organizationTPOInclude3rdPartyTipsOrgAndLocation || organizationEWAInclude3rdPartyTipsOrgAndLocation) && !isPayPeriodChecked)
                && (
                <Button
                  kind={KIND.secondary}
                  size={SIZE.compact}
                  onClick={() => { handleGenerateTPODailyFileBtnClick(true); }}
                  startEnhancer={(
                    <FontAwesomeIcon
                      icon={faDownload}
                    />
                      )}
                  isLoading={tpoFilePerDayPending && isExportDailyPartialBtnClicked}
                  disabled={(!location
                        || !location?.length)
                        || (isPayPeriodChecked && (!tippedEmployees || !tippedEmployees?.length))
                        || (!isPayPeriodChecked && (!tippedEmployeesPerDay || !tippedEmployeesPerDay?.length))
                        || (!isPayPeriodChecked && (businessDate > new Date()))}
                  overrides={{
                    Root: {
                      style: {
                        height: '36px',
                      },
                      props: {
                        'data-testid': 'TipManagement-download-tpo-payroll-template-button',
                        id: 'TipManagement-download-tpo-payroll-template-button',
                      },
                    },
                    LoadingSpinner: {
                      style: {
                        borderRightColor: colors.primary,
                        borderTopColor: colors.primary,
                        borderLeftColor: colors.primary,
                      },
                    },
                  }}
                >
                  {t('tipsManagement:downloadTemplate.button')}
                </Button>
                )}
              {(hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager, AccessUnit.TipsClientManager, AccessUnit.EWAClientManager])
                  && (organizationTPOInclude3rdPartyTipsOrgAndLocation || organizationEWAInclude3rdPartyTipsOrgAndLocation) && !isPayPeriodChecked)
                  && (
                  <Button
                    kind={KIND.primary}
                    size={SIZE.compact}
                    onClick={openImportTipsFileModal}
                    startEnhancer={(
                      <FontAwesomeIcon
                        icon={faUpload}
                      />
                        )}
                    disabled={(!location
                          || !location?.length)
                          || (isPayPeriodChecked && (!tippedEmployees || !tippedEmployees?.length))
                          || (!isPayPeriodChecked && (!tippedEmployeesPerDay || !tippedEmployeesPerDay?.length))
                          || (!isPayPeriodChecked && (businessDate > new Date()))}
                    overrides={{
                      Root: {
                        style: {
                          height: '36px',
                        },
                        props: {
                          'data-testid': 'TipManagement-upload-tpo-payroll-file-button',
                          id: 'TipManagement-import-tpo-payroll-file-button',
                        },
                      },
                    }}
                  >
                    {t('common:uploadFile')}
                  </Button>
                  )}
            </Block>
          </Block>
        </Grid>
        <Grid gridColumns={12} gridMargins={20}>
          <CellFormControl
            cellSpan={[12, 6, 3]}
            label={t('common:searchButton')}
          >
            <Input
              disabled={!location || !location?.length || pendingList}
              clearable
              clearOnEscape
              size={SIZE.compact}
              startEnhancer={<Search />}
              type="text"
              name="search"
              onChange={handleChangeSearch}
              value={search}
              placeholder={t('tipsManagement:searchByName.placeholder')}
              overrides={{
                Input: {
                  props: {
                    id: 'Tip-Management-Day-view-Search-by-NamePattern',
                    'data-testid': 'Tip-Management-Day-view-Search-by-NamePattern',
                    autoComplete: 'off',
                  },
                },
              }}
            />
          </CellFormControl>
        </Grid>
        {(pendingList || pendingTotalTips) && (
          <div className={css(listContainerStyles)}>
            <SkeletonTable
              rows={5}
              columns={5}
              cellStylesOverride={{ height: '72px' }}
              tableRowStylesOverride={{ width: 'calc(100% - 40px)' }}
            />
          </div>
        )}
        {!pendingList && !pendingTotalTips && (
          <Cell span={12}>
            {isNewTipSourceConfigurationAvailable
              ? <TippedEmployeesPerBusinessDateTableNew handlePageChange={handlePageChange} />
              : <TippedEmployeesPerBusinessDateTable handlePageChange={handlePageChange} />}

          </Cell>
        )}
      </div>
    </Grid>
  );
};

export default memo(TipManagementBusinessDateSection);
