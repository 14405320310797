import * as Yup from 'yup';
import messages from './messages';

const profileValidationSchema = Yup.object().shape({
  enrollmentStatus: Yup.array().of(Yup.object().shape(
    {
      value: Yup.string().max(64, messages?.maxLength),
      label: Yup.string().max(255, messages?.maxLength),
    },
  ).required()),
  email: Yup.string().required('Email is required').email(messages?.email),
  firstName: Yup.string().required('First Name is required').matches(/^[a-zA-Z ]+$/, messages?.name),
  lastName: Yup.string().required('Last Name is required').matches(/^[a-zA-Z ]+$/, messages?.name),
  mobilePhone: Yup.string()
    .matches(/^\d{10}$/, messages?.mobilePhone),
});

// TODO: remove default export once we have more than one declaration to export
export default profileValidationSchema;
