import { useParams } from 'react-router-dom';
import { Block } from 'baseui/block';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  LabelMedium,
} from 'baseui/typography';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import priceFormatter from 'utils/priceFormatter';
import { unScalePrice } from 'utils/priceScale';
import { useStyletron } from 'baseui';
import { memo, useState } from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { Card } from 'baseui/card';
import {
  Draw,
  EmployeeDrawsAvailableOperations,
  EmployeeIDType,
  SubElementStatusType,
  SubElementType,
  SubElementTypeEnum,
} from 'types/EmployeeTypes';
import {
  declineDraw,
  employeesTPODrawsPendingListSelector,
  voidDraw,
} from 'store/slices/employees';
import { fetchTippedEmployeeBalanceOfferById } from 'store/slices/tpo';
import {
  ModalNames,
  modalsSelector,
  setModal,
} from 'store/slices/modals';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import TippedEmployeeTPOPaymentsOfferModal from 'screens/TipManagement/EmployeeTipOverviewTPOPayments/TippedEmployeeTPOPaymentsOfferModal';
import {
  Pagination,
  SIZE,
} from 'baseui/pagination';
import { paginationTransparentOverrides } from 'screens/CommonHelpers';
import { Value } from 'baseui/select';
import {
  checkIfSubElementStatusTypeIsPositive, drawsListContainerStyles, tableHeaderStyles, tableStyles,
} from 'screens/Employees/EmployeesHelpers';
import { AccessCheckType, AccessUnit } from 'components/Access/Access';
import hasAccess from 'utils/hasAccess';
import ResendMoneyMFAModal from '../ResendMoneyMFAModal/ResendMoneyMFAModal';
import EmployeeDetailsTPODrawsTableItem from './EmployeeDetailsTPODrawsTableItem/EmployeeDetailsTPODrawsTableItem';

export type EmployeeDetailsTPODrawTablePropsType = {
  filteredDraws: Draw[],
  payPeriod: Value | undefined,
  handlePageChange: ({ nextPage }: { nextPage: number }) => void,
  pageNumber: number,
  numPages: number,
  fetchTPODraws: (filter: any) => void,
}

export const extractAmountItem = (subElements: SubElementType[]) => (
  subElements?.find((subElement) => subElement.type === SubElementTypeEnum.TCO_RESEND
    && checkIfSubElementStatusTypeIsPositive(subElement.status))
    ?? subElements?.find((subElement) => subElement.type === SubElementTypeEnum.TCO_REQUEST)
);

const EmployeeDetailsTPODrawsTable = ({
  filteredDraws,
  payPeriod,
  handlePageChange,
  pageNumber,
  numPages,
  fetchTPODraws,
}: EmployeeDetailsTPODrawTablePropsType) => {
  const [css] = useStyletron();
  const { t } = useTranslation(['tipsManagement', 'dateFormats']);
  const dispatch = useAppDispatch();
  const pending = useAppSelector(employeesTPODrawsPendingListSelector);
  const modals = useAppSelector(modalsSelector);
  const [clickedPaymentId, setClickedPaymentId] = useState<string>();
  const [selectedDraw, setSelectedDraw] = useState<Draw | undefined>();
  const isEwaManager = hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager]);
  const { employeeID }: { employeeID: EmployeeIDType } = useParams<{ employeeID: string }>();

  const handleOfferIDClick = (selectedOffer: number | undefined) => {
    dispatch(fetchTippedEmployeeBalanceOfferById({ offerID: String(selectedOffer) }));
    dispatch(setModal({
      name: ModalNames.EMPLOYEE_TPO_OFFER_PER_LOCATION_DETAILS_MODAL,
      isOpen: true,
    }));
  };

  const drawsAmountSum = filteredDraws?.reduce((acc, draw) => {
    const amountElements = draw?.subElements.filter(
      (subElement: any) => subElement.type === SubElementTypeEnum.TCO_REQUEST
        || subElement.type === SubElementTypeEnum.TCO_RESEND,
    );

    acc.sum += amountElements.reduce((sum, element) => {
      const { status, amount } = element;
      const isInvalidStatus = status === SubElementStatusType.VOIDED
        || status === SubElementStatusType.DECLINED
        || status === SubElementStatusType.NOT_PAID;

      return sum + (isInvalidStatus ? 0 : unScalePrice(amount?.value, amount?.scale));
    }, 0);

    return acc;
  }, { sum: 0 });

  const handleAvailableOperationClick = (
    draw: Draw,
    type: EmployeeDrawsAvailableOperations,
  ) => {
    setClickedPaymentId(`${draw?.offer?.id}`);
    setSelectedDraw(draw);
    switch (type) {
      case EmployeeDrawsAvailableOperations.VOID:
        dispatch(voidDraw(({
          employmentID: employeeID,
          ledgerID: `${draw?.id}`,
        }))).then(() => {
          setClickedPaymentId('');
          fetchTPODraws({
            pageNumber: '1',
            type: 'TCO',
            includeOfferDetails: 'true',
          });
        });
        break;
      case EmployeeDrawsAvailableOperations.RETRY:
        dispatch(setModal({
          name: ModalNames.RESEND_MONEY_MFA_MODAL,
          isOpen: true,
        }));
        setClickedPaymentId('');
        break;
      case EmployeeDrawsAvailableOperations.DECLINE:
        dispatch(declineDraw(({
          employmentID: employeeID,
          ledgerID: `${draw?.id}`,
        }))).then(() => {
          setClickedPaymentId('');
          fetchTPODraws({
            pageNumber: '1',
            type: 'TCO',
            includeOfferDetails: 'true',
          });
        });
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className={css(tableStyles)}>
        <div className={css(tableHeaderStyles)}>
          <Grid
            gridColumns={12}
            align={ALIGNMENT.center}
            gridMargins={30}
          >
            <Cell
              align={ALIGNMENT.center}
              span={1}
            >
              <Block display="flex" justifyContent="start">
                <strong>{t('tipsManagement:table.header.status.label')}</strong>
              </Block>
            </Cell>

            <Cell
              align={ALIGNMENT.center}
              span={2}
            >
              <strong>{t('tipsManagement:table.header.date.label')}</strong>
            </Cell>

            <Cell
              align={ALIGNMENT.center}
              span={1.5}
            >
              <strong>{t('tipsManagement:table.header.earnedToDay.label')}</strong>
            </Cell>

            <Cell
              align={ALIGNMENT.center}
              span={1.5}
            >
              <strong>{t('tipsManagement:table.header.payableToDay.label')}</strong>
            </Cell>

            <Cell
              align={ALIGNMENT.center}
              span={1.5}
            >
              <strong>{t('tipsManagement:table.header.paidThisDay.label')}</strong>
            </Cell>

            <Cell
              align={ALIGNMENT.center}
              span={1}
            >
              <strong>{t('tipsManagement:table.header.offerID.label')}</strong>
            </Cell>

            <Cell
              align={ALIGNMENT.center}
              span={2.5}
            >
              <strong>{t('tipsManagement:table.header.paymentInfo.label')}</strong>
            </Cell>

            { isEwaManager
                && (
                <Cell
                  align={ALIGNMENT.center}
                  span={1}
                >
                  <strong>{t('tipsManagement:table.header.actions.label')}</strong>
                </Cell>
                )}
          </Grid>
        </div>

        <div className={css(drawsListContainerStyles)}>
          <Loader active={pending} />
          {filteredDraws?.map((payment: any) => (
            <EmployeeDetailsTPODrawsTableItem
              payment={payment}
              handleOfferIDClick={handleOfferIDClick}
              employeeID={employeeID}
              handleAvailableOperationClick={handleAvailableOperationClick}
              clickedPaymentId={clickedPaymentId}
            />
          ))}
          {filteredDraws.length === 0 && (
            <Block
              margin="24px"
            >
              <Grid
                gridColumns={12}
                align={ALIGNMENT.center}
                gridMargins={4}
              >
                <Cell
                  align={ALIGNMENT.center}
                  span={4}
                >
                  {t('tipsManagement:employee.payments.noResults')}
                </Cell>
              </Grid>
            </Block>
          )}
          {payPeriod && payPeriod.length > 0 ? (
            <Block
              marginBottom="8px"
            >
              <Grid gridMargins={0}>
                <Cell span={12}>
                  <Card
                    overrides={{
                      Root: {
                        style: {
                          backgroundColor: '#EEEEEE',
                          borderRadius: '0',
                        },
                      },
                    }}
                  >
                    <LabelMedium>
                      {t('employee.paidTips.label')}
                      {': '}
                      {priceFormatter().format(drawsAmountSum?.sum)}
                    </LabelMedium>
                  </Card>
                </Cell>
              </Grid>
            </Block>
          ) : filteredDraws.length !== 0 && (
            <Block
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="center"
              justifyItems="center"
              marginBottom="16px"
            >
              <Pagination
                size={SIZE.compact}
                numPages={numPages}
                currentPage={pageNumber}
                overrides={paginationTransparentOverrides}
                onPageChange={handlePageChange}
              />
            </Block>
          )}
        </div>
      </div>
      {checkIsModalOpen(modals, ModalNames.EMPLOYEE_TPO_OFFER_PER_LOCATION_DETAILS_MODAL) && <TippedEmployeeTPOPaymentsOfferModal />}
      {checkIsModalOpen(modals, ModalNames.RESEND_MONEY_MFA_MODAL) && selectedDraw
        && <ResendMoneyMFAModal selectedDraw={selectedDraw} fetchDraws={fetchTPODraws} isTPO />}
    </>
  );
};

export default memo(EmployeeDetailsTPODrawsTable);
