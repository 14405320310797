import { ChangeEvent, createContext, useEffect } from 'react';
import {
  FormikHandlers, FormikHelpers, FormikState, useFormik,
} from 'formik';
import { useTranslation } from 'react-i18next';
import { Layer } from 'baseui/layer';
import { Block } from 'baseui/block';
import { Cell, Grid } from 'baseui/layout-grid';
import { AddAddressValuesType } from 'types/PaycardManagementTypes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAddressValidationSchema as validationSchema } from 'validation/paycardManagementSchema';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import AppModal from 'components/AppModal/AppModal';
import useIsFormChanged from 'hooks/useIsFormChanged';
import Loader from 'components/Loader';
import AppInput from 'components/Form/AppInput';
import { addAddressInitialvalues } from 'initialValues/PaycardsInitialValues';
import formatValue from 'utils/formatValueWithRegex';
import { states } from 'types/CommonTypes';
import AppSelect from 'components/Form/AppSelect';
import { LABEL_PLACEMENT, STYLE_TYPE } from 'baseui/checkbox';
import AppCheckbox from 'components/Form/AppCheckbox';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import {
  addShippingAddress,
  editShippingAddress,
  fetchShippingAddresses,
  paycardManagementSelectedCardStockSelector,
  paycardsEditAddressSelector,
  paycardsLocationLegalAddressSelector,
  setEditAddress,
} from 'store/slices/paycards';
import { addAddressDataMapper } from 'dataMappers/paycardManagementMapper';
import { LabelMedium } from 'baseui/typography';
import { blockRightMarginOverrides } from '../../../../PaycardManagementHelper';

export const AddAddressContext = createContext(
  {} as FormikState<AddAddressValuesType> & FormikHelpers<AddAddressValuesType> & FormikHandlers,
);

const OrderNewCardsAddAddressModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'paycardManagement', 'organizations']);
  const modals = useAppSelector(modalsSelector);
  const isModalOpen = checkIsModalOpen(modals, ModalNames.ADD_NEW_ADDRESS_MODAL);
  const organization = useAppSelector(loggedOrganizationSelector);
  const cardStock = useAppSelector(paycardManagementSelectedCardStockSelector);
  const paycardsLocationLegalAddress = useAppSelector(paycardsLocationLegalAddressSelector);
  const paycardsEditAddress = useAppSelector(paycardsEditAddressSelector);
  const initialValues: AddAddressValuesType = {
    ...addAddressInitialvalues,
  };

  const stateOptions = Object.values(states).map((state) => ({
    label: state,
    value: state,
  }));

  const onSubmit = (
    values: AddAddressValuesType,
  ) => {
    if (organization?.id && cardStock?.locationId) {
      const data = addAddressDataMapper(values);
      if (paycardsEditAddress && paycardsEditAddress?.id) {
        dispatch(editShippingAddress({
          organizationId: organization?.id,
          locationId: cardStock?.locationId.toString(),
          addressId: paycardsEditAddress.id.toString(),
          data,
        }))
          .then(() => {
            dispatch(setEditAddress(null));
            dispatch(fetchShippingAddresses({
              organizationId: organization?.id,
              locationId: cardStock?.locationId.toString(),
            }))
              .then(() => {
                dispatch(setModal({
                  name: ModalNames.ADD_NEW_ADDRESS_MODAL,
                  isOpen: false,
                }));
                dispatch(setModal({
                  name: ModalNames.SELECT_ADDRESS_MODAL,
                  isOpen: true,
                }));
              });
          });
      } else {
        dispatch(addShippingAddress({
          organizationId: organization?.id,
          locationId: cardStock?.locationId.toString(),
          data,
        }))
          .then(() => {
            dispatch(fetchShippingAddresses({
              organizationId: organization?.id,
              locationId: cardStock?.locationId.toString(),
            })).then(() => {
              dispatch(setModal({
                name: ModalNames.ADD_NEW_ADDRESS_MODAL,
                isOpen: false,
              }));
              dispatch(setModal({
                name: ModalNames.SELECT_ADDRESS_MODAL,
                isOpen: true,
              }));
            });
          });
      }
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const {
    values,
    handleSubmit,
    resetForm,
    validateForm,
    isSubmitting,
    setSubmitting,
    setValues,
    isValid,
    setErrors,
    errors,
    setFieldValue,
  } = formik;

  const { isFormChanged, setDefaultValues } = useIsFormChanged(values);

  const handleModalClose = () => {
    resetForm();
  };

  const handleSubmitExtended = () => {
    validateForm(values)
      .then((err) => {
        if (Object.keys(err).length === 0 && err.constructor === Object) {
          handleSubmit();
        }
      });
  };

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const formattedPhone = formatValue(e.target.value, /(\d{3})(\d{3})(\d{1,4})/, '$1-$2-$3');
    setFieldValue('phone', formattedPhone);
  };

  useEffect(() => {
    if (isModalOpen) {
      setSubmitting(false);
      setValues({
        ...initialValues,
      });
      setDefaultValues({
        ...initialValues,
      });
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (errors) {
      setErrors({
        ...errors,
      });
    }
  }, [values, errors]);

  useEffect(() => {
    if (paycardsLocationLegalAddress) {
      const data = {
        addressLine1: paycardsLocationLegalAddress.addressLine1,
        unit: paycardsLocationLegalAddress.addressLine2 || '',
        city: paycardsLocationLegalAddress.city,
        postalCode: paycardsLocationLegalAddress.postalCode,
        state: [{ value: paycardsLocationLegalAddress.state }],
      };
      setValues({
        ...values,
        ...data,
      });
      setDefaultValues({
        ...values,
        ...data,
      });
    }
  }, [paycardsLocationLegalAddress]);

  useEffect(() => {
    if (paycardsEditAddress) {
      const formattedPhone = formatValue(paycardsEditAddress.phone, /(\d{3})(\d{3})(\d{1,4})/, '$1-$2-$3');
      const data = {
        addressee: paycardsEditAddress.addressee,
        attention: paycardsEditAddress.attention,
        phone: formattedPhone,
        addressLine1: paycardsEditAddress.address.addressLine1,
        unit: paycardsEditAddress.address.addressLine2 || '',
        city: paycardsEditAddress.address.city,
        postalCode: paycardsEditAddress.address.postalCode,
        state: [{ value: paycardsEditAddress.address.state }],
        isDefault: paycardsEditAddress.isDefault,
      };
      setValues({
        ...values,
        ...data,
      });
      setDefaultValues({
        ...values,
        ...data,
      });
    }
  }, [paycardsEditAddress]);

  return (
    <Layer index={400}>
      <AddAddressContext.Provider value={formik}>
        <form>
          <AppModal
            modalWidth={['90vw', '90vw', '70vw', '40vw']}
            modal={ModalNames.ADD_NEW_ADDRESS_MODAL}
            title={t('paycardManagement:paycardManagement.addAddressModal.title')}
            cancelBtnText={t('common:cancel')}
            onClose={handleModalClose}
            actionBtnText={t('common:save')}
            onAction={handleSubmitExtended}
            isCloseDisabled={isSubmitting}
            isActionDisabled={isSubmitting || !isFormChanged || !isValid}
            minWidth="35vw"
          >
            <Loader active={isSubmitting} />
            <Block>
              <Grid
                gridColumns={12}
                gridMargins={18}
                gridGaps={0}
                gridGutters={0}
              >
                <Cell
                  span={[12, 12, 6]}
                  gridGutters={[0, 0, 24]}
                >
                  <Block>
                    <AppInput
                      showStar
                      name="addressee"
                      label={t('paycardManagement:paycardManagement.addAddressModal.addressee')}
                      cellSpan={12}
                      context={AddAddressContext}
                      inputProps={{
                        id: 'addNewAddressAddresse',
                        autoComplete: 'off',
                      }}
                    />
                  </Block>
                </Cell>
              </Grid>
              <Grid
                gridColumns={12}
                gridMargins={18}
                gridGaps={0}
                gridGutters={0}
              >
                <Cell
                  span={[12, 12, 6]}
                  gridGutters={[0, 0, 24]}
                >
                  <Block>
                    <AppInput
                      showStar
                      name="attention"
                      label={t('paycardManagement:paycardManagement.addAddressModal.attention')}
                      cellSpan={12}
                      context={AddAddressContext}
                      inputProps={{
                        id: 'addNewAddressAttention',
                        autoComplete: 'off',
                      }}
                    />
                  </Block>
                </Cell>
              </Grid>
              <Grid
                gridColumns={12}
                gridMargins={18}
                gridGaps={0}
                gridGutters={0}
              >
                <Cell
                  span={[12, 12, 6]}
                  gridGutters={[0, 0, 24]}
                >
                  <Block>
                    <AppInput
                      showStar
                      name="phone"
                      inputProps={{
                        id: 'phone',
                        autoComplete: 'off',
                        onChange: handlePhoneChange,
                      }}
                      formControlProps={{ htmlFor: 'phone' }}
                      label={t('paycardManagement:paycardManagement.addAddressModal.mobileNumber')}
                      cellSpan={12}
                      context={AddAddressContext}
                    />
                  </Block>
                </Cell>
              </Grid>
              <Grid
                gridColumns={12}
                gridMargins={18}
                gridGaps={0}
                gridGutters={0}
              >
                <Cell
                  span={[12, 12, 6]}
                  gridGutters={[0, 0, 24]}
                >
                  <Block>
                    <AppInput
                      showStar
                      name="addressLine1"
                      inputProps={{
                        id: 'addNewAddressAddressLine1',
                        autoComplete: 'off',
                      }}
                      formControlProps={{
                        htmlFor: 'addNewAddressAddressLine1',
                      }}
                      label={t('paycardManagement:paycardManagement.addAddressModal.address')}
                      cellSpan={12}
                      context={AddAddressContext}
                    />
                  </Block>
                </Cell>
                <Cell
                  span={[12, 12, 6]}
                  gridGutters={[0, 0, 24]}
                >
                  <Block
                    overrides={{
                      Block: {
                        style: blockRightMarginOverrides,
                      },
                    }}
                  >
                    <AppInput
                      name="unit"
                      inputProps={{
                        id: 'addNewAddressUnit',
                        autoComplete: 'off',
                      }}
                      formControlProps={{
                        htmlFor: 'addNewAddressUnit',
                      }}
                      label={t('paycardManagement:paycardManagement.addAddressModal.unit')}
                      cellSpan={12}
                      context={AddAddressContext}
                    />
                  </Block>
                </Cell>
              </Grid>
              <Grid
                gridColumns={12}
                gridMargins={18}
                gridGaps={[8, 8, 0]}
                gridGutters={0}
              >
                <Cell
                  span={[12, 12, 6]}
                  gridGutters={[0, 0, 24]}
                >
                  <Block>
                    <AppInput
                      showStar
                      name="city"
                      inputProps={{
                        id: 'addNewAddressCity',
                        autoComplete: 'off',
                      }}
                      label={t('paycardManagement:paycardManagement.addAddressModal.city')}
                      cellSpan={12}
                      context={AddAddressContext}
                      formControlProps={{
                        htmlFor: 'addNewAddressCity',
                        overrides: {
                          ControlContainer: {
                            style: {
                              marginBottom: '0',
                            },
                          },
                        },
                      }}
                    />
                  </Block>
                </Cell>
                <Cell
                  span={[12, 12, 6]}
                >
                  <Grid
                    gridColumns={6}
                    gridMargins={0}
                    gridGutters={0}
                    gridGaps={[8, 8, 0]}
                  >
                    <Cell
                      span={[12, 12, 6]}
                      gridColumns={12}
                      gridGutters={[0, 0, 24]}
                    >
                      <AppSelect
                        showStar
                        name="state"
                        label={t('paycardManagement:paycardManagement.addAddressModal.state')}
                        options={stateOptions}
                        cellSpan={[12, 12, 6]}
                        context={AddAddressContext}
                        selectProps={{
                          clearable: false,
                        }}
                        formControlProps={{
                          htmlFor: 'state',
                          overrides: {
                            ControlContainer: {
                              style: {
                                marginBottom: '0',
                              },
                            },
                          },
                        }}
                      />
                    </Cell>
                    <Cell
                      span={[12, 12, 6]}
                      gridColumns={12}
                      gridGutters={[0, 0, 24]}
                    >
                      <Block
                        overrides={{
                          Block: {
                            style: blockRightMarginOverrides,
                          },
                        }}
                      >
                        <AppInput
                          showStar
                          name="postalCode"
                          inputProps={{
                            id: 'addNewAddressPostalCode',
                            autoComplete: 'off',
                          }}
                          formControlProps={{
                            htmlFor: 'addNewAddressPostalCode',
                            overrides: {
                              ControlContainer: {
                                style: {
                                  marginTop: '0',
                                },
                              },
                            },
                          }}
                          label={t('paycardManagement:paycardManagement.addAddressModal.zip')}
                          cellSpan={[12, 12, 6]}
                          context={AddAddressContext}
                        />
                      </Block>
                    </Cell>
                  </Grid>
                </Cell>
              </Grid>
              <Grid
                gridColumns={12}
                gridMargins={0}
                gridGaps={0}
              >
                <Cell
                  span={[12, 12, 12]}
                >
                  <AppCheckbox
                    name="isDefault"
                    label={(
                      <LabelMedium whiteSpace="normal">
                        { t('paycardManagement:paycardManagement.addAddressModal.makeDefault')}
                      </LabelMedium>
                      )}
                    checkboxType={STYLE_TYPE.default}
                    labelPlacement={LABEL_PLACEMENT.right}
                    cellSpan={12}
                    context={AddAddressContext}
                  />
                </Cell>
              </Grid>
            </Block>
          </AppModal>
        </form>
      </AddAddressContext.Provider>
    </Layer>

  );
};

export default OrderNewCardsAddAddressModal;
