import { Pagination, SIZE } from 'baseui/pagination';
import { Block } from 'baseui/block';
import { useStyletron } from 'baseui';
import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid';
import { useTranslation } from 'react-i18next';
import { TippedEmployeePerBusinessDate } from 'types/TPOTypes';
import { memo, useState } from 'react';
import { MoneyDtoType } from 'types/CommonTypes';
import { setTippedEmployeesByBusinessDateList } from 'store/slices/tpo';
import { useAppDispatch } from 'store/hooks';
import { paginationTransparentOverrides } from 'screens/CommonHelpers';
import {
  tableListContainerStyles,
  tableStyles,
  tableTotalTipsContainerStyles,
} from './AppDataTableHelpers';
import AppDataTableBodyRow from './AppDataTableBodyRow';
import AppDataTableTotalTipsRow from './AppDataTableTotalTipsRow';
import AppDataTableHeader from './AppDataTableHeader';

export type AppDataTablePropsType = {
  data: TippedEmployeePerBusinessDate[];
  isPaginationAvailable?: boolean;
  pageNumber: number;
  numPages: number;
  handlePageChange: ({ nextPage }: { nextPage: number }) => void,
};

const AppDataTable = ({
  data,
  isPaginationAvailable = true,
  pageNumber,
  numPages,
  handlePageChange,
}: AppDataTablePropsType) => {
  const [css] = useStyletron();
  const { t } = useTranslation(['tipsManagement']);
  const dispatch = useAppDispatch();
  const [rows, setRows] = useState<TippedEmployeePerBusinessDate[]>(data);

  const updateRowData = (
    rowId: number,
    columnKey: keyof TippedEmployeePerBusinessDate,
    newValue: string | number | MoneyDtoType | undefined,
  ) => {
    setRows((prevRows) => prevRows.map((row) => {
      if (row.id === rowId) {
        return { ...row, [columnKey]: newValue };
      }
      return row;
    }));
  };

  const updateTippedEmployeesRowValues = (shouldUpdate: boolean) => {
    if (shouldUpdate) {
      dispatch(setTippedEmployeesByBusinessDateList(rows));
    } else {
      setRows(data);
    }
  };
  return (
    <div
      className={css(tableStyles)}
    >
      <AppDataTableHeader updateTippedEmployeesRowValues={updateTippedEmployeesRowValues} />
      <div className={css(tableTotalTipsContainerStyles)}>
        <AppDataTableTotalTipsRow />
      </div>
      <div className={css(tableListContainerStyles)}>
        {rows?.map((row: TippedEmployeePerBusinessDate) => (
          <AppDataTableBodyRow
            row={row}
            key={row.id}
            updateRowData={updateRowData}
          />
        ))}
        {rows.length === 0 && (
          <Block
            margin="24px"
          >
            <Grid
              gridColumns={12}
              align={ALIGNMENT.center}
              gridMargins={4}
            >
              <Cell
                align={ALIGNMENT.center}
                span={4}
              >
                {t('tipsManagement:tippedEmployeesPerBusinessDateTable.noResults')}
              </Cell>
            </Grid>
          </Block>
        )}
      </div>
      { isPaginationAvailable && data.length > 0 && (
        <Block
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="center"
          justifyItems="center"
          marginBottom="16px"
          position="absolute"
          bottom="0"
          left="0"
        >
          <Pagination
            size={SIZE.compact}
            numPages={numPages}
            currentPage={pageNumber}
            overrides={paginationTransparentOverrides}
            onPageChange={handlePageChange}
          />
        </Block>
      )}
    </div>
  );
};

export default memo(AppDataTable);
